import { FC, useCallback, useMemo } from "react";
import { PopoverProps } from "@mui/material";
import { NestedGroupingOptionTrigger } from "../triggers/NestedGroupingOptionTrigger";
import {
  ResourceExplorerGrouping,
  useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery,
} from "../../../../../../services/cloudchipr.api";
import { DropdownSelect } from "../../../../../common/select/dropdown-select/DropdownSelect";
import { useAppSelector } from "../../../../../../store/hooks";
import { DropdownSelectOption } from "../../../../../common/select/dropdown-select/utils/types/types";
import { resourceExplorerGroupValuesSelector } from "../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupValuesSelector";
import { FilterTriggerComponentProps } from "../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";

interface GroupByDimensionsProps {
  onClose(): void;
  onChange(grouping: ResourceExplorerGrouping, groupValue?: string[]): void;
}

export const GroupByDimensionOption: FC<GroupByDimensionsProps> = ({
  onClose,
  onChange,
}) => {
  const { data, isLoading } = useValuesQuery({
    filterProvider: "dimensions",
    filterType: "dimension_id",
  });

  const groupValues = useAppSelector(resourceExplorerGroupValuesSelector);
  const selectedDimension = groupValues?.at(0);

  const dropdownOptions = useMemo(() => {
    return (
      data?.map((option) => ({
        value: option.value,
        hideOnly: true,
        hideCheckbox: true,
        rawValue: option,
        label: option.title ?? option.value,
      })) ?? []
    );
  }, [data]);

  const dropdownOnCloseHandler = useCallback(
    (selected: string[]) => {
      const id = selected.at(0);

      if (!id || id === selectedDimension) {
        return;
      }

      onClose();
      setTimeout(() => {
        onChange?.("category", [id]);
      });
    },
    [onChange, selectedDimension, onClose],
  );

  const TriggerComponent = useCallback((props: FilterTriggerComponentProps) => {
    return <NestedGroupingOptionTrigger {...props} />;
  }, []);

  return (
    <DropdownSelect
      singleSelect
      values={groupValues}
      listWidth={320}
      label="Dimensions"
      filterFn={filterFn}
      showSelectAll={false}
      options={dropdownOptions}
      optionsLoading={isLoading}
      PopoverProps={popoverProps}
      submitHandlerOnClose={dropdownOnCloseHandler}
      TriggerComponent={TriggerComponent}
    />
  );
};

const filterFn = (option: DropdownSelectOption, keyword: string) => {
  return option.rawValue.title.toLowerCase().includes(keyword.toLowerCase());
};

const popoverProps: Partial<PopoverProps> = {
  anchorOrigin: { vertical: "top", horizontal: "right" },
  transformOrigin: { vertical: "top", horizontal: "left" },
};
