import { FC, useCallback } from "react";
import { List, Popover, PopoverOrigin } from "@mui/material";
import moment from "moment/moment";
import { MoreDateRangePopoverItem } from "./MoreDateRangePopoverItem";
import { DateLabel } from "../../../../services/cloudchipr.api";
import { generateDateRangeString } from "../utils/helpers/generateDateRangeString";
import { PreDefinedRange } from "../utils/constants/types";
import { DateData } from "../../../pages/dashboard/components/adding-widget/widget-create/widget-setups/cost-by-category/date-picker/CostByCategorySetupDateRangeSelect";

interface MoreDateRangesPopoverProps {
  onClick?(date: DateData): void;
  dateLabel?: DateLabel | null;
  options?: (PreDefinedRange | undefined)[];
  open: boolean;
  anchor: HTMLElement | null;
  closeMenu(): void;
  minDate?: Date | string;
}
export const MoreDateRangesPopover: FC<MoreDateRangesPopoverProps> = ({
  onClick,
  dateLabel,
  options,
  open,
  anchor,
  closeMenu,
  minDate,
}) => {
  const dateRangeClickHandler = useCallback(
    (label: any) => {
      if (onClick) {
        onClick({
          from: null,
          to: null,
          label,
        });
      }
      closeMenu();
    },
    [closeMenu, onClick],
  );

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={closeMenu}
      transformOrigin={transformOrigin}
      anchorOrigin={anchorOrigin}
      PaperProps={paperProps}
    >
      <List>
        {options?.map((range) => {
          if (!range) {
            return null;
          }
          const disabled = !!(
            minDate && moment(range.startDate).isBefore(minDate)
          );
          return (
            <MoreDateRangePopoverItem
              key={range.shortLabel}
              label={range.shortLabel}
              value={range.dateLabel}
              onClick={dateRangeClickHandler}
              selected={dateLabel === range.dateLabel}
              tooltip={generateDateRangeString(range)}
              disabled={disabled}
            />
          );
        })}
      </List>
    </Popover>
  );
};
const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "center",
};
const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "center",
};
const paperProps = {
  style: { maxHeight: 400, width: 200 },
};
