import { FC, useEffect } from "react";
import { Box, Stack } from "@mui/material";
import { useDidMount, useEffectOnceWhen, useIntervalWhen } from "rooks";
import { useLocation, useNavigate } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AccountsGridView } from "./accounts-grid/AccountsGridView";
import { AccountsEmptyTemplate } from "./AccountsEmptyTemplate";
import { ProviderAccountCashs } from "./ProviderAccountCashs";
import { AccountsToolbar } from "./toolbar/AccountsToolbar";
import { AccountsListView } from "./accounts-list/AccountsListView";
import { AccountSetupDialog } from "../../../common/connectors/AccountSetupDialog";
import { Account, ProviderType } from "../../../../../services/cloudchipr.api";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { accountsFilterValueSelector } from "../../../../../store/accounts/selectors/slice-data/accountsFilterValueSelector";
import { accountsViewSelector } from "../../../../../store/accounts/selectors/slice-data/accountsViewSelector";
import { getAccountsThunk } from "../../../../../store/accounts/thunks/getAccountsThunk";
import { useAccountsDnD } from "../../../account/utils/hooks/useAccountsDnD";
import {
  accountTabs,
  defaultAccountTab,
} from "../../utils/constants/constants";
import { AccountTabs } from "../../utils/types/types";
import { accountsNeededInfoLoadingSelector } from "../../../../../store/accounts/selectors/loadings/accountsNeededInfoLoadingSelector";
import { getOrganisationCurrentFilterTemplatesThunk } from "../../../../../store/live-usage-mgmt/thunks/getOrganisationCurrentFilterTemplatesThunk";

interface AccountsPageBodyProps {
  accounts: Record<AccountTabs, Account[]>;
  provider: ProviderType;
  activeTab: AccountTabs;
  orgId: string;
}

export const AccountsPageBody: FC<AccountsPageBodyProps> = ({
  accounts,
  provider,
  activeTab,
  orgId,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) =>
    accountsNeededInfoLoadingSelector(state, provider, activeTab, orgId),
  );

  const filterValue = useAppSelector(accountsFilterValueSelector);
  const accountsView = useAppSelector(accountsViewSelector);

  const { sortedData, onSortingChange } = useAccountsDnD(
    provider,
    accounts[activeTab],
    activeTab,
    orgId,
  );

  useDidMount(() => {
    dispatch(getAccountsThunk());
    dispatch(getOrganisationCurrentFilterTemplatesThunk());
  });

  useIntervalWhen(
    () => {
      // TODO: Instead of using interval, we should use polling mechanism from RTK
      dispatch(getAccountsThunk());
    },
    60_000,
    true,
  );

  useEffect(() => {
    if (!accountTabs.find((item) => item.value === activeTab)) {
      navigate(`${location.pathname}?type=${defaultAccountTab}`, {
        replace: true,
      });
    }
  }, [activeTab, navigate, location.pathname]);

  useEffectOnceWhen(
    () => {
      navigate(`${location.pathname}?type=${defaultAccountTab}`, {
        replace: true,
      });
    },
    activeTab === "inactive" && !accounts.inactive?.length,
  );

  if (!accounts || loading) {
    return null;
  }

  const accountsByView = {
    grid: (
      <AccountsGridView
        accounts={sortedData}
        provider={provider}
        onSortingChange={onSortingChange}
      />
    ),
    list: (
      <AccountsListView
        accounts={sortedData}
        onSortingChange={onSortingChange}
        accountsType={activeTab}
        provider={provider}
      />
    ),
    bar: (
      <AccountsListView
        withBar
        accounts={sortedData}
        onSortingChange={onSortingChange}
        accountsType={activeTab}
        provider={provider}
      />
    ),
  };

  if (!(accounts[activeTab]?.length || filterValue)) {
    return (
      <AccountsEmptyTemplate
        provider={provider}
        onlyInActive={!!accounts.inactive?.length}
      />
    );
  }

  return (
    <Stack py={2}>
      <Stack px={1} spacing={1}>
        <AccountsToolbar provider={provider} tabValue={activeTab} />

        {activeTab === "active" && (
          <ProviderAccountCashs organizationId={orgId} provider={provider} />
        )}
      </Stack>

      <Box px={1} mt={accountsView === "grid" ? 0 : 1}>
        <DndProvider backend={HTML5Backend} context={window}>
          {!!sortedData && accountsView && accountsByView[accountsView]}
        </DndProvider>
      </Box>

      <AccountSetupDialog />
    </Stack>
  );
};
