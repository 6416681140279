import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { CommitmentUtilizationWidgetContentCostsCard } from "./CommitmentUtilizationWidgetContentCostsCard";
import { useAppSelector } from "../../../../../../../store/hooks";
import { organisationQuarterStartMonthSelector } from "../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { calculateFromToByDatesData } from "../../../../../../common/date-range-picker-v2/utils/helpers/calculateFromToByDatesData";
import { getTrendTooltipTitle } from "../../../../utils/helpers/trend-tooltip-title/getTrendTooltipTitle";
import { commitmentUtilizationWidgetByWidgetIdDataSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetByWidgetIdDataSelector";
import { commitmentUtilizationWidgetAggregationSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetAggregationSelector";
import { commitmentUtilizationWidgetDatesSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetDatesSelector";

interface CommitmentUtilizationWidgetContentCostsProps {
  widgetId: string;
}

export const CommitmentUtilizationWidgetContentCosts: FC<
  CommitmentUtilizationWidgetContentCostsProps
> = ({ widgetId }) => {
  const aggregation = useAppSelector((state) =>
    commitmentUtilizationWidgetAggregationSelector(state, widgetId),
  );
  const dates = useAppSelector((state) =>
    commitmentUtilizationWidgetDatesSelector(state, widgetId),
  );
  const data = useAppSelector((state) =>
    commitmentUtilizationWidgetByWidgetIdDataSelector(state, widgetId),
  );
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );
  const { toDate, fromDate } = useMemo(
    () => calculateFromToByDatesData(dates ?? {}, quarterStartMonth),
    [dates, quarterStartMonth],
  );

  const trendTooltipTitle = useMemo(
    () =>
      getTrendTooltipTitle(
        data?.current_period_date_details?.date_from,
        data?.current_period_date_details?.date_to,
        data?.previous_period_date_details?.date_from,
        data?.previous_period_date_details?.date_to,
        undefined,
        dates?.label,
      ),
    [
      data?.current_period_date_details,
      data?.previous_period_date_details,
      dates?.label,
    ],
  );

  return (
    <Stack direction="row" padding={2} spacing={2}>
      {aggregation?.utilization && (
        <CommitmentUtilizationWidgetContentCostsCard
          title="Utilization"
          tooltipTitle={`Commitments Utilization from ${fromDate} to ${toDate}`}
          cost={data?.utilization?.amount}
          percent={data?.utilization?.percentage}
          trend={Number(data?.utilization_trend)}
          trendTooltipTitle={trendTooltipTitle}
        />
      )}

      {aggregation?.commitments && (
        <CommitmentUtilizationWidgetContentCostsCard
          title="Commitments"
          tooltipTitle={`Commitments from ${fromDate} to ${toDate}`}
          cost={data?.commitments}
          trend={Number(data?.commitments_trend)}
          trendTooltipTitle={trendTooltipTitle}
        />
      )}

      {aggregation?.net_savings && (
        <CommitmentUtilizationWidgetContentCostsCard
          title="Net Savings"
          tooltipTitle={netSavingsTooltipTitle}
          cost={data?.net_savings}
          trend={Number(data?.net_savings_trend)}
          trendTooltipTitle={trendTooltipTitle}
        />
      )}
    </Stack>
  );
};

const netSavingsTooltipTitle =
  "Net Savings from commitments for the selected date range. Calculated by comparing the usage cost covered by Commitments to the public On-Demand cost.";
