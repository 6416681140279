import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";
import { costBreakdownV2ForecastChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2TrendTypeChangeThunk";
import { TrendType } from "../../../../../../../../../../../services/cloudchipr.api";
import { WidgetSetupTrendSettingSelect } from "../../../../common/toolbar/WidgetSetupTrendSettingSelect";

export const CostBreakdownV2WidgetAdvancedSettingsTrendSetting: FC = () => {
  const dispatch = useAppDispatch();

  const trendType = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("trendType"),
  );

  const handleTrendTypeChange = useCallback(
    (trend: TrendType) => {
      dispatch(costBreakdownV2ForecastChangeThunk(trend));
    },
    [dispatch],
  );

  return (
    <WidgetSetupTrendSettingSelect
      trendType={trendType}
      onTrendTypeChange={handleTrendTypeChange}
    />
  );
};
