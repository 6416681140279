import { FC, useCallback, useMemo } from "react";
import { Button, Tooltip } from "@mui/material";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import {
  ResourceFiltersWithAction,
  ResourceType,
} from "../../../../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../../../../../services/permissions";
import { setWorkflowCreationDrawerOpen } from "../../../../../../../../../../../store/schedules/schedulesSlice";
import { getFirstParentInHierarchyResourceType } from "../../../../../../../../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { useCreateWorkflowV2 } from "../../../../../../../../utils/hooks/useCreateWorkflowV2.hook";
import { liveUsageMgmtAccountsWorkflowDataSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/accounts-data/liveUsageMgmtAccountsWorkflowDataSelector";
import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeSelector";

interface LiveUsageMgmtCreateWorkflowToolbarActionProps {
  resourceType: ResourceType;
}

export const LiveUsageMgmtCreateWorkflowToolbarAction: FC<
  LiveUsageMgmtCreateWorkflowToolbarActionProps
> = ({ resourceType }) => {
  const dispatch = useAppDispatch();

  const { cannot } = useAppAbility();
  const canNotCreateSchedule = cannot("create", "schedule");

  const accountsWorkflowData = useAppSelector((state) =>
    liveUsageMgmtAccountsWorkflowDataSelector(state, resourceType),
  );

  const selectedResources = useAppSelector((state) =>
    liveUsageMgmtSelectedResourcesByResourceTypeSelector(state, resourceType),
  );

  const data = useMemo(() => {
    if (!accountsWorkflowData || !selectedResources) {
      return null;
    }

    const parentResourceType =
      getFirstParentInHierarchyResourceType(resourceType) ?? resourceType;

    const value = selectedResources.map(
      (resource) => resource.resource.provider_unique_identifier,
    );

    let filters: ResourceFiltersWithAction[] = [
      {
        filter: {
          type: parentResourceType,
          filter_groups: [0],
          operators: ["and"],
          filter_items: [
            {
              type: resourceType,
              filter: {
                key: "provider_unique_identifier",
                operator: "in",
                value: value,
              },
            },
          ],
        },
      },
    ];

    if (!value.length) {
      filters = accountsWorkflowData.filters.filter(
        (filter) => filter.filter.type === parentResourceType,
      );
    }

    return {
      ...accountsWorkflowData,
      filters,
      resourceType,
    };
  }, [resourceType, selectedResources, accountsWorkflowData]);

  const createWorkflow = useCreateWorkflowV2(data);

  const createHandler = useCallback(() => {
    createWorkflow();
    dispatch(setWorkflowCreationDrawerOpen(true));
  }, [dispatch, createWorkflow]);

  if (!data || canNotCreateSchedule) {
    return null;
  }

  return (
    <Tooltip title="Create Workflow." arrow placement="top">
      <Button
        onClick={createHandler}
        sx={buttonSx}
        variant="outlined"
        color="primary"
      >
        <AvTimerIcon fontSize="small" />
      </Button>
    </Tooltip>
  );
};

const buttonSx = {
  minWidth: 36,
  px: 0.5,
};
