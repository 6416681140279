import { FC } from "react";
import {
  Card,
  Divider,
  Table,
  TableContainer,
  Typography,
} from "@mui/material";
import { CommitmentsOverviewCommitmentsTableHeader } from "./components/CommitmentsOverviewCommitmentsTableHeader";
import { CommitmentsOverviewCommitmentsTableBody } from "./components/CommitmentsOverviewCommitmentsTableBody";
import { CommitmentsOverviewCommitmentsEmptyState } from "./components/CommitmentsOverviewCommitmentsEmptyState";
import { useAppSelector } from "../../../../../../../store/hooks";
import { overviewCommitmentsSelector } from "../../../../../../../store/commitments/selectors/overview/overviewCommitmentsSelector";
import { overviewLoadingSelector } from "../../../../../../../store/commitments/selectors/overview/loading/overviewLoadingSelector";
import { CommitmentsOverviewContentLoading } from "../common/CommitmentsOverviewContentLoading";

export const CommitmentsOverviewCommitments: FC = () => {
  const commitments = useAppSelector(overviewCommitmentsSelector);
  const loading = useAppSelector(overviewLoadingSelector);

  const content =
    commitments?.length === 0 ? (
      <CommitmentsOverviewCommitmentsEmptyState />
    ) : (
      <TableContainer>
        <Table size="small" sx={{ my: 1 }}>
          <CommitmentsOverviewCommitmentsTableHeader />
          <CommitmentsOverviewCommitmentsTableBody />
        </Table>
      </TableContainer>
    );

  return (
    <Card
      variant="outlined"
      sx={{ flex: 1, alignItems: "center", justifyContent: "center" }}
    >
      <Typography variant="subtitle2" color="text.secondary" px={2} py={1}>
        Commitments
      </Typography>
      <Divider />

      {loading ? <CommitmentsOverviewContentLoading /> : content}
    </Card>
  );
};
