import { FC, ReactNode } from "react";
import { Stack, Typography } from "@mui/material";
import { InfoDataChip } from "../../../pages/dashboard/components/widgets/common/widget-header/source-and-name/InfoDataChip";
import { DateInfoChipV2 } from "../../../pages/dashboard/components/adding-widget/widget-create/widget-setups/common/v2/DateInfoChipV2";
import {
  Dates,
  ResourceExplorerGrouping,
} from "../../../../services/cloudchipr.api";
import { resourcesExplorerGroupingSingularLabels } from "../../../pages/resource-explorer/components/resource-explorer-card/utils/constants/groupings";

interface WidgetHeaderProps {
  name?: string;
  grouping?: ResourceExplorerGrouping;
  dates?: Dates;
  children?: ReactNode;
}

export const WidgetHeader: FC<WidgetHeaderProps> = ({
  name,
  grouping,
  dates,
  children,
}) => {
  return (
    <Stack
      pl={2}
      pr={0}
      py={1}
      top={0}
      zIndex={2}
      spacing={2}
      direction="row"
      bgcolor="white"
      borderBottom={1}
      position="sticky"
      alignItems="center"
      borderColor="grey.300"
    >
      <Typography variant="subtitle1" fontWeight="medium">
        {name}
      </Typography>

      {grouping && (
        <InfoDataChip>
          Group By:{" "}
          <Typography
            component="span"
            variant="inherit"
            fontWeight="medium"
            color="text.primary"
          >
            {resourcesExplorerGroupingSingularLabels.get(grouping)}
          </Typography>
        </InfoDataChip>
      )}

      {dates && <DateInfoChipV2 {...dates} tooltipPlacement="top" />}

      {children}
    </Stack>
  );
};
