import { costBreakdownV2WidgetDataByWidgetIdSelector } from "./costBreakdownV2WidgetDataByWidgetIdSelector";
import { costBreakdownV2WidgetFrequencySelector } from "./costBreakdownV2WidgetFrequencySelector";
import { costBreakdownV2WidgetVisualizationSelector } from "./costBreakdownV2WidgetVisualizationSelector";
import { RootState } from "../../../../../store";
import { ChartDataType } from "../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { formatResourceExplorerChartData } from "../../../../../resource-explorer/utils/helpers/formatResourceExplorerChartData";
import { convertCostToPercent } from "../../../../../resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/convertCostToPercent";

export const costBreakdownV2WidgetChartDataSelector = (
  state: RootState,
  widgetId: string,
): ChartDataType[] | [] => {
  const visualization = costBreakdownV2WidgetVisualizationSelector(
    state,
    widgetId,
  );
  const data = costBreakdownV2WidgetDataByWidgetIdSelector(
    state,
    widgetId,
  )?.data;

  const frequency = costBreakdownV2WidgetFrequencySelector(state, widgetId);

  if (!data || !frequency) {
    return [];
  }

  const formatedData = formatResourceExplorerChartData(data, frequency);

  if (visualization?.chart_base_type === "cost" || !formatedData) {
    return formatedData;
  }

  return convertCostToPercent(formatedData);
};
