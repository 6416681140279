import { costBreakdownV2SetupPropertyByKeySelector } from "./costBreakdownV2SetupPropertyByKeySelector";
import { CostBreakdownWidgetSetupType } from "../../../utils/types/setups/costBreakdownSetupType";
import { RootState } from "../../../../store";
import { getWidgetsConfigurationsFeatureToggleFromStorage } from "../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/cost-breakdown-v2/utils/helpers/configurations-feature-toggle/getWidgetsConfigurationsFeatureToggleFromStorage";

export const costBreakdownV2SetupVisualizationPropertyByKeySelector =
  (key: keyof CostBreakdownWidgetSetupType["visualization"]) =>
  (state: RootState): any => {
    const enableWidgetsSideBarConfigurations =
      getWidgetsConfigurationsFeatureToggleFromStorage();

    if (!enableWidgetsSideBarConfigurations && key == "chart_type") {
      return costBreakdownV2SetupPropertyByKeySelector("viewType")(state);
    }

    const setup =
      costBreakdownV2SetupPropertyByKeySelector("visualization")(state);

    if (!setup) {
      return null;
    }

    return setup?.[key];
  };
