import { liveUsageMgmtResourceTypeDataSelector } from "./liveUsageMgmtResourceTypeDataSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { ResourceDataType } from "../../../account/utils/types/types";

export const liveUsageMgmtLiveFilteredResourcesSelector = (
  state: RootState,
  resourceType: ResourceType,
): ResourceDataType[] | undefined => {
  return liveUsageMgmtResourceTypeDataSelector(state, resourceType)?.resources;
};
