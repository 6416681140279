import {
  ChangeEvent,
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Divider } from "@mui/material";
import { FilterSearchBox } from "./components/FilterSearchBox";
import { FilterTrigger } from "./components/FilterTrigger";
import {
  DropdownFooterComponent,
  DropdownHeaderComponent,
} from "./utils/types/types";
import {
  PopoverWrapper,
  PopoverWrapperProps,
} from "./components/PopoverWrapper";
import {
  DropdownListContent,
  DropdownListContentProps,
} from "./DropdownListContent";
import { FilterTriggerComponentProps } from "./utils/types/filterTriggerComponentProps";
import { sortOptionsBySelected } from "./utils/helpers/sortOptionsBySelected";
import { useMenuHook } from "../../../../utils/hooks/useMenu.hook";

type DropdownListContentExtendedProps = Omit<
  DropdownListContentProps,
  "keyword" | "setSelectedValues" | "selectedValues" | "closeHandler"
>;

type PopoverWrapperExtendedProps = Partial<
  Pick<PopoverWrapperProps, "PopoverProps" | "placement" | "wrapperVariant">
>;

type ExtendedProps = DropdownListContentExtendedProps &
  PopoverWrapperExtendedProps;

export interface DropdownSelectProps extends ExtendedProps {
  disabled?: boolean;
  label: string;
  searchPlaceholder?: string;
  values?: string[];
  initialSelectedValues?: string[];
  DropdownHeader?: DropdownHeaderComponent;
  DropdownFooter?: DropdownFooterComponent;
  TriggerComponent?: FC<FilterTriggerComponentProps>;
  showSearch?: boolean;
  showSelectAll?: boolean;
  sortSelectedOptions?: boolean;
  error?: string | boolean;
  disableEmptySelectionList?: boolean;
  onSearchChange?(value: string): void;
  forceToClose?: boolean;
}

export const DropdownSelect: FC<DropdownSelectProps> = ({
  searchPlaceholder,
  disabled,
  label,
  placement,
  values,
  options,
  submitHandlerOnClose,
  filterFn,
  singleSelect,
  initialSelectedValues,
  TriggerComponent,
  DropdownHeader,
  PopoverProps,
  listWidth,
  optionsLoading,
  showSearch = true,
  showSelectAll = true,
  wrapperVariant = "popover",
  creatable,
  setExtraOptions,
  renderOption,
  noItemsLabel,
  DropdownFooter,
  error,
  sortSelectedOptions = true,
  disableEmptySelectionList,
  onSearchChange,
  forceToClose,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const [selectedValues, setSelectedValues] = useState<string[]>(
    initialSelectedValues ?? [],
  );

  const [sortedOptions, setSortedOptions] = useState(options);

  const [keyword, setKeyword] = useState("");

  const isSelected = !!selectedValues.length;

  const handleSearchKeywordChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setKeyword(e.target.value);
      onSearchChange?.(e.target.value);
    },
    [onSearchChange],
  );

  const closeHandler = useCallback(() => {
    closeMenu();
    setKeyword("");
  }, [closeMenu]);

  const selectedValuesChangeHandler: Dispatch<SetStateAction<string[]>> =
    useCallback(
      (setStateAction) => {
        setSelectedValues((prevState) => {
          let result: string[];

          if (typeof setStateAction === "function") {
            const currentState = setStateAction(prevState);

            if (disableEmptySelectionList && currentState.length === 0) {
              result = prevState;
            } else {
              result = currentState;
            }
          } else {
            result = setStateAction;
          }

          return result;
        });
      },
      [disableEmptySelectionList],
    );

  const handlePopoverCloseAndSubmit = useCallback(
    (values: string[]) => {
      closeHandler();
      submitHandlerOnClose?.(values);
    },
    [closeHandler, submitHandlerOnClose],
  );

  const handlePopoverClose = useCallback(() => {
    handlePopoverCloseAndSubmit(selectedValues);
  }, [handlePopoverCloseAndSubmit, selectedValues]);

  const triggerProps = {
    open,
    error,
    label,
    selectedValues,
    onClick: openMenu,
    disabled: !!disabled,
    selected: isSelected,
  };

  useEffect(() => {
    if (values) {
      setSelectedValues(values);
    }
  }, [values]);

  useEffect(() => {
    if (forceToClose) {
      closeHandler();
    }
  }, [forceToClose, closeHandler]);

  useEffect(() => {
    if (open && sortSelectedOptions) {
      setSortedOptions(
        sortOptionsBySelected(options, values ?? initialSelectedValues ?? []),
      );
    }
  }, [sortSelectedOptions, open, options, values, initialSelectedValues]);

  return (
    <Fragment>
      {TriggerComponent ? (
        <TriggerComponent
          keyword={onSearchChange ? "" : keyword}
          setKeyword={setKeyword}
          onClose={handlePopoverClose}
          onCloseAndSubmit={handlePopoverCloseAndSubmit}
          setSelectedValues={selectedValuesChangeHandler}
          options={sortedOptions.length ? sortedOptions : options}
          {...triggerProps}
        />
      ) : (
        <FilterTrigger
          optionsLength={
            sortedOptions.length ? sortedOptions.length : options.length
          }
          {...triggerProps}
        />
      )}

      <PopoverWrapper
        open={open}
        anchor={anchor}
        wrapperVariant={wrapperVariant}
        PopoverProps={PopoverProps}
        onClose={handlePopoverClose}
        listWidth={listWidth}
        placement={placement}
      >
        {DropdownHeader && (
          <DropdownHeader
            onClose={closeHandler}
            onSearchChange={handleSearchKeywordChange}
          />
        )}

        {((showSearch && !!sortedOptions.length) || onSearchChange) && (
          <FilterSearchBox
            placeholder={searchPlaceholder}
            onChange={handleSearchKeywordChange}
          />
        )}

        <Divider />

        <DropdownListContent
          noItemsLabel={noItemsLabel}
          singleSelect={singleSelect}
          selectedValues={selectedValues}
          optionsLoading={optionsLoading}
          options={sortSelectedOptions ? sortedOptions : options}
          submitHandlerOnClose={submitHandlerOnClose}
          listWidth={listWidth}
          filterFn={filterFn}
          closeHandler={closeHandler}
          keyword={onSearchChange ? "" : keyword}
          setSelectedValues={selectedValuesChangeHandler}
          creatable={creatable}
          showSelectAll={showSelectAll}
          setExtraOptions={setExtraOptions}
          renderOption={renderOption}
        />

        {DropdownFooter && <DropdownFooter onClose={closeHandler} />}
      </PopoverWrapper>
    </Fragment>
  );
};
