import { FC, useCallback, useMemo, useState } from "react";
import { Popover, PopoverOrigin } from "@mui/material";
import { FilterTemplateDropDownContent } from "./FilterTemplateDropDownContent";
import { FilterTemplateDropdownHeader } from "./FilterTemplateDropdownHeader";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { appliedFilterTemplateIdSelector } from "../../../../../../store/filters/selectors/filter-set/applied/appliedFilterTemplateIdSelector";
import { applyFilterTemplateToCurrentAccount } from "../../../../../../store/filters/thunks/filter-set/applyFilterSetToCurrentAccount";
import { currentAccountProviderTypeSelector } from "../../../../../../store/account/selectors/current-account/currentAccountProviderTypeSelector";
import { getOrganisationCurrentFilterTemplatesThunk } from "../../../../../../store/live-usage-mgmt/thunks/getOrganisationCurrentFilterTemplatesThunk";
import { filterTemplatesSelector } from "../../../../../../store/filters/selectors/filter-set/filterTemplatesSelector";

interface FilterTemplateDropdownProps {
  open: boolean;
  onClose(): void;
  anchor: HTMLElement | null;
}
export const FilterTemplateDropdown: FC<FilterTemplateDropdownProps> = ({
  open,
  onClose,
  anchor,
}) => {
  const dispatch = useAppDispatch();
  const provider = useAppSelector(currentAccountProviderTypeSelector);

  const appliedFilterSetId = useAppSelector(appliedFilterTemplateIdSelector);
  const [loadingByFilterSetId, setLoadingByFilterSetId] = useState<Record<
    string,
    boolean
  > | null>(null);
  const filterTemplates = useAppSelector((state) =>
    filterTemplatesSelector(state, provider ?? "aws"),
  );

  const applyFilterTemplateHandler = useCallback(
    async (filterSetId: string) => {
      setLoadingByFilterSetId({
        [filterSetId]: true,
      });
      await dispatch(applyFilterTemplateToCurrentAccount(filterSetId));

      setLoadingByFilterSetId(null);
      onClose();
      await dispatch(getOrganisationCurrentFilterTemplatesThunk());
    },
    [dispatch, onClose],
  );

  const filterTemplateHeader = useMemo(() => {
    return <FilterTemplateDropdownHeader onClose={onClose} />;
  }, [onClose]);

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={paperProps}
    >
      <FilterTemplateDropDownContent
        filterTemplates={filterTemplates}
        provider={provider ?? undefined}
        onApplyFilterTemplate={applyFilterTemplateHandler}
        appliedFilterSetId={appliedFilterSetId ?? ""}
        loadingByFilterSetId={loadingByFilterSetId}
        header={filterTemplateHeader}
      />
    </Popover>
  );
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};
const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const paperProps = {
  style: { maxHeight: 400, width: 380 },
};
