import { FC } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { WidgetTotalsSummary } from "../../common/WidgetTotalsSummary";
import { DateLabelNullable } from "../../../../../../../services/cloudchipr.api";
import { costBreakdownV2WidgetCostsSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetCostsSelector";
import { costBreakdownV2WidgetFrequencySelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetFrequencySelector";
import { costBreakdownV2WidgetForecastOptionSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetForecastOptionSelector";
import { costBreakdownV2WidgetAggregationsSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetAggregationsSelector";
import { costBreakdownV2WidgetVisualizationSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetVisualizationSelector";

interface CostBreakdownWidgetContentCostsProps {
  widgetId: string;
  dateLabel?: DateLabelNullable;
}

export const CostBreakdownV2WidgetContentCosts: FC<
  CostBreakdownWidgetContentCostsProps
> = ({ widgetId, dateLabel }) => {
  const costsData = useAppSelector((state) =>
    costBreakdownV2WidgetCostsSelector(state, widgetId),
  );

  const visualisations = useAppSelector((state) =>
    costBreakdownV2WidgetVisualizationSelector(state, widgetId),
  );

  const frequency = useAppSelector((state) =>
    costBreakdownV2WidgetFrequencySelector(state, widgetId),
  );

  const forecastOption = useAppSelector((state) =>
    costBreakdownV2WidgetForecastOptionSelector(state, widgetId),
  );

  const aggregations = useAppSelector((state) =>
    costBreakdownV2WidgetAggregationsSelector(state, widgetId),
  );

  if (!costsData || !frequency || !forecastOption) {
    return null;
  }

  return (
    <WidgetTotalsSummary
      frequency={frequency}
      costsData={costsData}
      forecastOption={forecastOption}
      dateLabel={dateLabel}
      aggregations={aggregations}
      showTrends={visualisations?.trend}
    />
  );
};
