import { FC } from "react";
import { Stack } from "@mui/material";
import { alertsCostAnomalyDataGridColumns } from "./components/alertsCostAnomalyDataGridColumns";
import { useAppSelector } from "../../../../../../store/hooks";
import { costAnomalyAlertsDataGridDataSelector } from "../../../../../../store/alerts/selectors/cost-anomaly-alerts/costAnomalyAlertsDataGridDataSelector";
import { getCostAnomalyAlertsLoadingSelector } from "../../../../../../store/alerts/selectors/cost-anomaly-alerts/getCostAnomalyAlertsLoadingSelector";
import { AlertsEmptyPage } from "../common/AlertsEmptyPage";
import { DataGrid } from "../../../../../../storybook/data-grid/DataGrid";
import { AlertsDataGridToolbar } from "../common/AlertsDataGridToolbar";
import { ToolbarConfig } from "../../../../../../storybook/data-grid/utils/types/prop-types";
import { NoFilteredData } from "../../../../../common/NoFilteredData";

export const AlertsCostAnomaly: FC = () => {
  const data = useAppSelector(costAnomalyAlertsDataGridDataSelector);
  const emptyData = !data?.length;
  const costAnomalyLoading = useAppSelector(
    getCostAnomalyAlertsLoadingSelector,
  );

  return (
    <Stack pb={5}>
      {emptyData && !costAnomalyLoading && <AlertsEmptyPage />}

      {!emptyData && (
        <DataGrid
          globalFilter
          data={data}
          styles={styles}
          columnVisibility
          columnResizeMode="onEnd"
          toolbar={dataGridToolbar}
          columnSorting={sortingConfig}
          enableStickyColumns={!!data.length}
          columns={alertsCostAnomalyDataGridColumns}
          Footer={NoFilteredData}
        />
      )}
    </Stack>
  );
};

const sortingConfig = { initialSort: [{ id: "name", desc: false }] };

const dataGridToolbar: ToolbarConfig = {
  renderToolbar: AlertsDataGridToolbar,
};

const styles = {
  table: {
    tableLayout: "fixed",
  },
  tableHeaderRow: {
    bgcolor: "grey.200",
    "& th": { py: 2 },
  },
  tableRow: {
    "& td": { py: 0.75 },
  },
};
