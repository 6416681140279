import { FC, useCallback } from "react";
import { SwitchWithTypography } from "../../../../../../../../../../SwitchWithTypography";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { costBreakdownV2SetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupVisualizationPropertyByKeySelector";
import { costBreakdownV2ViewVisualizationChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2ViewVisualizationChangeThunk";

export const CostBreakdownV2WidgetVisualizationForecastedCost: FC = () => {
  const dispatch = useAppDispatch();
  const forecastedCostChecked = useAppSelector(
    costBreakdownV2SetupVisualizationPropertyByKeySelector("forecasted_cost"),
  );

  const handleForecastedCostCheckedChange = useCallback(
    (checked: boolean) => {
      dispatch(
        costBreakdownV2ViewVisualizationChangeThunk({
          forecasted_cost: checked,
        }),
      );
    },
    [dispatch],
  );

  return (
    <SwitchWithTypography
      checked={forecastedCostChecked}
      onChange={handleForecastedCostCheckedChange}
      title="Forecasted Cost"
    />
  );
};
