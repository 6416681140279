import { FC, useCallback } from "react";
import { SwitchWithTypography } from "../../../../../../../../../../SwitchWithTypography";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { costBreakdownV2SetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupVisualizationPropertyByKeySelector";
import { costBreakdownV2ViewVisualizationChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2ViewVisualizationChangeThunk";

export const CostBreakdownV2WidgetVisualizationTrend: FC = () => {
  const dispatch = useAppDispatch();
  const trendChecked = useAppSelector(
    costBreakdownV2SetupVisualizationPropertyByKeySelector("trend"),
  );

  const handleTrendCheckedChange = useCallback(
    (checked: boolean) => {
      dispatch(
        costBreakdownV2ViewVisualizationChangeThunk({
          trend: checked,
        }),
      );
    },
    [dispatch],
  );

  return (
    <SwitchWithTypography
      checked={trendChecked}
      onChange={handleTrendCheckedChange}
      title="Trend"
    />
  );
};
