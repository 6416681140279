import { liveUsageMgmtResourceTypeDataResourcesSelector } from "./liveUsageMgmtResourceTypeDataResourcesSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const liveUsageMgmtResourceTypeResourcesCountSelector = (
  state: RootState,
  resourceType: ResourceType,
): number | undefined => {
  const resources = liveUsageMgmtResourceTypeDataResourcesSelector(
    state,
    resourceType,
  );

  return resources?.length;
};
