import { appliedFilterTemplateSelector } from "./applied/appliedFilterTemplateSelector";
import { RootState } from "../../../store";
import {
  ResourceFilters,
  ResourceType,
} from "../../../../services/cloudchipr.api";
import { organisationCurrentFilterSetsSelector } from "../current-filter-sets/organisationCurrentFilterSetsSelector";

export const currentAccountApplyingFilterTemplateFiltersByResourceTypeSelector =
  (
    state: RootState,
    resourceType: ResourceType,
  ): ResourceFilters | undefined => {
    const filterTemplates = organisationCurrentFilterSetsSelector(state);

    let filters = appliedFilterTemplateSelector(state)?.filters;

    if (!filters) {
      filters = filterTemplates?.find(
        (filter_set) => filter_set.is_default,
      )?.filters;
    }

    return filters?.find(({ type }) => type === resourceType);
  };
