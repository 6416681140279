import React, { FC, memo, RefObject, useCallback, useMemo } from "react";
import { grey } from "@mui/material/colors";
import { Stack, Theme } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { SubRowDataGridNF, SubRowDataGridProps } from "./SubRowDataGridNF";
import { ResourcesDataGridSubRowToolbar } from "./ResourcesDataGridSubRowToolbar";
import { resourcesDataGridSubRow } from "./ResourcesSubRowNF";
import { useDataGridContext } from "../../../../../../../../../../storybook/data-grid/DataGridProvider";
import { getFirstResourceTypeFromResources } from "../utils/helpers/getFirstResourceTypeFromResources";
import { generateExportedResourceData } from "../utils/csv-data/generateExportedResourceData";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { nestedGridPageSize } from "../utils/data-grid/constants";
import { generateMetricsFrequenciesFromContextData } from "../utils/csv-data/generateMetricsFrequenciesFromContextData";
import { liveUsageMgmtSelectedGroupedRowsByResourceTypeSelector } from "../../../../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtSelectedGroupedRowsByResourceTypeSelector";
import { setLiveUsageMgmtSelectedResources } from "../../../../../../../../../../store/live-usage-mgmt/liveUsageMgmtSlice";

interface ResourcesDataGridSubRowProps {
  resource: SubRowDataGridProps["resource"];
  globalFilter?: string;
  parentSource: string;
  parentContainerRef: RefObject<HTMLTableElement>;
  exportedColumns?: string[];
  expandable?: boolean;
}

export const ResourcesDataGridSubRowTable: FC<ResourcesDataGridSubRowProps> =
  memo(
    ({
      exportedColumns,
      parentContainerRef,
      resource,
      globalFilter,
      parentSource,
      expandable,
    }) => {
      const enableEcsActions = useFlag("EnableECSActions");
      const {
        cellSpecificMetadata: { data: cellData },
      } = useDataGridContext();
      const metricsFrequencies = useMemo(
        () => generateMetricsFrequenciesFromContextData(cellData),
        [cellData],
      );

      const subRowResourceType = getFirstResourceTypeFromResources(
        resource.resources,
      );

      const selectedResources = useAppSelector((state) =>
        liveUsageMgmtSelectedGroupedRowsByResourceTypeSelector(
          state,
          subRowResourceType,
        ),
      );
      const dispatch = useAppDispatch();

      const csvData = useMemo(() => {
        if (!subRowResourceType) {
          return null;
        }

        return generateExportedResourceData(
          subRowResourceType,
          resource.resources,
          exportedColumns ?? [],
          {
            metricsFrequencies,
          },
        );
      }, [
        subRowResourceType,
        resource.resources,
        exportedColumns,
        metricsFrequencies,
      ]);

      const onRowSelectionChange = useCallback(
        (data: Record<string, boolean>): void => {
          if (!subRowResourceType) {
            return;
          }
          dispatch(
            setLiveUsageMgmtSelectedResources({
              resourceType: subRowResourceType,
              selectedResources: data,
              parentSource: parentSource,
            }),
          );
        },
        [dispatch, subRowResourceType, parentSource],
      );

      if (!subRowResourceType) {
        return null;
      }
      const rowSelection =
        subRowResourceType === "ecs_workload" && !enableEcsActions
          ? undefined
          : {
              rowSelectionChange: onRowSelectionChange,
              initialSelectedItems: selectedResources?.[parentSource] ?? {},
            };

      const parentWidth = parentContainerRef?.current?.offsetWidth;

      const rowExpandingConfig = {
        renderExpandedRowSubRow: resourcesDataGridSubRow,
        parentRowStyles: {
          bgcolor: grey[100],
        },
      };

      return (
        <Stack
          pt={1}
          width={parentWidth ? `calc(${parentWidth}px - 34px)` : "inherit"}
        >
          <SubRowDataGridNF
            exportedColumns={exportedColumns}
            resourceType={subRowResourceType}
            resource={resource}
            styles={resourcesDataGridSubRowStyles}
            globalFilter={globalFilter}
            pagination={paginationConfig}
            rowExpanding={expandable ? rowExpandingConfig : undefined}
            rowSelection={rowSelection}
            toolbar={{
              renderToolbar: (props) => (
                <ResourcesDataGridSubRowToolbar
                  {...props}
                  csvData={csvData}
                  resourceType={subRowResourceType}
                  totalCount={resource.total_resources}
                  count={resource.resources.length}
                />
              ),
            }}
          />
        </Stack>
      );
    },
  );

const paginationConfig = {
  renderPagination: () => <span> </span>,
  pageSize: nestedGridPageSize,
};

const resourcesDataGridSubRowStyles = {
  tableHeaderRow: {
    top: 0,
    position: "sticky",
    zIndex: (theme: Theme) => theme.zIndex.speedDial - 2,
    bgcolor: "grey.200",
  },
  tableRow: {
    background: "white",
    "&:last-of-type td": {
      borderBottom: `none`,
    },
  },
  tableToolbar: { px: 2 },
  tableContainer: {
    maxHeight: 580,
    overflowY: "hidden",
    border: 1,
    borderColor: "grey.300",
    borderRadius: 1,
  },
};
