import { costBreakdownV2WidgetSetupDataSelector } from "./costBreakdownV2WidgetSetupDataSelector";
import { RootState } from "../../../../../store";
import { ChartDataType } from "../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { formatResourceExplorerChartData } from "../../../../../resource-explorer/utils/helpers/formatResourceExplorerChartData";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";
import { convertCostToPercent } from "../../../../../resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/convertCostToPercent";
import { costBreakdownV2SetupVisualizationPropertyByKeySelector } from "../../../setups/cost-breakdown-v2/costBreakdownV2SetupVisualizationPropertyByKeySelector";

export const costBreakdownV2WidgetSetupChartDataSelector = (
  state: RootState,
): ChartDataType[] | [] => {
  const base =
    costBreakdownV2SetupVisualizationPropertyByKeySelector("chart_base_type")(
      state,
    );
  const data = costBreakdownV2WidgetSetupDataSelector(state)?.data;
  const frequency =
    costBreakdownV2SetupPropertyByKeySelector("frequency")(state);

  if (!data) {
    return [];
  }

  const formatedData = formatResourceExplorerChartData(data, frequency);

  if (base === "cost" || !formatedData) {
    return formatedData;
  }

  return convertCostToPercent(formatedData);
};
