import { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { useDidMount } from "rooks";
import { AddDimensionButton } from "./components/AddDimensionButton";
import { DimensionsEmptyState } from "./components/DimensionsEmptyState";
import { DimensionsDataGrid } from "./components/data-grid/dimensions/DimensionsDataGrid";
import { DimensionDrawers } from "./components/form-drawer/DimensionDrawers";
import { PageHeader } from "../common/PageHeader";
import { getDimensionsThunk } from "../../../store/dimensions/thunks/dimensions/getDimensionsThunk";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { dimensionsLoadingSelector } from "../../../store/dimensions/selectors/dimensions-data/dimensionsLoadingSelector";
import { dimensionsDataEmptySelector } from "../../../store/dimensions/selectors/dimensions-data/dimensionsDataEmptySelector";
import { ProviderBillingDataAccessController } from "../common/billing/billing-data-access-controller/ProviderBillingDataAccessController";
import { isBillingAvailableSelector } from "../../../store/common/selectors/billing-status/isBillingAvailableSelector";
import { connectedProvidersSelector } from "../../../store/common/selectors/connectedProvidersSelector";
import { isBillingStatusesGettingFulfilledSelector } from "../../../store/common/selectors/billing-status/isBillingStatusesGettingFulfilledSelector";

export const Dimensions: FC = () => {
  const dispatch = useAppDispatch();
  const empty = useAppSelector(dimensionsDataEmptySelector);
  const loading = useAppSelector(dimensionsLoadingSelector);
  const connectedProviders = useAppSelector(connectedProvidersSelector);
  const isBillingStatusesGettingFulfilled = useAppSelector(
    isBillingStatusesGettingFulfilledSelector,
  );
  const resourceExplorerAvailable = useAppSelector(isBillingAvailableSelector);
  useDidMount(() => {
    dispatch(getDimensionsThunk());
  });

  if (!isBillingStatusesGettingFulfilled) {
    return null;
  }

  if (!resourceExplorerAvailable) {
    const provider = connectedProviders[0];
    return <ProviderBillingDataAccessController provider={provider} />;
  }

  return (
    <Fragment>
      <PageHeader
        sticky
        title="Dimensions"
        loading={loading}
        actions={!empty ? <AddDimensionButton size="medium" /> : null}
      />

      <Box p={2}>
        {!loading && empty ? <DimensionsEmptyState /> : <DimensionsDataGrid />}
      </Box>

      <DimensionDrawers />
    </Fragment>
  );
};
