import { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { useDidMount, useIntervalWhen, useWillUnmount } from "rooks";
import {
  Box,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
} from "@mui/material";
import {
  ImperativePanelHandle,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from "react-resizable-panels";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { LiveUsageMgmtHeader } from "./components/header/LiveUsageMgmtHeader";
import { LiveUsageMgmtNavigation } from "./components/navigation/LiveUsageMgmtNavigation";
import { LiveUsageResourcesArea } from "./components/account-resources-area/LiveUsageResourcesArea";
import { accountIdsSearchParam } from "./components/navigation/utils/constants";
import { generateAccountIdsQueryParams } from "./utils/helpers/generateAccountIdsQueryParams";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useAppAbility } from "../../../services/permissions";
import { useLayoutConfigHook } from "../../navigation/utils/hooks/useLayoutConfigHook";
import { getSettingsByKeyThunk } from "../../../store/settings/getSettingsByKeyThunk";
import { OutletContextType } from "../../layout/MainLayout";
import { initiateLiveUsageMgmtThunk } from "../../../store/live-usage-mgmt/thunks/initiateLiveUsageMgmtThunk";
import { liveUsagePageStateSelector } from "../../../store/live-usage-mgmt/selectors/store-selectors/liveUsagePageStateSelector";
import { ProviderType } from "../../../services/cloudchipr.api";
import { COMPONENT_ID } from "../../../utils/constants/components-ids";
import { CreateWorkflowDrawer } from "../automations/workflows/drawer/CreateWorkflowDrawer";
import { CleanV2Dialog } from "../common/clean-v2/clean-dialog/CleanV2Dialog";
import { resetLiveUsageMgmtSlice } from "../../../store/live-usage-mgmt/liveUsageMgmtSlice";
import { refreshLiveUsageMgmtPageThunk } from "../../../store/live-usage-mgmt/thunks/refreshLiveUsageMgmtPageThunk";
import { liveUsageMgmtIsPageLoadingSelector } from "../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtIsPageLoadingSelector";
import { liveUsageMgmtAccountsListSelector } from "../../../store/live-usage-mgmt/selectors/accounts-data/liveUsageMgmtAccountsListSelector";

interface LiveUsageMgmtProps {
  provider: ProviderType;
}
export const LiveUsageMgmt: FC<LiveUsageMgmtProps> = ({ provider }) => {
  const [searchParams] = useSearchParams();
  const accountIds = searchParams.get(accountIdsSearchParam);
  const navigate = useNavigate();
  const accounts = useAppSelector(liveUsageMgmtAccountsListSelector);
  const pageState = useAppSelector(liveUsagePageStateSelector);

  const isPageLoading = useAppSelector(liveUsageMgmtIsPageLoadingSelector);
  const sideBarRef = useRef<ImperativePanelHandle>(null);

  const [collapsed, setCollapsed] = useState<boolean>();

  const handleCollapseToggle = useCallback(() => {
    if (sideBarRef.current?.isExpanded()) {
      sideBarRef.current?.collapse();
    } else {
      sideBarRef.current?.expand();
    }
  }, []);

  const handleSideBarCollapse = useCallback(() => {
    setCollapsed(true);
  }, []);

  const handleSideBarExpand = useCallback(() => {
    setCollapsed(false);
  }, []);

  const dispatch = useAppDispatch();
  const { can } = useAppAbility();
  const canViewAccount = can("view", "account");

  const { layoutSizes } = useOutletContext<OutletContextType>();

  const { min, max, collapsedSize } = useLayoutConfigHook(
    52,
    220,
    320,
    layoutSizes?.[1],
  );

  useDidMount(() => {
    setCollapsed(sideBarRef.current?.isCollapsed());
    dispatch(getSettingsByKeyThunk("smart_tag_ttl"));
  });

  useEffect(() => {
    if (!accountIds && accounts?.[0]?.id) {
      navigate(
        `/${provider}/live-usage${generateAccountIdsQueryParams([accounts?.[0]?.id])}`,
      );
    }
  }, [provider, accounts, accountIds, navigate]);

  useEffect(() => {
    if (provider) {
      dispatch(initiateLiveUsageMgmtThunk({ accountIds, provider }));
    }
  }, [provider, accountIds, dispatch]);

  useIntervalWhen(
    () => {
      if (accountIds) {
        dispatch(refreshLiveUsageMgmtPageThunk());
      }
    },
    180_000,
    !!(accountIds && canViewAccount),
    false,
  );

  useWillUnmount(() => {
    dispatch(resetLiveUsageMgmtSlice());
  });

  if (pageState !== "fulfilled") {
    return <LinearProgress />;
  }

  if (!canViewAccount) {
    return null;
  }

  return (
    <Stack height="100%">
      {isPageLoading && <LinearProgress />}

      <LiveUsageMgmtHeader />
      <PanelGroup direction="horizontal" autoSaveId="account-page-navigation">
        <Panel
          collapsible
          minSize={min}
          maxSize={max}
          collapsedSize={collapsedSize}
          ref={sideBarRef}
          onCollapse={handleSideBarCollapse}
          onExpand={handleSideBarExpand}
          style={{ overflowY: "auto" }}
        >
          {collapsed !== undefined && (
            <Box bgcolor="white" height="100%">
              {collapsed ? (
                <IconButton
                  color="primary"
                  size="small"
                  onClick={handleCollapseToggle}
                  sx={{ borderRadius: 2, m: 1 }}
                >
                  <MenuOpenIcon
                    sx={{
                      transform: "rotate(180deg)",
                    }}
                  />
                </IconButton>
              ) : (
                <Button
                  variant="text"
                  startIcon={<MenuOpenIcon />}
                  onClick={handleCollapseToggle}
                  sx={{ m: 1, textTransform: "initial" }}
                >
                  Collapse Services
                </Button>
              )}

              <LiveUsageMgmtNavigation collapsed={collapsed} />
            </Box>
          )}
        </Panel>
        <PanelResizeHandle>
          <Divider orientation="vertical" />
        </PanelResizeHandle>
        <Panel
          style={{ overflowY: "auto" }}
          id={COMPONENT_ID.ACCOUNT_PAGE_SCROLL_AREA}
        >
          <LiveUsageResourcesArea />
        </Panel>
      </PanelGroup>
      <CleanV2Dialog />
      <CreateWorkflowDrawer />
    </Stack>
  );
};
