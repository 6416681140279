import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { categoriesGroupedByGroupSelector } from "../../../../../../../../../../store/categories/selectors/categoriesGroupedByGroupSelector";
import { costByCategorySetupChangeThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/cost-by-category/costByCategorySetupChangeThunk";
import { costByCategorySetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-by-category/costByCategorySetupPropertyByKeySelector";
import { categoryNamesAlphabeticallySortedSelector } from "../../../../../../../../../../store/categories/selectors/categoryNamesAlphabeticallySortedSelector";
import { fetchCostByCategoryWidgetDataWithoutFiltersThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/cost-by-category/data-fetch/fetchCostByCategoryWidgetDataWithoutFiltersThunk";

export const CostByCategoryGroupSelect: FC = () => {
  const dispatch = useAppDispatch();
  const categoryGroup = useAppSelector(
    costByCategorySetupPropertyByKeySelector("categoryGroup"),
  );
  const groupedCategories = useAppSelector(categoriesGroupedByGroupSelector);
  const categoryNames = useAppSelector(
    categoryNamesAlphabeticallySortedSelector,
  );

  const changeHandler = useCallback(
    (e: any) => {
      const name = e.target.value;
      const ids = groupedCategories[name]?.map((category) => category.id) ?? [];

      dispatch(
        costByCategorySetupChangeThunk({
          categoryGroup: name,
          categoryIds: ids,
        }),
      );
      dispatch(fetchCostByCategoryWidgetDataWithoutFiltersThunk());
    },
    [dispatch, groupedCategories],
  );

  return (
    <FormControl size="small" fullWidth>
      <InputLabel>Select Category Group</InputLabel>

      <Select
        value={categoryGroup}
        onChange={changeHandler}
        label="Select Category Group"
        renderValue={(r) => r}
      >
        {categoryNames.map((name) => {
          return (
            <MenuItem key={name} value={name}>
              <ListItemText>{name}</ListItemText>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
