import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { appliedFilterTemplateSelector } from "../applied/appliedFilterTemplateSelector";
import { currentAccountProviderTypeSelector } from "../../../../account/selectors/current-account/currentAccountProviderTypeSelector";
import { isDeepEqual } from "../../../../../utils/is-deep-equal";
import { appliedFiltersSelector } from "../../appliedFiltersSelector";
import { organisationCurrentFilterSetsLoadingSelector } from "../../current-filter-sets/organisationCurrentFilterSetsLoadingSelector";

export const defaultFilterTemplateFiltersChangedSelector =
  createDraftSafeSelector(
    [
      currentAccountProviderTypeSelector,
      appliedFiltersSelector,
      appliedFilterTemplateSelector,
      organisationCurrentFilterSetsLoadingSelector,
    ],
    (provider, appliedFilters, appliedFilterSet, isLoading) => {
      if (!provider || !appliedFilters || isLoading) {
        return false;
      }

      if (!appliedFilterSet) {
        return true;
      }

      return appliedFilterSet.filters.some((filter) => {
        const appliedFilterByType = appliedFilters[filter.type];

        if (!appliedFilterByType) {
          return false;
        }

        if (
          !filter ||
          !appliedFilterByType ||
          appliedFilterByType?.filter_items?.length !==
            filter?.filter_items?.length ||
          appliedFilterByType?.filter_groups?.length !==
            filter?.filter_groups?.length ||
          appliedFilterByType?.operators?.length !== filter?.operators?.length
        ) {
          return true;
        }

        return !isDeepEqual(appliedFilterByType, filter);
      });
    },
  );
