import { formatCostBreakdownV2WidgetTableViewLastGridItem } from "../../../selectors/widgets/cost-breakdown-v2/utils/helpers/formatCostBreakdownV2WidgetTableViewLastGridItem";
import { ResourceExplorerGridDataWithId } from "../../../../resource-explorer/utils/types/resourceExplorerDataGrid";

export const combineCostBreakdownWidgetGridDataWithOthers = (
  data: ResourceExplorerGridDataWithId[],
) => {
  const lastItems = data.slice(visibleItemsCount, data?.length);
  const startItems = data.slice(0, visibleItemsCount);

  const lastItem = formatCostBreakdownV2WidgetTableViewLastGridItem(lastItems);

  const completeData = [...startItems];

  if (lastItems.length) {
    completeData.push(lastItem);
  }

  return completeData;
};

const visibleItemsCount = 9;
