import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { applyFilterTemplateToAccountsThunk } from "./applyFilterTemplateToAccountsThunk";
import { applyFilterTemplateToCurrentAccount } from "./applyFilterSetToCurrentAccount";
import { RootState } from "../../../store";
import {
  cloudChiprApi,
  AccountResourceFilter2,
  ProviderType,
} from "../../../../services/cloudchipr.api";
import { getOrganisationCurrentFilterTemplatesThunk } from "../../../live-usage-mgmt/thunks/getOrganisationCurrentFilterTemplatesThunk";

type Args = {
  provider: ProviderType;
  name: string;
  filters: AccountResourceFilter2[];
  accountIds?: string[];
};

export const createFilterTemplateThunk = createAsyncThunk(
  "filter/createFilterTemplateThunk",
  async (
    { name, filters, provider, accountIds }: Args,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const currentAccountId = state.account.id;

    const { postUsersMeOrganisationsCurrentFilterSetsV2 } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        postUsersMeOrganisationsCurrentFilterSetsV2.initiate({
          body: {
            name,
            filters,
            cloud_provider: provider,
          },
        }),
      ).unwrap();

      if (accountIds && response) {
        await dispatch(
          applyFilterTemplateToAccountsThunk({
            accountIds,
            filterSetId: response.id,
          }),
        );

        if (currentAccountId && accountIds.includes(currentAccountId)) {
          await dispatch(applyFilterTemplateToCurrentAccount(response.id));
        }
      }

      await dispatch(getOrganisationCurrentFilterTemplatesThunk());

      enqueueSnackbar("New filter template successfully created", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "success",
        },
      });
    } catch (error) {
      // @ts-expect-error // todo: types for this should be fixed
      enqueueSnackbar(error?.data?.message || "Something went wrong", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });

      return error;
    }
  },
);
