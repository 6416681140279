import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import EmptyStateImage from "../../../../../../../../assets/images/empty_recommendation_state.svg";

export const CommitmentsOverviewCommitmentsEmptyState: FC = () => {
  return (
    <Stack alignItems="center" justifyContent="center" py={3}>
      <img
        src={EmptyStateImage}
        alt="EmptyStateImage"
        height={100}
        width={280}
      />
      <Typography variant="body1" fontWeight="bold" mt={1}>
        No Commitments
      </Typography>
      <Typography variant="body2" color="text.secondary" textAlign="center">
        Try changing the date range.
      </Typography>
    </Stack>
  );
};
