import { FC, Fragment, useMemo } from "react";
import { Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { AuthLessCostBreakdownWidgetTableView } from "./AuthLessCostBreakdownWidgetTableView";
import { AuthLessCostBreakdownWidgetNumeralView } from "./AuthLessCostBreakdownWidgetNumeralView";
import { AuthLessCostBreakdownWidgetChartView } from "./AuthLessCostBreakdownWidgetChartView";
import { WidgetLoadingState } from "../../../../../pages/dashboard/components/widgets/common/WidgetLoadingState";
import { useGetWidgetsCostBreakdownByWidgetIdQuery } from "../../../../../../services/cloudchipr-authless.api";
import {
  DashboardWidgetCostBreakdown,
  Dates,
} from "../../../../../../services/cloudchipr.api";
import { ChartType } from "../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { formatCostBreakdownREDataGridData } from "../../../../../../store/dashboards/utils/helpers/costBreakdown/formatCostBreakdownREDataGridData";
import { combineCostBreakdownWidgetGridDataWithOthers } from "../../../../../../store/dashboards/utils/helpers/costBreakdown/combineCostBreakdownWidgetGridDataWithOthers";
import { generateCostBreakdownWidgetCosts } from "../../../../../../store/dashboards/utils/helpers/costBreakdown/generateCostBreakdownWidgetCosts";
import { WidgetHeader } from "../../WidgetHeader";
import { covertDatesToQueryParam } from "../../../../../common/date-range-picker-v2/utils/helpers/covertDatesToQueryParam";

interface AuthLessDashboardCostBreakdownWidgetProps
  extends DashboardWidgetCostBreakdown {
  overrideQueryDates: Dates;
}

export const AuthLessDashboardCostBreakdownWidget: FC<
  AuthLessDashboardCostBreakdownWidgetProps
> = ({
  id,
  name,
  dates,
  chart_type,
  date_granularity,
  grouping,
  forecast_option,
  visualization,
  aggregation,
  overrideQueryDates,
}) => {
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("category_id") ?? undefined;
  const datesFormQuery = covertDatesToQueryParam(overrideQueryDates);

  const { data, isFetching } = useGetWidgetsCostBreakdownByWidgetIdQuery(
    { widgetId: id, categoryId, ...datesFormQuery },
    { refetchOnMountOrArgChange: true },
  );

  const gridData = useMemo(() => {
    const formatedData =
      formatCostBreakdownREDataGridData(data?.total ?? [], true) ?? [];

    if (!formatedData) {
      return [];
    }

    return combineCostBreakdownWidgetGridDataWithOthers(formatedData);
  }, [data?.total]);

  const widgetCostsData = useMemo(() => {
    return generateCostBreakdownWidgetCosts(data, date_granularity);
  }, [data, date_granularity]);

  return (
    <Stack flexGrow={1} overflow="auto">
      <WidgetHeader name={name} dates={dates} grouping={grouping} />

      {isFetching ? (
        <WidgetLoadingState />
      ) : (
        <Fragment>
          {chart_type === "table" && (
            <AuthLessCostBreakdownWidgetTableView
              gridData={gridData}
              grouping={grouping}
              widgetCosts={widgetCostsData}
              forecastOption={forecast_option}
              originalDataCount={data?.total?.length}
              forecastedCosts={data?.forecasted_costs}
              visualization={visualization}
              dateLabel={dates?.label}
              dateDataPoint={date_granularity}
              aggregation={aggregation}
            />
          )}

          {chart_type === "numeral" && (
            <AuthLessCostBreakdownWidgetNumeralView
              data={gridData}
              grouping={grouping}
              loading={isFetching}
              forecastOption={forecast_option}
              visualization={visualization}
              dateLabel={dates?.label}
              dateDataPoint={date_granularity}
              widgetCosts={widgetCostsData}
              aggregation={aggregation}
            />
          )}

          {data && chart_type !== "numeral" && chart_type !== "table" && (
            <AuthLessCostBreakdownWidgetChartView
              loading={isFetching}
              widgetCosts={widgetCostsData}
              widgetData={data}
              dateLabel={dates?.label}
              dateDataPoint={date_granularity}
              chartType={chart_type as ChartType}
              forecastOption={forecast_option}
              visualization={visualization}
              chartBase={visualization?.chart_base_type ?? "cost"}
              aggregation={aggregation}
            />
          )}
        </Fragment>
      )}
    </Stack>
  );
};
