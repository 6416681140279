import { costBreakdownV2SetupPropertyByKeySelector } from "./costBreakdownV2SetupPropertyByKeySelector";
import { RootState } from "../../../../store";
import { WidgetCostBreakdownAggregation } from "../../../../../services/cloudchipr.api";

export const costBreakdownV2SetupHasAggregationSelector = (
  state: RootState,
) => {
  const aggregations: Required<WidgetCostBreakdownAggregation> | null =
    costBreakdownV2SetupPropertyByKeySelector("aggregation")(state);

  return Object.keys(aggregations ?? {}).some(
    (key) => !!aggregations?.[key as keyof WidgetCostBreakdownAggregation],
  );
};
