import { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { WidgetNumeralView } from "../../../common/view-type/WidgetNumeralView";
import { WidgetNumeralViewItem } from "../../../common/view-type/WidgetNumeralViewItem";
import { costBreakdownV2WidgetSetupLoadingSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupLoadingSelector";
import { costBreakdownV2WidgetSetupNoChartViewDataSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupNoChartViewDataSelector";
import { getCostBreakdownV2NumeralItemSecondaryLabel } from "../../../../../../widgets/cost-breakdown-v2/components/chart/numeral/utils/helpers/getCostBreakdownV2NumeralItemSecondaryLabel";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";
import { costBreakdownV2SetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupVisualizationPropertyByKeySelector";
import { CostBreakdownV2WidgetSetupContentCosts } from "../CostBreakdownV2WidgetSetupContentCosts";
import { costBreakdownV2SetupHasAggregationSelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupHasAggregationSelector";

export const CostBreakdownV2WidgetSetupNumeralView: FC = () => {
  const data = useAppSelector(
    costBreakdownV2WidgetSetupNoChartViewDataSelector,
  );
  const showTrend = useAppSelector(
    costBreakdownV2SetupVisualizationPropertyByKeySelector("trend"),
  );
  const showForecastedCost = useAppSelector(
    costBreakdownV2SetupVisualizationPropertyByKeySelector("forecasted_cost"),
  );
  const forecastOption = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("forecastOption"),
  );

  const grouping = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("grouping"),
  );
  const hasAggregations = useAppSelector(
    costBreakdownV2SetupHasAggregationSelector,
  );

  const loading = useAppSelector(costBreakdownV2WidgetSetupLoadingSelector);

  return (
    <Fragment>
      {hasAggregations && (
        <Box px={2} pt={2}>
          <CostBreakdownV2WidgetSetupContentCosts />
        </Box>
      )}

      <WidgetNumeralView
        loading={loading}
        skeletonCount={data?.length}
        width={itemWidth}
      >
        {data?.map((item) => {
          const providers = item?.multiProviders ?? [item.cloud_provider];

          const secondaryLabel = getCostBreakdownV2NumeralItemSecondaryLabel({
            monthlyCost: item?.monthly_forecast?.cost,
            quarterlyCost: item?.quarterly_forecast?.cost,
            grouping,
            forecastOption,
          });

          return (
            <WidgetNumeralViewItem
              key={item.cloud_provider + item.field}
              providers={item.cloud_provider ? providers : []}
              label={item.field}
              cost={item.total_cost}
              trend={showTrend ? item.trend : null}
              itemsCount={data.length}
              secondaryLabel={showForecastedCost ? secondaryLabel : undefined}
              width={itemWidth}
            />
          );
        })}
      </WidgetNumeralView>
    </Fragment>
  );
};

const itemWidth = "20%";
