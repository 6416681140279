import { Box, Stack, Typography } from "@mui/material";
import { ColumnSetupType } from "../../../../../../storybook/data-grid/utils/types/types";
import { ResourceExplorerGridDataWithId } from "../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";
import { ResourceExplorerGrouping } from "../../../../../../services/cloudchipr.api";
import { groupingsExcludingForecastedCostsColumns } from "../../../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/utils/constants/groupingsExcludingForecastedCostsColumns";
import { forecastedCostOptionLabels } from "../../../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/utils/constants";
import { money } from "../../../../../../utils/numeral/money";
import { ProviderIcon } from "../../../../../common/ProviderIcon";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { getResourcesExplorerGroupingLabel } from "../../../../../pages/resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { TotalCostColumnHeaderCell } from "../../../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/TotalCostColumnHeaderCell";
import {
  ForecastedCost,
  ResourceExplorerCostsDateDetails,
  ForecastOption,
  NullableForecastedCost,
  ProviderType,
} from "../../../../../../services/cloudchipr-authless.api";
import { PrevPeriodCostColumnHeaderCell } from "../../../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/PrevPeriodCostColumnHeaderCell";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { TooltipText } from "../../../../../common/TooltipText";
import { TrendColumnHeaderCell } from "../../../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/TrendColumnHeaderCell";
import { TrendChip } from "../../../../../common/chips/TrendChip";
import { ForecastedCostHeaderCell } from "../../../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/ForecastedCostHeaderCell";
import { labelColumnSortingFn } from "../../../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/utils/helpers/sortingFunctions/sortingFunctions";

type ColumnsArgs = {
  dataCount: number;
  grouping: ResourceExplorerGrouping;
  forecastOption?: ForecastOption;
  forecastedCost?: ForecastedCost;
  trend: number | null;

  total?: {
    cost: number;
    trend: number | null;
    dateDetails?: ResourceExplorerCostsDateDetails;
  };

  previousPeriodTotal?: {
    cost: number;
    dateDetails?: ResourceExplorerCostsDateDetails;
  };
};

const getColumnsStartPart = ({
  dataCount,
  grouping,
  total,
  previousPeriodTotal,
  trend,
}: ColumnsArgs): ColumnSetupType<ResourceExplorerGridDataWithId>[] => {
  return [
    {
      size: 400,
      minSize: 150,
      accessorKey: "label",
      header: () => (
        <Stack pl={1}>
          {getResourcesExplorerGroupingLabel(grouping, true)}
          <Typography variant="tiny" color="text.disabled" fontWeight="medium">
            {dataCount}
          </Typography>
        </Stack>
      ),
      sortingFn: labelColumnSortingFn,
      cell: (cell) => {
        const { field, label, cloud_provider } = cell.row.original;
        const name = label ?? field;

        const provider = cloud_provider ? [cloud_provider] : [];
        const multiProviders = cell.row.original?.multiProviders ?? provider;

        return (
          <Stack direction="row" spacing={1} pl={1}>
            {(multiProviders as ProviderType[]).map((item) => {
              return <ProviderIcon key={item} provider={item} width={16} />;
            })}

            <TypographyWithTooltip
              title={name}
              variant="body2"
              maxWidth={300}
            />
          </Stack>
        );
      },
    },
    {
      minSize: 110,
      accessorKey: "total_cost",
      header: () => (
        <TotalCostColumnHeaderCell
          totalCost={total?.cost ?? 0}
          dateDetails={total?.dateDetails}
        />
      ),
      cell: (cell) => {
        const val = cell.getValue() as number;

        return (
          <Typography variant="body2" align="right">
            {isNaN(val) ? "N / A" : money(val)}
          </Typography>
        );
      },
    },
    {
      minSize: 170,
      accessorKey: "previous_period_total_cost",
      header: () => (
        <PrevPeriodCostColumnHeaderCell
          sum={previousPeriodTotal?.cost ?? null}
          dateDetails={previousPeriodTotal?.dateDetails}
        />
      ),
      cell: (cell) => {
        const value = cell.getValue() as number | null;
        const fromDate = formatDate(
          previousPeriodTotal?.dateDetails?.from_date ?? "",
          { type: "date" },
        );
        const toDate = formatDate(
          previousPeriodTotal?.dateDetails?.to_date ?? "",
          {
            type: "date",
          },
        );

        return value === null ? (
          <Box textAlign="right">
            <TooltipText
              tooltipTitle={`No data for Previous Period - ${fromDate} - ${toDate}`}
            >
              N/A
            </TooltipText>
          </Box>
        ) : (
          <Typography variant="body2" align="right">
            {money(value)}
          </Typography>
        );
      },
    },
    {
      size: 100,
      accessorKey: "trend",
      header: () => <TrendColumnHeaderCell trend={trend} />,
      cell: (cell) => {
        const value = cell.getValue() as number | null;

        const fromDate = formatDate(
          previousPeriodTotal?.dateDetails?.from_date ?? "",
          { type: "date" },
        );
        const toDate = formatDate(
          previousPeriodTotal?.dateDetails?.to_date ?? "",
          {
            type: "date",
          },
        );

        if (value === null) {
          return (
            <TooltipText
              tooltipTitle={`No data for Previous Period - ${fromDate} - ${toDate}`}
            >
              N/A
            </TooltipText>
          );
        }

        return <TrendChip value={value} />;
      },
    },
  ];
};

export const getAuthLessCostBreakdownWidgetTableViewColumns = (
  args: ColumnsArgs,
): ColumnSetupType<ResourceExplorerGridDataWithId>[] => {
  const { forecastOption, grouping, forecastedCost } = args;

  const startPart = getColumnsStartPart(args);

  if (
    !grouping ||
    groupingsExcludingForecastedCostsColumns.has(grouping) ||
    !forecastOption
  ) {
    return startPart;
  }

  return [
    ...startPart,
    {
      minSize: 190,
      accessorKey:
        forecastOption === "month" ? "monthly_forecast" : "quarterly_forecast",
      header: () => {
        if (!forecastOption) {
          return null;
        }

        return (
          <ForecastedCostHeaderCell
            forecastOption={forecastOption}
            forecastedCost={forecastedCost}
          />
        );
      },
      meta: {
        headerTitle: `Forecasted ${forecastedCostOptionLabels.get(
          "month",
        )} Cost`,
      },
      cell: (cell) => {
        const data: NullableForecastedCost = cell.getValue();

        return (
          <Typography variant="body2" align="right" pr={1}>
            {data === null || data === undefined
              ? "-"
              : `~${money(data?.cost)}`}
          </Typography>
        );
      },
    },
  ];
};
