import { ResourceExplorerPath } from "../types/resourceExplorer";
import {
  FilterGroupNode,
  FilterItemNode,
  FilterTreeNode,
  ProviderType,
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerFilterProvider,
  ResourceExplorerGrouping,
} from "../../../../services/cloudchipr.api";
import { filterTreeKeyByCurrentGrouping } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/constants/groupings";

type Args = {
  pathName: string;
  filterValue?: string;
  currentGroupBy: ResourceExplorerGrouping;
  currentPath: ResourceExplorerPath[];
  aggregated: boolean;
  provider: ProviderType;
};

export const generateFilterTreeForPath = (
  { filterValue, aggregated, provider, currentPath, currentGroupBy }: Args,
  dimensionId?: string,
) => {
  const pathLastItem = currentPath.at(-1) ?? ({} as ResourceExplorerPath);
  const pathListItemFilterTree = pathLastItem?.filterTree as FilterGroupNode;

  let filterTree: FilterGroupNode = pathListItemFilterTree
    ? { ...pathListItemFilterTree }
    : {
        operator: "and",
        node_type: "group",
        items: [],
      };

  if (!currentGroupBy) {
    return filterTree;
  }

  const filterKey = filterTreeKeyByCurrentGrouping[currentGroupBy]?.key;
  const filterType = filterTreeKeyByCurrentGrouping[currentGroupBy]?.type;

  if (!filterKey || !filterValue) {
    return filterTree;
  }

  const filterTreeItem = getFilterTreeItemValue(
    filterKey,
    filterType,
    filterValue,
    pathLastItem,
    aggregated,
    currentPath.length > 1,
    provider,
    dimensionId,
  );

  filterTree = {
    node_type: "group",
    operator: "and",
    items: [filterTree, filterTreeItem],
  };

  return filterTree;
};

const getFilterTreeItemValue = (
  filterKey: ResourceExplorerDynamicFilterItemType,
  filterType: string,
  filterValue: string,
  pathLastItem: ResourceExplorerPath,
  aggregated: boolean,
  isOnlyKeyFilter: boolean,
  provider: ProviderType,
  dimensionId?: string,
): FilterTreeNode => {
  const filterProvider = filterProviderByFilterType[filterKey] ?? provider;

  const filterItem: FilterItemNode = {
    value: null,
    operator: "in",
    type: filterKey,
    node_type: "item",
    filter_provider: filterProvider,
  };

  if (filterType === "array") {
    filterItem.value = [filterValue];

    return filterItem;
  }

  if (filterKey === "dimension_id" && dimensionId) {
    filterItem.value = { key: dimensionId, value: [filterValue] };

    return filterItem;
  }

  const key = isOnlyKeyFilter ? pathLastItem.name : filterValue;

  if (!aggregated) {
    filterItem.operator = isOnlyKeyFilter ? "in" : "exists";
    filterItem.value = { key, value: isOnlyKeyFilter ? [filterValue] : [] };

    return filterItem;
  }

  return {
    node_type: "group",
    operator: "and",
    items:
      pathLastItem.groupValues?.map((key) => ({
        ...filterItem,
        value: { key, value: [] },
        operator: "does_not_exist",
      })) || [],
  };
};

const filterProviderByFilterType: Partial<
  Record<ResourceExplorerDynamicFilterItemType, ResourceExplorerFilterProvider>
> = {
  dimension_id: "dimensions",
  cloud_provider: "global",
};
