import { FC } from "react";
import { Stack } from "@mui/material";
import { CloudChiprCell } from "./CloudChiprCell";
import { DeletedUserCell } from "./DeletedUserCell";
import { TypographyWithTooltip } from "../TypographyWithTooltip";
import { formatDate } from "../../../utils/helpers/date-time/datetime-format";

interface ResourceExplorerCreatedByCellProps {
  name?: string;
  date?: string;
  byCloudchipr?: boolean;
  deleted?: boolean;
}

export const UserActivityCell: FC<ResourceExplorerCreatedByCellProps> = ({
  name,
  byCloudchipr,
  deleted,
  date,
}) => {
  if (byCloudchipr) {
    return <CloudChiprCell />;
  }

  if (deleted) {
    return <DeletedUserCell />;
  }

  if (!name) {
    return "-";
  }

  return (
    <Stack spacing={0.25}>
      <TypographyWithTooltip variant="body2" title={name} />
      {date && (
        <TypographyWithTooltip
          variant="caption"
          sx={{ fontSize: 10 }}
          color="text.secondary"
          title={formatDate(date, { type: "dateTime" })}
        />
      )}
    </Stack>
  );
};
