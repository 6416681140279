import { RootState } from "../../../store";
import { FilterSet } from "../../../../services/cloudchipr.api";
import { organisationCurrentFilterSetsSelector } from "../current-filter-sets/organisationCurrentFilterSetsSelector";

export const filterTemplateByIdSelector = (
  state: RootState,
  id: string,
): FilterSet | undefined => {
  const filterSets = organisationCurrentFilterSetsSelector(state);

  return filterSets?.find((filter_set) => filter_set.id === id);
};
