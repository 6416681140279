import { FC, SyntheticEvent, useCallback } from "react";
import { RadioGroup, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { TrendingUp } from "@mui/icons-material";
import { ViewTrendSettingsRadioButton } from "./ViewTrendSettingsRadioButton";
import { setResourceExplorerTrendType } from "../../../../../../../../../../store/resource-explorer/resourceExplorerSlice";
import { TrendType } from "../../../../../../../../../../services/cloudchipr.api";
import { getResourceExplorerDataThunk } from "../../../../../../../../../../store/resource-explorer/thunks/widgets/getResourceExplorerDataThunk";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { resourceExplorerPathLastItemFilterTreeSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/path/resourceExplorerPathLastItemFilterTreeSelector";

export const ViewTrendSettings: FC = () => {
  const dispatch = useAppDispatch();

  const pathLastItemFilterTree = useAppSelector(
    resourceExplorerPathLastItemFilterTreeSelector,
  );

  const handleChange = useCallback(
    (event: SyntheticEvent, checked: boolean) => {
      const target = event.target as HTMLInputElement;

      if (checked) {
        dispatch(
          setResourceExplorerTrendType(target.defaultValue as TrendType),
        );
        dispatch(getResourceExplorerDataThunk(pathLastItemFilterTree));
      }
    },
    [dispatch, pathLastItemFilterTree],
  );

  return (
    <Stack px={2} py={1} spacing={1}>
      <Stack direction="row" spacing={0.5}>
        <TrendingUp fontSize="small" />
        <Typography variant="body2" fontWeight="bold">
          Trend Settings
        </Typography>
      </Stack>
      <Typography variant="caption" lineHeight={1.25}>
        Set up the logic for the{" "}
        <Typography component="span" variant="inherit" fontWeight="bold">
          Previous Period Cost
        </Typography>{" "}
        and{" "}
        <Typography component="span" variant="inherit" fontWeight="bold">
          Trend
        </Typography>{" "}
        columns in the table, as well as the other trends.
      </Typography>
      <Typography variant="caption" lineHeight={1.25}>
        This setting is relevant for date ranges less than 30 days.
      </Typography>
      <RadioGroup>
        <ViewTrendSettingsRadioButton
          value="month_over_month"
          onChange={handleChange}
        />
        <ViewTrendSettingsRadioButton
          value="period_over_period"
          onChange={handleChange}
        />
      </RadioGroup>
    </Stack>
  );
};
