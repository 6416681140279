import { FC, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDidMount } from "rooks";
import { Box, Stack, Typography, Tooltip } from "@mui/material";
import { TabPanel } from "@mui/lab";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TimePicker } from "./TimePicker";
import {
  startEndDatesValidation,
  resourceStopStartValidation,
} from "../../../../../common/utils/constants/validation";
import { AutomationsWeekDays } from "../../../../../../automations/common/components/AutomationsWeekDays";
import { StopStartDatesTitle } from "../date-time/StopStartDatesTitle";
import {
  DateTimeContent,
  ScheduleDatesInterface,
} from "../date-time/DateTimeContent";
import { NextPrevious } from "../../form/tools/NextPrevious";
import { OffHoursSchedulerData } from "../../../../utils/types/types";
import { getTimeFromCron } from "../../../../../../../../store/automations/utils/helpers/cron/getTimeFromCron";
import { parseCron } from "../../../../../../../../store/automations/utils/helpers/cron/parseCron";
import { generateWeeklyCron } from "../../../../../../../../store/automations/utils/helpers/cron/generateWeeklyCron";

interface InitialValuesInterface {
  runAt: string | null;
  endAt: string | null;
  stopWeekDays: number[];
  startWeekDays: number[];
}

const initialValues: InitialValuesInterface & ScheduleDatesInterface = {
  runAt: null,
  endAt: null,
  startWeekDays: [],
  stopWeekDays: [],
  startDate: "",
  endDate: "",
  timeZone: "",
  id: "",
};

const validationSchema = Yup.object({
  startWeekDays: Yup.array().of(Yup.number()).min(1, "Please select week day"),
  stopWeekDays: Yup.array().of(Yup.number()).min(1, "Please select week day"),
  runAt: resourceStopStartValidation("start"),
  endAt: resourceStopStartValidation("stop"),
  startDate: startEndDatesValidation,
  endDate: startEndDatesValidation,
});

interface WeeklyProps {
  stopCron: string | null;
  startCron: string | null;
  setScheduleDatesData(data: OffHoursSchedulerData): void;
  onPreviousStep(): void;
  isDirty: boolean;
}

export const Weekly: FC<WeeklyProps & ScheduleDatesInterface> = ({
  setScheduleDatesData,
  startCron,
  stopCron,
  onPreviousStep,
  timeZone,
  endDate,
  startDate,
  id,
  isDirty,
}) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const startCron = generateWeeklyCron(values.startWeekDays, values.runAt);
      const stopCron = generateWeeklyCron(values.stopWeekDays, values.endAt);

      setScheduleDatesData({
        startDate: values.startDate,
        endDate: values.endDate,
        startCron,
        stopCron,
        timeZone: values.timeZone,
      });
    },
  });

  const {
    values,
    errors,
    touched,
    submitForm,
    setFieldValue,
    resetForm,
    dirty,
    isValid,
    setValues,
  } = formik;

  const changeHandler = useCallback(
    (name: "startWeekDays" | "stopWeekDays") => (days: number[]) =>
      setFieldValue(name, days),
    [setFieldValue],
  );

  useDidMount(() => {
    const startWeekDays = parseCron(startCron).daysOfWeek as number[];
    const stopWeekDays = parseCron(stopCron).daysOfWeek as number[];

    const values = {
      startWeekDays: startWeekDays || [],
      stopWeekDays: stopWeekDays || [],
      runAt: getTimeFromCron(startCron),
      endAt: getTimeFromCron(stopCron),
      timeZone,
      endDate,
      startDate,
      id,
    };

    if (id) {
      resetForm({ values });
    } else {
      setValues(values);
    }
  });

  return (
    <TabPanel value="weekly">
      <form>
        <StopStartDatesTitle title="Stop Resources On" type="stop" />
        <Stack direction="row" alignItems="baseline" mt={1} mb={3}>
          <AutomationsWeekDays
            color="warning"
            weekDays={values.stopWeekDays}
            onChange={changeHandler("stopWeekDays")}
            error={
              (touched.stopWeekDays && errors.stopWeekDays) ||
              (touched.endAt && errors.endAt)
            }
          />

          <Typography variant="subtitle2" mr={1}>
            at
          </Typography>
          <TimePicker
            name="endAt"
            setFieldValue={setFieldValue}
            error={touched.endAt && errors.endAt}
            value={values.endAt!}
          />
        </Stack>

        <StopStartDatesTitle title="Start Resources On" type="start" />
        <Stack direction="row" alignItems="center" my={1}>
          <AutomationsWeekDays
            color="success"
            weekDays={values.startWeekDays}
            onChange={changeHandler("startWeekDays")}
            error={
              (touched.startWeekDays && errors.startWeekDays) ||
              (touched.runAt && errors.runAt)
            }
          />
          <Typography variant="subtitle2" mr={1}>
            at
          </Typography>
          <TimePicker
            name="runAt"
            setFieldValue={setFieldValue}
            error={touched.runAt && errors.runAt}
            value={values.runAt!}
          />
          <Tooltip
            arrow
            title="Cloudchipr will only start resources that were stopped previously from this schedule. No other resources will be affected."
          >
            <InfoOutlinedIcon
              sx={{ verticalAlign: "middle", color: "text.secondary", ml: 2 }}
              fontSize="small"
            />
          </Tooltip>
        </Stack>

        <DateTimeContent
          endDate={values.endDate}
          startDate={values.startDate}
          timeZone={values.timeZone}
          endDateError={touched.endDate && errors.endDate}
          startDateError={touched.startDate && errors.startDate}
          setFieldValue={setFieldValue}
        />

        <Box mt={12}>
          <NextPrevious
            isNextDisabled={!id ? !dirty || !isValid : !isValid}
            onNext={submitForm}
            onPrevious={onPreviousStep}
            isDirty={dirty || isDirty}
          />
        </Box>
      </form>
    </TabPanel>
  );
};
