import { FC, useCallback } from "react";
import { SwitchWithTypography } from "../../../../../../../../../../SwitchWithTypography";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { costBreakdownV2SetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupVisualizationPropertyByKeySelector";
import { costBreakdownV2ViewVisualizationChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2ViewVisualizationChangeThunk";

export const CostBreakdownV2WidgetVisualizationRegionColumn: FC = () => {
  const dispatch = useAppDispatch();
  const regionColumnChecked = useAppSelector(
    costBreakdownV2SetupVisualizationPropertyByKeySelector("region_column"),
  );

  const handleRegionColumnCheckedChange = useCallback(
    (checked: boolean) => {
      dispatch(
        costBreakdownV2ViewVisualizationChangeThunk({
          region_column: checked,
        }),
      );
    },
    [dispatch],
  );

  return (
    <SwitchWithTypography
      checked={regionColumnChecked}
      onChange={handleRegionColumnCheckedChange}
      title="Region"
    />
  );
};
