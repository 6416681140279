import { FC, Fragment } from "react";
import { CostBreakdownV2WidgetVisualizationSection } from "./components/visualization/CostBreakdownV2WidgetVisualizationSection";
import { CostBreakdownV2WidgetAggregationsSection } from "./components/aggregations/CostBreakdownV2WidgetAggregationsSection";
import { CostBreakdownV2WidgetAdvancedSettingsSection } from "./components/advanced-settings/CostBreakdownV2WidgetAdvancedSettingsSection";

export const CostBreakdownV2WidgetConfigurations: FC = () => {
  return (
    <Fragment>
      <CostBreakdownV2WidgetVisualizationSection />
      <CostBreakdownV2WidgetAggregationsSection />
      <CostBreakdownV2WidgetAdvancedSettingsSection />
    </Fragment>
  );
};
