import { FC, useCallback } from "react";
import { useToggle } from "rooks";
import { Card, CircularProgress, Collapse, Stack } from "@mui/material";
import { CategoryCollapseHeader } from "./CategoryCollapseHeader";
import { DimensionCategory } from "../../../../../../../../store/dimensions/utils/types/common";
import { useAppDispatch } from "../../../../../../../../store/hooks";
import { setCategoryFilterTree } from "../../../../../../../../store/dimensions/dimensionsSlice";
import { useGetUsersMeOrganisationsCurrentResourceExplorerPossibleFiltersQuery } from "../../../../../../../../services/cloudchipr.api";
import { FilterTree } from "../../../../../../common/filters-tree/components/FilterTree";
import { FilterGroupNodeWithId } from "../../../../../../common/filters-tree/utils/types/common";

interface CategoryItemProps {
  category: DimensionCategory;
  deleteDisabled?: boolean;
}

export const CategoryItem: FC<CategoryItemProps> = ({
  category,
  deleteDisabled,
}) => {
  const { data, isLoading } =
    useGetUsersMeOrganisationsCurrentResourceExplorerPossibleFiltersQuery({});
  const dispatch = useAppDispatch();
  const [collapsed, toggleCollapsed] = useToggle();

  const filterTreeChangeHandler = useCallback(
    (tree: FilterGroupNodeWithId) => {
      dispatch(setCategoryFilterTree({ id: category.id, tree }));
    },
    [dispatch, category.id],
  );

  return (
    <Card variant="outlined">
      <CategoryCollapseHeader
        title="Filters"
        collapsed={collapsed}
        category={category}
        deleteDisabled={!!deleteDisabled}
        toggleCollapsed={toggleCollapsed}
      />

      <Collapse in={!collapsed}>
        <Stack p={1}>
          {isLoading ? (
            <Stack alignItems="center" padding={2}>
              <CircularProgress />
            </Stack>
          ) : (
            <FilterTree
              possibleFilters={data ?? []}
              filterTree={category.filter_tree}
              setFilters={filterTreeChangeHandler}
            />
          )}
        </Stack>
      </Collapse>
    </Card>
  );
};
