import { ChangeEvent, FC, useCallback } from "react";
import { Divider, Stack, TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useDebounce } from "rooks";
import {
  ProviderType,
  useGetUsersMeOrganisationsCurrentProvidersByProviderTagKeysQuery,
} from "../../../../../../services/cloudchipr.api";

interface CostAllocationTagsDrawerHeaderProps {
  provider: ProviderType;
  onClose(): void;
  setSearchValue(value: string): void;
}

export const CostAllocationTagsDrawerHeader: FC<
  CostAllocationTagsDrawerHeaderProps
> = ({ onClose, setSearchValue, provider }) => {
  const { data } =
    useGetUsersMeOrganisationsCurrentProvidersByProviderTagKeysQuery({
      provider,
    });

  const searchChangeDebouncedHandler = useDebounce(setSearchValue, 300);

  const searchChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      searchChangeDebouncedHandler(e.target.value);
    },
    [searchChangeDebouncedHandler],
  );

  return (
    <Stack>
      <Stack
        p={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" fontWeight="medium">
          AWS Tags {!!data?.length && `(${data?.length})`}
        </Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Stack>

      <Divider />

      <Stack p={2} pb={1} spacing={1}>
        <Typography variant="body1">
          All Cost Allocation Tags are enabled by default and can be managed
          from the AWS Console. To enable additional tags and use them as a
          filter in the Resource Explorer, please enable them from the list
          below.
        </Typography>

        <TextField
          size="small"
          onChange={searchChangeHandler}
          placeholder="Search by tag key"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Stack>
  );
};
