import { FC } from "react";
import { Chip } from "@mui/material";
import { PersonOffOutlined } from "@mui/icons-material";

export const DeletedUserCell: FC = () => {
  return (
    <Chip
      size="small"
      sx={{ px: 0.25, width: "fit-content" }}
      icon={<PersonOffOutlined fontSize="small" />}
      label="Deleted"
    />
  );
};
