import { FC, useCallback, useMemo } from "react";
import { Stack } from "@mui/material";
import { CostBreakdownV2WidgetAggregationsSwitch } from "./CostBreakdownV2WidgetAggregationsSwitch";
import { WidgetConfigSection } from "../../../../common/widget-configurations/WidgetConfigSection";
import AggregationsIcon from "../../../../../../../../../../../assets/images/icons/aggregations_icon.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { costBreakdownV2WidgetSetupAverageExistsSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupAverageExistsSelector";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";
import { getCostBreakdownAggregationsSwitchesData } from "../../utils/helpers/getCostBreakdownAggregationsSwitchesData";
import { costBreakdownV2ViewAggregationsChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2ViewAggregationsChangeThunk";
import { WidgetCostBreakdownAggregation } from "../../../../../../../../../../../services/cloudchipr.api";

export const CostBreakdownV2WidgetAggregationsSection: FC = () => {
  const dispatch = useAppDispatch();

  const averageExists = useAppSelector(
    costBreakdownV2WidgetSetupAverageExistsSelector,
  );
  const frequency = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("frequency"),
  );
  const aggregations = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("aggregation"),
  );

  const switchChecked = useMemo(
    () => Object.keys(aggregations).some((key) => !!aggregations[key]),
    [aggregations],
  );
  const aggregationsSwitchesData = useMemo(
    () => getCostBreakdownAggregationsSwitchesData(averageExists, frequency),
    [averageExists, frequency],
  );

  const handleSwitchChange = useCallback(() => {
    const aggregationsNewState = Object.keys(aggregations)?.reduce(
      (acc, key) => {
        acc[key as keyof WidgetCostBreakdownAggregation] = !switchChecked;
        return acc;
      },
      {} as WidgetCostBreakdownAggregation,
    );

    dispatch(costBreakdownV2ViewAggregationsChangeThunk(aggregationsNewState));
  }, [aggregations, switchChecked, dispatch]);

  return (
    <WidgetConfigSection
      title="Aggregations"
      icon={<img src={AggregationsIcon} alt="Aggregations Icon" />}
      switchChecked={switchChecked}
      onSwitchChange={handleSwitchChange}
    >
      <Stack spacing={1.5}>
        {aggregationsSwitchesData.map(({ selectorKey, title }) => (
          <CostBreakdownV2WidgetAggregationsSwitch
            selectorKey={selectorKey}
            title={title}
            key={selectorKey}
          />
        ))}
      </Stack>
    </WidgetConfigSection>
  );
};
