import { v4 as uuid } from "uuid";
import { DimensionCategories } from "../../../../../store/dimensions/utils/types/common";

export const generateEmptyCategory = (n?: number): DimensionCategories => {
  const id = uuid();

  return {
    [id]: {
      id,
      name: `Category ${(n ?? 0) + 1}`,
      filter_tree: {
        node_type: "group",
        operator: "or",
        items: [],
      },
    },
  };
};
