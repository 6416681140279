import React, { FC, Fragment } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { CleanFAB } from "./pages/common/clean/CleanFAB";
import { BillingData } from "./pages/common/billing/billing-data/BillingData";
import { useRefreshTokenHook } from "./utils/hooks/useRefreshTokenHook";
import { CleanV2FAB } from "./pages/common/clean-v2/CleanV2FAB";

export const Common: FC = () => {
  useRefreshTokenHook();
  const enableLiveUsageMultiAccountSelect = useFlag(
    "EnableLiveUsageMultiAccountSelect",
  );
  return (
    <Fragment>
      {enableLiveUsageMultiAccountSelect ? <CleanV2FAB /> : <CleanFAB />}

      <BillingData />
    </Fragment>
  );
};
