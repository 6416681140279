import { FC } from "react";
import { blue, red } from "@mui/material/colors";
import { Box, Skeleton, Typography, TypographyVariant } from "@mui/material";

type ResourceStatisticPropsSize = "small" | "medium" | "large";

interface LiveUsageMgmtResourceStatisticProps {
  value: string;
  color: "red" | "blue";
  hasBackground?: boolean;
  loading?: boolean;
  size?: ResourceStatisticPropsSize;
}

export const LiveUsageMgmtResourceStatistic: FC<
  LiveUsageMgmtResourceStatisticProps
> = ({ value, color, hasBackground, loading, size = "medium" }) => {
  const styles = {
    backgroundColor:
      ResourceStatisticsItemBackground.get(!!hasBackground)?.get(color),
    color: ResourceStatisticsItemColor.get(size)?.get(color),
    borderColor: ResourceStatisticsItemBorderColor.get(size)?.get(color),
    fontWeight: ResourceStatisticsItemTypography.get(size)?.fontWeight,
    fontSize: ResourceStatisticsItemTypography.get(size)?.fontSize,
    borderLeft: ResourceStatisticsItemBorder.get(size)?.borderLeft,
  };

  return (
    <Box
      borderLeft={styles.borderLeft}
      borderColor={styles.borderColor}
      borderRadius={0.5}
      bgcolor={styles.backgroundColor}
    >
      {loading ? (
        <Skeleton variant="text" height={20} width={40} />
      ) : (
        <Typography
          color={styles.color}
          variant="body1"
          fontWeight={styles.fontWeight}
          px={0.5}
          fontSize={styles.fontSize}
        >
          {value}
        </Typography>
      )}
    </Box>
  );
};

type ResourceStatisticsItemBackgroundType = Map<"blue" | "red", string>;

const ResourceStatisticsItemBackgroundColor = new Map<"blue" | "red", string>([
  ["blue", blue[50]],
  ["red", red[50]],
]);

const ResourceStatisticsItemColorWithBackgroundValue = new Map<
  "blue" | "red",
  string
>([
  ["blue", blue[800]],
  ["red", red[600]],
]);

const ResourceStatisticsItemColorWithoutBackgroundValue = new Map<
  "blue" | "red",
  string
>([
  ["blue", "white"],
  ["red", "white"],
]);

const ResourceStatisticsItemBorderColorValue = new Map<"blue" | "red", string>([
  ["blue", blue[800]],
  ["red", red[600]],
]);

type ResourceStatisticsItemTypographyType = {
  variant?: TypographyVariant;
  fontSize?: number;
  fontWeight?: number | string;
  color?: string;
};

type ResourceStatisticsItemBorderType = {
  borderLeft: number;
};

const ResourceStatisticsItemBackground = new Map<
  boolean,
  ResourceStatisticsItemBackgroundType | undefined
>([
  [true, ResourceStatisticsItemBackgroundColor],
  [false, undefined],
]);

const ResourceStatisticsItemColor = new Map<
  ResourceStatisticPropsSize,
  ResourceStatisticsItemBackgroundType | undefined
>([
  ["small", ResourceStatisticsItemColorWithBackgroundValue],
  ["medium", ResourceStatisticsItemColorWithoutBackgroundValue],
]);

const ResourceStatisticsItemBorderColor = new Map<
  ResourceStatisticPropsSize,
  ResourceStatisticsItemBackgroundType | undefined
>([
  ["small", ResourceStatisticsItemBorderColorValue],
  ["medium", ResourceStatisticsItemBorderColorValue],
]);

const ResourceStatisticsItemTypography = new Map<
  ResourceStatisticPropsSize,
  ResourceStatisticsItemTypographyType | undefined
>([
  ["medium", { fontWeight: "", fontSize: 16 }],
  ["small", { fontWeight: "bold", fontSize: 10 }],
]);

const ResourceStatisticsItemBorder = new Map<
  ResourceStatisticPropsSize,
  ResourceStatisticsItemBorderType
>([
  ["medium", { borderLeft: 4 }],
  ["small", { borderLeft: 2 }],
]);
