import { FC, useCallback } from "react";
import AdvancedSettingsIcon from "../../../../../../../../../assets/images/icons/advanced_settings_icon.svg";
import { WidgetConfigSection } from "../../common/widget-configurations/WidgetConfigSection";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { costByCategorySetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-by-category/costByCategorySetupPropertyByKeySelector";
import { costByCategoryWidgetForecastedOptionsSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-by-category/costByCategoryWidgetForecastedOptionsSelector";
import { costByCategorySetupChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/cost-by-category/costByCategorySetupChangeThunk";
import { WidgetForecastSelect } from "../../common/toolbar/WidgetForecastSelect";

export const CostByCategorySetupAdvancedSettingsSection: FC = () => {
  const dispatch = useAppDispatch();

  const forecastOption = useAppSelector(
    costByCategorySetupPropertyByKeySelector("forecastOption"),
  );
  const forecastedCostsOptions = useAppSelector(
    costByCategoryWidgetForecastedOptionsSelector,
  );
  const forecastChangeHandler = useCallback(
    (event: any) => {
      dispatch(
        costByCategorySetupChangeThunk({
          forecastOption: event.target.value,
        }),
      );
    },
    [dispatch],
  );

  return (
    <WidgetConfigSection
      title="Advanced Settings"
      icon={<img src={AdvancedSettingsIcon} alt="Advanced Settings Icon" />}
    >
      <WidgetForecastSelect
        forecast={forecastOption}
        onChange={forecastChangeHandler}
        options={forecastedCostsOptions}
      />
    </WidgetConfigSection>
  );
};
