import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { ResourceType } from "../../../../../../services/cloudchipr.api";
import { getResourceTypeName } from "../../../../../../utils/helpers/resources/getResourceTypeName";

interface ResourceCardHeaderTitleProps {
  resourceType: ResourceType;
}

export const LiveUsageMgmtResourceCardHeaderTitle: FC<
  ResourceCardHeaderTitleProps
> = ({ resourceType }) => {
  const shortTitle = (
    <Stack>
      <Typography color="grey.50" variant="subtitle1" fontWeight="bold">
        {getResourceTypeName(resourceType)}
      </Typography>
    </Stack>
  );
  const longTitle = (
    <Typography variant="caption" fontWeight="bold" color="grey.300">
      {getResourceTypeName(resourceType, {
        type: "long",
      })}
    </Typography>
  );

  return (
    <Stack>
      {shortTitle}
      {longTitle}
    </Stack>
  );
};
