import { FC, Fragment } from "react";
import { ListSubheader, Typography } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { IntegrationMenuItemV2 } from "./IntegrationMenuItemV2";
import { integrationOptions } from "../../../../../../../../../../../account/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/constants/constants";
import { ResourceType } from "../../../../../../../../../../../../../services/cloudchipr.api";

interface IntegrationMenuItemsProps {
  resourceType: ResourceType;
  id: string;
}

export const IntegrationMenuItemsV2: FC<IntegrationMenuItemsProps> = ({
  id,
  resourceType,
}) => {
  const enableSlackFromAccountPage = useFlag("EnableSlackFromAccountPage");
  const enableEmailFromAccountPage = useFlag("EnableEmailFromAccountPage");

  return (
    <Fragment>
      <ListSubheader>
        <Typography fontSize="smaller">INTEGRATIONS</Typography>
      </ListSubheader>

      {integrationOptions.map(({ type, label }) => (
        <IntegrationMenuItemV2
          id={id}
          key={type}
          type={type}
          disabled={
            (type === "slack" && !enableSlackFromAccountPage) ||
            (type === "email" && !enableEmailFromAccountPage)
          }
          resourceType={resourceType}
          label={label}
        />
      ))}
    </Fragment>
  );
};
