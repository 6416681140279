import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { InfoDataChip } from "./InfoDataChip";
import { SourceDataChip, SourceDataChipProps } from "./SourceDataChip";
import { DateInfoChipProps } from "./DateInfoChip";
import { DateInfoChipV2 } from "../../../../adding-widget/widget-create/widget-setups/common/v2/DateInfoChipV2";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";

interface WidgetTitleProps {
  widgetName: string;
  grouping?: string;
  dates: DateInfoChipProps;
  sourceData?: SourceDataChipProps;
}

type WidgetSourceInfoProps = Omit<WidgetTitleProps, "widgetName">;

export const WidgetSourceInfoV2: FC<WidgetSourceInfoProps> = ({
  grouping,
  dates,
  sourceData,
}) => {
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  return (
    <Stack direction="row" gap={1} alignItems="center" overflow="auto">
      {sourceData && <SourceDataChip {...sourceData} />}

      {grouping && (
        <InfoDataChip>
          Group By:{" "}
          <Typography
            component="span"
            variant="inherit"
            fontWeight="medium"
            color="text.primary"
          >
            {grouping}
          </Typography>
        </InfoDataChip>
      )}

      <DateInfoChipV2 {...dates} quarterStartMonth={quarterStartMonth} />
    </Stack>
  );
};
