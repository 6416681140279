import { FC } from "react";
import { Stack } from "@mui/material";
import { DataPointSelector } from "./components/data-point-selector/DataPointSelector";
import { ResourceExplorerGroupBySelector } from "./components/ResourceExplorerGroupBySelector";
import { ResourceExplorerDateRange } from "./components/ResourceExplorerDateRange";
import { ViewSettings } from "./components/view-settings/ViewSettings";
import { ResourceExplorerFilterTree } from "./components/filter-tree/ResourceExplorerFilterTree";
import { useAppSelector } from "../../../../../../../store/hooks";
import { isBillingAvailableSelector } from "../../../../../../../store/common/selectors/billing-status/isBillingAvailableSelector";

export const ResourceExplorerToolbar: FC = () => {
  const isResourceExplorerAvailable = useAppSelector(
    isBillingAvailableSelector,
  );

  if (!isResourceExplorerAvailable) {
    return null;
  }

  return (
    <Stack
      px={2}
      py={1.5}
      direction="row"
      borderBottom={1}
      borderColor="grey.300"
      justifyContent="space-between"
    >
      <Stack spacing={1.5} direction="row" flexWrap="wrap">
        <ResourceExplorerFilterTree />

        <ResourceExplorerGroupBySelector />

        <Stack direction="row" justifySelf="end" spacing={1}>
          <ResourceExplorerDateRange />

          <DataPointSelector />
        </Stack>
      </Stack>

      <ViewSettings />
    </Stack>
  );
};
