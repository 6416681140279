import { FC, useMemo } from "react";
import { Box } from "@mui/material";
import { AgGridReact } from "@ag-grid-community/react";
import { getCommitmentUtilizationTableViewColumnDefs } from "../../../adding-widget/widget-create/widget-setups/commitment-utilization /utils/helpers/getCommitmentUtilizationTableViewColumnDefs";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentUtilizationWidgetByWidgetIdDataSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetByWidgetIdDataSelector";
import { commitmentUtilizationWidgetVisualizationSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetVisualizationSelector";
import {
  commitmentUtilizationTableOptions,
  commitmentUtilizationTableWrapperStyles,
} from "../utils/constants/constants";

interface CommitmentUtilizationWidgetTableViewProps {
  widgetId: string;
}

export const CommitmentUtilizationWidgetTableView: FC<
  CommitmentUtilizationWidgetTableViewProps
> = ({ widgetId }) => {
  const data = useAppSelector((state) =>
    commitmentUtilizationWidgetByWidgetIdDataSelector(state, widgetId),
  );
  const { groups } = data ?? {};
  const visualization = useAppSelector((state) =>
    commitmentUtilizationWidgetVisualizationSelector(state, widgetId),
  );

  const columnDefs = useMemo(
    () => getCommitmentUtilizationTableViewColumnDefs(visualization),
    [visualization],
  );

  return (
    <Box
      className="ag-theme-quartz"
      sx={commitmentUtilizationTableWrapperStyles}
    >
      <AgGridReact
        enableCellTextSelection
        rowHeight={40}
        rowData={groups ?? []}
        columnDefs={columnDefs}
        gridOptions={commitmentUtilizationTableOptions}
      />
    </Box>
  );
};
