import { FC, useCallback } from "react";
import { Box, Link, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  FilterSet,
  ProviderType,
} from "../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../store/hooks";
import { applyFilterSetLoadingSelector } from "../../../../../../store/filters/selectors/filter-set/applyFilterSetLoadingSelector";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import c8rLogo from "../../../../../../assets/images/logos/c8r-logo.svg";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { FilterTemplatesFiltersDialog } from "../templates-table/templates-filters/FilterTemplatesFiltersDialog";

interface FilterTemplateDropdownItemProps {
  applied: boolean;
  disabled: boolean;
  filterSet: FilterSet;
  onApply(filterSetId: string): void;
  loading?: boolean;
  provider?: ProviderType;
}

export const FilterTemplateDropdownItem: FC<
  FilterTemplateDropdownItemProps
> = ({ onApply, applied, disabled, loading, filterSet, provider }) => {
  const { name, source, id } = filterSet;
  const applyFilterLoading = useAppSelector((state) =>
    applyFilterSetLoadingSelector(state, id),
  );

  const { open, openDialog, closeDialog } = useDialog();

  const applyClickHandler = useCallback(() => {
    onApply(id);
  }, [onApply, id]);

  return (
    <Stack justifyContent="space-between" direction="row" alignItems="center">
      <Link
        component="button"
        variant="body1"
        onClick={openDialog}
        sx={{ overflow: "hidden" }}
      >
        <Stack direction="row" spacing={0.5}>
          {source === "cloudchipr" && <img src={c8rLogo} alt="C8R-logo" />}
          <TypographyWithTooltip title={name} />
        </Stack>
      </Link>

      <Box>
        <LoadingButton
          size="small"
          loading={applyFilterLoading || loading}
          variant="outlined"
          disabled={applyFilterLoading || loading || disabled || applied}
          onClick={applyClickHandler}
          sx={{ minWidth: 75 }}
        >
          {applied ? "Applied" : "Apply"}
        </LoadingButton>
      </Box>

      {open && (
        <FilterTemplatesFiltersDialog
          open
          provider={provider}
          onClose={closeDialog}
          filterSet={filterSet}
        />
      )}
    </Stack>
  );
};
