import { FC, useCallback } from "react";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useAppAbility } from "../../../../../../../services/permissions";
import { useAppDispatch } from "../../../../../../../store/hooks";
import { initiateOffHours } from "../../../../../../../store/schedules/schedulesSlice";
import { createOffHoursSchedule } from "../../../../../schedule/off-hours/utils/helpers/helpers";
import { RecommendationDataType } from "../../utils/types/types";
import { getOrganisationCurrentFilterTemplatesThunk } from "../../../../../../../store/live-usage-mgmt/thunks/getOrganisationCurrentFilterTemplatesThunk";

interface OffHoursRecommendationCreateButtonProps {
  data: RecommendationDataType;
}

export const OffHoursRecommendationCreateButton: FC<
  OffHoursRecommendationCreateButtonProps
> = ({ data }) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotCreateSchedule = cannot("create", "schedule");

  const createHandler = useCallback(() => {
    dispatch(getOrganisationCurrentFilterTemplatesThunk());
    dispatch(initiateOffHours(createOffHoursSchedule(data, data.frequency)));
  }, [dispatch, data]);

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      component={RouterLink}
      to="/schedule/off-hours/create"
      disabled={
        canNotCreateSchedule || data?.account.provider_access_type === "read"
      }
      onClick={createHandler}
    >
      Create
    </Button>
  );
};
