import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import accountsReducer from "./accounts/accountsSlice";
import executionLogReducer from "./execution-log/executionLogSlice";
import accountSetupReducer from "./account-setup/accountSetupSlice";
import filtersReducer from "./filters/filtersSlice";
import { rtkQueryErrorLoggerMiddleware } from "./middlewares/rtkQueryErrorLoggerMiddleware";
import { unauthenticatedMiddleware } from "./middlewares/unauthenticatedMiddleware";
import schedulesReducer from "./schedules/schedulesSlice";
import cloudChatSlice from "./cloudchat/cloudChatSlice";
import commitmentsSlice from "./commitments/commitmentsSlice";
import accountSlice from "./account/accountSlice";
import liveUsageMgmtSlice from "./live-usage-mgmt/liveUsageMgmtSlice";
import resourceExplorerSlice from "./resource-explorer/resourceExplorerSlice";
import cleanReducer from "./clean/cleanSlice";
import cleanV2Reducer from "./clean-v2/cleanV2Slice";
import integrationsReducer from "./integrations/integrationsSlice";
import commonReducer from "./common/commonSlice";
import budgetsSlice from "./budgets/budgetsSlice";
import dashboardsSlice from "./dashboards/dashboardsSlice";
import dimensionsSlice from "./dimensions/dimensionsSlice";
import recommendationsSlice from "./recommendations/recommendationsSlice";
import navigationSlice from "./navigation/navigationSlice";
import automationsSlice from "./automations/automationsSlice";
import alertsReducer from "./alerts/alertsSlice";
import { cloudChiprApi } from "../services/cloudchipr.api";
import { cloudChiprApiAuthLess } from "../services/cloudchipr-authless.api";

export const store = configureStore({
  reducer: {
    // keep alphanumeric sorting
    account: accountSlice,
    liveUsageMgmt: liveUsageMgmtSlice,
    accountSetup: accountSetupReducer,
    accounts: accountsReducer,
    alerts: alertsReducer,
    auth: authReducer,
    automations: automationsSlice,
    budgets: budgetsSlice,
    clean: cleanReducer,
    cleanV2: cleanV2Reducer,
    cloudchat: cloudChatSlice,
    commitments: commitmentsSlice,
    common: commonReducer,
    dashboards: dashboardsSlice,
    dimensions: dimensionsSlice,
    executionLog: executionLogReducer,
    filters: filtersReducer,
    integrations: integrationsReducer,
    recommendations: recommendationsSlice,
    resourceExplorer: resourceExplorerSlice,
    schedules: schedulesReducer,
    navigation: navigationSlice,
    [cloudChiprApi.reducerPath]: cloudChiprApi.reducer,
    [cloudChiprApiAuthLess.reducerPath]: cloudChiprApiAuthLess.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(cloudChiprApi.middleware)
      .concat(cloudChiprApiAuthLess.middleware)
      .concat(rtkQueryErrorLoggerMiddleware)
      .concat(unauthenticatedMiddleware);
  },
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
