import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AccountResourceFilter2,
  cloudChiprApi,
  ProviderType,
} from "../../../../services/cloudchipr.api";
import { getOrganisationCurrentFilterTemplatesThunk } from "../../../live-usage-mgmt/thunks/getOrganisationCurrentFilterTemplatesThunk";

type Args = {
  filterTemplateId: string;
  provider: ProviderType;
  name?: string;
  accountIds?: string[];
  filters?: AccountResourceFilter2[];
};

export const patchFilterTemplateThunk = createAsyncThunk(
  "filter/patchFilterTemplateThunk",
  async (
    { filterTemplateId, name, accountIds, filters, provider }: Args,
    { dispatch },
  ) => {
    const { patchUsersMeOrganisationsCurrentFilterSetsByFilterSetIdV2 } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        patchUsersMeOrganisationsCurrentFilterSetsByFilterSetIdV2.initiate({
          filterSetId: filterTemplateId,
          body: {
            name,
            filters,
            cloud_provider: provider,
            account_ids: accountIds,
          },
        }),
      ).unwrap();

      await dispatch(getOrganisationCurrentFilterTemplatesThunk());
    } catch (error) {
      return error;
    }
  },
);
