import { FC } from "react";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";

interface ViewMoveActionDialogContentProps {
  currentItemName: string;
  visibility: NavigationItemsVisibilityType;
}

export const ViewMoveActionDialogContent: FC<
  ViewMoveActionDialogContentProps
> = ({ visibility, currentItemName }) => {
  return (
    <Stack spacing={3}>
      {visibility === "visible_only_to_me" && (
        <Typography>
          Changing the view visibility will deactivate all connected widgets and
          delete any linked budgets. This action cannot be undone.
        </Typography>
      )}

      <Typography variant="inherit">
        Please confirm if you want to move the{" "}
        <Typography variant="inherit" fontWeight="bold" component="span">
          {currentItemName}
        </Typography>{" "}
        view.
      </Typography>
    </Stack>
  );
};
