import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { SwitchWithTypography } from "../../../../../../../../../../SwitchWithTypography";
import { costBreakdownV2SetupAggregationsPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupAggregationsPropertyByKeySelector";
import { costBreakdownV2ViewAggregationsChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2ViewAggregationsChangeThunk";
import { CostBreakdownWidgetSetupType } from "../../../../../../../../../../../store/dashboards/utils/types/setups/costBreakdownSetupType";

export interface CostBreakdownV2WidgetAggregationsSwitchProps {
  selectorKey: keyof CostBreakdownWidgetSetupType["aggregation"];
  title: string;
}

export const CostBreakdownV2WidgetAggregationsSwitch: FC<
  CostBreakdownV2WidgetAggregationsSwitchProps
> = ({ selectorKey, title }) => {
  const dispatch = useAppDispatch();
  const checked = useAppSelector(
    costBreakdownV2SetupAggregationsPropertyByKeySelector(selectorKey),
  );

  const handleCheckedChange = useCallback(
    (checked: boolean) => {
      dispatch(
        costBreakdownV2ViewAggregationsChangeThunk({
          [selectorKey]: checked,
        }),
      );
    },
    [dispatch, selectorKey],
  );

  return (
    <SwitchWithTypography
      checked={checked}
      onChange={handleCheckedChange}
      title={title}
    />
  );
};
