import { costBreakdownV2WidgetDataByWidgetIdSelector } from "./costBreakdownV2WidgetDataByWidgetIdSelector";
import { costBreakdownV2WidgetFrequencySelector } from "./costBreakdownV2WidgetFrequencySelector";
import { RootState } from "../../../../../store";
import { formatResourceExplorerChartData } from "../../../../../resource-explorer/utils/helpers/formatResourceExplorerChartData";
import { convertCostToPercent } from "../../../../../resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/convertCostToPercent";
import {
  FormatedData,
  generateCombinatedChartData,
} from "../../../../../resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/generateCombinatedChartData";

export const costBreakdownV2WidgetCombinedChartDataSelector = (
  state: RootState,
  widgetId: string,
): FormatedData | null => {
  const data = costBreakdownV2WidgetDataByWidgetIdSelector(
    state,
    widgetId,
  )?.data;

  const frequency = costBreakdownV2WidgetFrequencySelector(state, widgetId);

  if (!data || !frequency) {
    return null;
  }

  const formatedData = formatResourceExplorerChartData(data, frequency);
  const percentData = convertCostToPercent(formatedData);

  return generateCombinatedChartData(formatedData, percentData);
};
