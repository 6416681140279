import { FC } from "react";
import { CardHeader, IconButton, Stack } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LiveUsageMgmtResourceCardHeaderTitle } from "./LiveUsageMgmtResourceCardHeaderTitle";
import { useAppSelector } from "../../../../../../store/hooks";
import { ResourceType } from "../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../utils/numeral/money";
import { liveUsageMgmtResourceTypeDataEmsSelector } from "../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataEmsSelector";
import { liveUsageMgmtResourceTypeDataSpendingSelector } from "../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataSpendingSelector";
import { LiveUsageMgmtResourceStatistic } from "../../common/LiveUsageMgmtResourceStatistic";

interface ResourceCardHeaderProps {
  resourceType: ResourceType;
  collapsed: boolean;
  onCollapse(action: any): any;
  onFullScreen(action: any): any;
}

export const LiveUsageMgmtResourceCardHeader: FC<ResourceCardHeaderProps> = ({
  resourceType,
  onCollapse,
  onFullScreen,
  collapsed,
}) => {
  const ems = useAppSelector((state) =>
    liveUsageMgmtResourceTypeDataEmsSelector(state, resourceType),
  );

  const spending = useAppSelector((state) =>
    liveUsageMgmtResourceTypeDataSpendingSelector(state, resourceType),
  );

  return (
    <CardHeader
      title={
        <LiveUsageMgmtResourceCardHeaderTitle resourceType={resourceType} />
      }
      sx={{
        py: 1,
        pr: 2,
        pl: 1,
        bgcolor: "grey.800",
        ".MuiCardHeader-action": {
          minWidth: 40,
          alignSelf: "center",
        },
      }}
      action={
        collapsed ? (
          <Stack direction="row" spacing={1}>
            <LiveUsageMgmtResourceStatistic color="red" value={money(ems)} />
            <LiveUsageMgmtResourceStatistic
              color="blue"
              value={money(spending)}
            />
          </Stack>
        ) : (
          <IconButton
            onClick={onFullScreen}
            size="small"
            disableRipple
            sx={{ color: "grey.50", bgcolor: "#303030" }}
          >
            <FullscreenIcon color="inherit" />
          </IconButton>
        )
      }
      avatar={
        <IconButton
          onClick={onCollapse}
          sx={{
            color: "grey.50",
            transform: collapsed ? "rotate(0deg)" : "rotate(180deg)",
            transition: "transform 0.3s",
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      }
    />
  );
};
