import { FC } from "react";
import { Divider, Drawer } from "@mui/material";
import { ViewSettingsDrawerHeader } from "./ViewSettingsDrawerHeader";
import { ViewTrendSettings } from "./ViewTrendSettings";
import { ViewCostType } from "./view-cost-type/ViewCostType";

interface ViewSettingsDrawerProps {
  open: boolean;
  onClose(): void;
}

export const ViewSettingsDrawer: FC<ViewSettingsDrawerProps> = ({
  open,
  onClose,
}) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={paperProps}
      slotProps={slotProps}
    >
      <ViewSettingsDrawerHeader onClose={onClose} />
      <Divider />
      <ViewTrendSettings />
      <Divider />
      <ViewCostType />
    </Drawer>
  );
};

const paperProps = {
  sx: {
    width: "25dvw",
  },
};

const slotProps = {
  backdrop: {
    invisible: true,
  },
};
