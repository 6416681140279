import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { WidgetTotalsSummary } from "../../../../../widgets/common/WidgetTotalsSummary";
import { costBreakdownV2WidgetSetupCostsSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupCostsSelector";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";
import { costBreakdownV2SetupVisualizationPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupVisualizationPropertyByKeySelector";

export const CostBreakdownV2WidgetSetupContentCosts: FC = () => {
  const dates = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("dates"),
  );
  const costsData = useAppSelector(costBreakdownV2WidgetSetupCostsSelector);
  const showTrends = useAppSelector(
    costBreakdownV2SetupVisualizationPropertyByKeySelector("trend"),
  );

  const frequency = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("frequency"),
  );

  const forecastOption = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("forecastOption"),
  );

  const aggregations = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("aggregation"),
  );

  if (!costsData) {
    return null;
  }

  return (
    <WidgetTotalsSummary
      frequency={frequency}
      costsData={costsData}
      forecastOption={forecastOption}
      aggregations={aggregations}
      dateLabel={dates.label}
      showTrends={showTrends}
    />
  );
};
