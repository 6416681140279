import { Route } from "react-router-dom";
import { DashboardUnauthorizedView } from "../../unauthorized-pages/dashbaord/DashboardUnauthorizedView";

export const UnauthorizedRoutes = () => {
  return (
    <Route path="/api">
      <Route
        path="dashboard/:dashboardId"
        element={<DashboardUnauthorizedView />}
      />
    </Route>
  );
};
