import { FC, Fragment } from "react";
import { CommitmentUtilizationWidgetContentCosts } from "./CommitmentUtilizationWidgetContentCosts";
import { CommitmentUtilizationWidgetNumeralView } from "./CommitmentUtilizationWidgetNumeralView";
import { CommitmentUtilizationWidgetTableView } from "./CommitmentUtilizationWidgetTableView";
import { CommitmentUtilizationWidgetChartView } from "./CommitmentUtilizationWidgetChartView";
import { NoCommitmentsCard } from "./NoCommitmentsCard";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentUtilizationWidgetVisualizationChartTypeSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetVisualizationChartTypeSelector";
import { commitmentUtilizationWidgetByWidgetIdEmptyDataSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetByWidgetIdEmptyDataSelector";
import { commitmentUtilizationWidgetByWidgetIdLoadingSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetByWidgetIdLoadingSelector";
import { commitmentUtilizationChangesWidgetNotFoundSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationChangesWidgetNotFoundSelector";
import { DashboardNotFoundWidget } from "../../common/DashboardNotFoundWidget";
import { commitmentUtilizationWidgetProviderOrganizationIdSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetProviderOrganizationIdSelector";
import { commitmentUtilizationWidgetHasAggregationSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetHasAggregationSelector";

interface CommitmentUtilizationWidgetContentProps {
  widgetId: string;
}

export const CommitmentUtilizationWidgetContent: FC<
  CommitmentUtilizationWidgetContentProps
> = ({ widgetId }) => {
  const widgetNotFound = useAppSelector((state) =>
    commitmentUtilizationChangesWidgetNotFoundSelector(state, widgetId),
  );
  const chartType = useAppSelector((state) =>
    commitmentUtilizationWidgetVisualizationChartTypeSelector(state, widgetId),
  );
  const hasAggregations = useAppSelector((state) =>
    commitmentUtilizationWidgetHasAggregationSelector(state, widgetId),
  );

  const providerOrgId = useAppSelector((state) =>
    commitmentUtilizationWidgetProviderOrganizationIdSelector(state, widgetId),
  );

  const loading = useAppSelector((state) =>
    commitmentUtilizationWidgetByWidgetIdLoadingSelector(state, widgetId),
  );

  const emptyData = useAppSelector((state) =>
    commitmentUtilizationWidgetByWidgetIdEmptyDataSelector(state, widgetId),
  );

  if (widgetNotFound) {
    return (
      <DashboardNotFoundWidget
        name={providerOrgId ?? ""}
        label="organization"
      />
    );
  }

  if (emptyData && !loading) {
    return <NoCommitmentsCard />;
  }

  return (
    <Fragment>
      {hasAggregations && (
        <CommitmentUtilizationWidgetContentCosts widgetId={widgetId} />
      )}

      {chartType === "numeral" && (
        <CommitmentUtilizationWidgetNumeralView widgetId={widgetId} />
      )}

      {chartType === "table" && (
        <CommitmentUtilizationWidgetTableView widgetId={widgetId} />
      )}

      {!["numeral", "table"].includes(chartType ?? "stack") && (
        <CommitmentUtilizationWidgetChartView widgetId={widgetId} />
      )}
    </Fragment>
  );
};
