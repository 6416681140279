import { FC, Fragment, useMemo } from "react";
import { TextField, Tooltip } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { FilterTriggerComponentProps } from "../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import {
  ResourceExplorerGrouping,
  useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery,
} from "../../../../../../services/cloudchipr.api";
import { getResourcesExplorerGroupingLabel } from "../../../../resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { CountChipWithTooltip } from "../CountChipWithTooltip";
import { useAppSelector } from "../../../../../../store/hooks";
import { connectedProvidersSelector } from "../../../../../../store/common/selectors/connectedProvidersSelector";

interface GroupBySelectorTriggerProps extends FilterTriggerComponentProps {
  groupValues?: string[];
  size?: TextFieldProps["size"];
}

export const GroupBySelectorTrigger: FC<GroupBySelectorTriggerProps> = ({
  onClick,
  open,
  disabled,
  selectedValues,
  groupValues,
  size = "small",
}) => {
  const providers = useAppSelector(connectedProvidersSelector);
  const groupBy = selectedValues.at(0) as ResourceExplorerGrouping;

  const { data } = useValuesQuery(
    { filterProvider: "dimensions", filterType: "dimension_id" },
    { skip: groupBy !== "category" },
  );

  const label =
    groupBy === "category"
      ? "Dimension (Categories)"
      : getResourcesExplorerGroupingLabel(groupBy, true, providers);

  const dimensionName = useMemo(() => {
    if (groupBy !== "category") {
      return "";
    }

    const firstValue = groupValues?.at(0);
    const selected = data?.find((option) => option.value === firstValue);

    return selected?.title ?? selected?.value ?? "";
  }, [groupValues, data]);

  return (
    <Tooltip title={open ? "" : dimensionName} arrow>
      <TextField
        size={size}
        label="Group by"
        onClick={onClick}
        disabled={disabled}
        contentEditable={false}
        value={label}
        InputProps={{
          readOnly: true,
          inputProps: {
            style: { cursor: "pointer", caretColor: "transparent" },
          },
          sx: { pr: 1, cursor: "pointer", minWidth: 175 },
          endAdornment: (
            <TriggerEndAdornment
              open={open}
              values={
                groupBy === "cost_allocation_tag" ? groupValues : undefined
              }
            />
          ),
        }}
      />
    </Tooltip>
  );
};

const TriggerEndAdornment: FC<{
  open: boolean;
  values?: string[];
}> = ({ open, values }) => {
  return (
    <Fragment>
      {!!values?.length && <CountChipWithTooltip values={values} />}

      {open ? (
        <ArrowDropUpOutlinedIcon color="action" />
      ) : (
        <ArrowDropDownOutlinedIcon color="action" />
      )}
    </Fragment>
  );
};
