import { FC, useCallback } from "react";
import { ListItemButton, ListItemText } from "@mui/material";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { FilterTriggerComponentProps } from "../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { CountChipWithTooltip } from "../CountChipWithTooltip";

interface NestedGroupingOptionTriggerProps extends FilterTriggerComponentProps {
  needToShowCount?: boolean;
}

export const NestedGroupingOptionTrigger: FC<
  NestedGroupingOptionTriggerProps
> = ({ onClick, label, needToShowCount, selectedValues }) => {
  const clickHandler = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      onClick(event);
    },
    [onClick],
  );

  return (
    <ListItemButton disableRipple onClick={clickHandler}>
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          sx: { whiteSpace: "nowrap" },
          variant: "body2",
        }}
      />

      {needToShowCount && <CountChipWithTooltip values={selectedValues} />}

      <KeyboardArrowRightOutlinedIcon fontSize="inherit" />
    </ListItemButton>
  );
};
