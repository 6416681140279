import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCostByCategoryWidgetDataBySetupThunk } from "../data-fetch/fetchCostByCategoryWidgetDataBySetupThunk";
import { fetchCostByCategoryWidgetDataWithoutFiltersThunk } from "../data-fetch/fetchCostByCategoryWidgetDataWithoutFiltersThunk";
import { RootState } from "../../../../../store";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { ChartType } from "../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { costByCategoryWidgetByIdSelector } from "../../../../selectors/widgets/cost-by-category/costByCategoryWidgetByIdSelector";

export const editCostByCategoryWidgetThunk = createAsyncThunk(
  "dashboards/editCostByCategoryWidget",
  (widgetId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const widget = costByCategoryWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }
    dispatch(
      setWidgetSetup({
        widgetType: "costs_by_category_v2",
        id: widget.id,
        categoryIds: widget.categories.map(({ id }) => id),
        chartType: widget.chart_type as ChartType,
        frequency: widget.date_granularity,
        forecastOption: widget.forecast_option ?? "month",
        name: widget.name,
        displayFilteredResources: widget.display_filtered_resources,
        date: {
          from: widget.date_from,
          to: widget.date_to,
          label: widget.date_label,
        },
        dates: {
          from: widget.date_from,
          to: widget.date_to,
          label: widget.date_label,
        },
        categoryGroup: widget.category_group_key,
      }),
    );

    dispatch(fetchCostByCategoryWidgetDataBySetupThunk());
    dispatch(fetchCostByCategoryWidgetDataWithoutFiltersThunk());
  },
);
