import { FC, Fragment, useCallback, useEffect } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { CostBreakdownV2WidgetSetupContent } from "./content/CostBreakdownV2WidgetSetupContent";
import { CostBreakdownV2WidgetSetupSidebar } from "./side-bar/CostBreakdownV2WidgetSetupSidebar";
import { setWidgetsConfigurationsFeatureToggleFromStorage } from "./utils/helpers/configurations-feature-toggle/setWidgetsConfigurationsFeatureToggleFromStorage";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { WidgetSetupLayout } from "../common/WidgetSetupLayout";
import { WidgetSetupHeader } from "../common/WidgetSetupHeader";
import { scrollPageToBottom } from "../../../../../../utils/helpers/page-scrolling-functions";
import { buildCostBreakdownV2WidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/actions/buildCostBreakdownV2WidgetThunk";
import { costBreakdownV2SubmitButtonDisabledSelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SubmitButtonDisabledSelector";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";
import { costBreakdownV2SetupVisualizationPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupVisualizationPropertyByKeySelector";

interface CostBreakdownSetupProps {
  onClose(): void;
  onSubmit(): void;
}

export const CostBreakdownV2WidgetSetup: FC<CostBreakdownSetupProps> = ({
  onClose,
  onSubmit,
}) => {
  const dispatch = useAppDispatch();
  const enableWidgetsSideBarConfigurations = useFlag(
    "EnableWidgetsSideBarConfigurations",
  );

  const viewId = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("viewId"),
  );

  const viewType = useAppSelector(
    costBreakdownV2SetupVisualizationPropertyByKeySelector("chart_type"),
  );

  const setupId = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("id"),
  );
  const disabled = useAppSelector(costBreakdownV2SubmitButtonDisabledSelector);

  const submitHandler = useCallback(async () => {
    const responseId = await dispatch(
      buildCostBreakdownV2WidgetThunk(),
    ).unwrap();

    onSubmit();
    if (responseId && !setupId) {
      scrollPageToBottom();
    }
  }, [dispatch, setupId, onSubmit]);

  useEffect(() => {
    setWidgetsConfigurationsFeatureToggleFromStorage(
      enableWidgetsSideBarConfigurations,
    );
  }, [enableWidgetsSideBarConfigurations]);

  if (!viewType) {
    return null;
  }

  return (
    <Fragment>
      <WidgetSetupHeader
        disabled={disabled}
        onAdd={submitHandler}
        onCancel={onClose}
      />

      <WidgetSetupLayout
        empty={!viewId ? "Select Resource Explorer View to get started." : ""}
        appBar={<CostBreakdownV2WidgetSetupSidebar />}
      >
        <CostBreakdownV2WidgetSetupContent />
      </WidgetSetupLayout>
    </Fragment>
  );
};
