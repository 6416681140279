import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCostBreakdownV2WidgetSetupDataThunk } from "../getCostBreakdownV2WidgetSetupDataThunk";
import { RootState } from "../../../../../store";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { CostBreakdownWidgetSetupType } from "../../../../utils/types/setups/costBreakdownSetupType";
import { costBreakdownV2WidgetByIdSelector } from "../../../../selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetByIdSelector";

export const editCostBreakdownV2WidgetThunk = createAsyncThunk(
  "dashboards/editCostBreakdownWidgetV2",
  (widgetId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const widget = costBreakdownV2WidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    dispatch(
      setWidgetSetup({
        widgetType: "cost_breakdown",
        id: widget.id,
        //TODO: EnableWidgetsSideBarConfigurations, remove viewType
        viewType: widget.chart_type,
        frequency: widget.date_granularity,
        forecastOption: widget.forecast_option,
        name: widget.name,
        dates: widget.dates,
        grouping: widget.grouping,
        group_values: widget.group_values,
        visualization: widget.visualization,
        aggregation: widget.aggregation,
        viewId: widget.view_id,
        trendType: widget.trend_type,
      } as CostBreakdownWidgetSetupType),
    );
    dispatch(getCostBreakdownV2WidgetSetupDataThunk());
  },
);
