import { FC, Fragment, useCallback } from "react";
import { Button, Divider, Stack } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { SnackbarKey, useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { setFilterChangedManually } from "../../../../../store/filters/filtersSlice";
import { useAppAbility } from "../../../../../services/permissions";
import { liveUsageMgmtAppliedFilterTemplateFiltersSelector } from "../../../../../store/live-usage-mgmt/selectors/filter-template/liveUsageMgmtAppliedFilterTemplateFiltersSelector";
import { setLiveUsageMgmtAppliedFilters } from "../../../../../store/live-usage-mgmt/liveUsageMgmtSlice";
import { getLiveUsageMgmtResourcesWithFiltersByProviderThunk } from "../../../../../store/live-usage-mgmt/thunks/resources/getLiveUsageMgmtResourcesWithFiltersByProviderThunk";
import { saveFilterTemplateThunk } from "../../../../../store/live-usage-mgmt/thunks/filter-template/saveFilterTemplateThunk";
import { saveFilterTemplateLoadingSelector } from "../../../../../store/live-usage-mgmt/selectors/filter-template/saveFilterTemplateLoadingSelector";

interface FilterTemplateViewActionsProps {
  snackBarUi?: boolean;
  closeParentsSnackBar(): void;
  openDialog(): void;
  onSaveAsClick?(): void;
}

export const FilterTemplateViewActions: FC<FilterTemplateViewActionsProps> = ({
  snackBarUi,
  closeParentsSnackBar,
  openDialog,
  onSaveAsClick,
}) => {
  const { can } = useAppAbility();
  const loading = useAppSelector(saveFilterTemplateLoadingSelector);
  const canCreateFilterSet = can("create", "filter-set");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const appliedFilterTemplateFilters = useAppSelector(
    liveUsageMgmtAppliedFilterTemplateFiltersSelector,
  );

  const notifyHandler = useCallback(() => {
    const key: SnackbarKey = enqueueSnackbar("Changes reverted", {
      variant: "snackbarAlert",
      AlertSnackBarProps: {
        icon: false,
        severity: "info",
        onClose: () => closeSnackbar(key),
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
    closeParentsSnackBar();
  }, [enqueueSnackbar, closeSnackbar, closeParentsSnackBar]);

  const revertHandler = useCallback(
    (event: any) => {
      if (!appliedFilterTemplateFilters) {
        return;
      }

      appliedFilterTemplateFilters.forEach((resourceFilters) => {
        dispatch(setLiveUsageMgmtAppliedFilters(resourceFilters));
        dispatch(
          getLiveUsageMgmtResourcesWithFiltersByProviderThunk(
            resourceFilters.type,
          ),
        );
      });

      dispatch(setFilterChangedManually(false));
      notifyHandler();
      event.stopPropagation();
      event.preventDefault();
    },
    [dispatch, notifyHandler, appliedFilterTemplateFilters],
  );

  const saveButtonOpenDialogHandler = useCallback(
    (event: any) => {
      openDialog();
      event.preventDefault();
      event.stopPropagation();
    },
    [openDialog],
  );

  const filterTemplateSaveHandler = useCallback(async () => {
    await dispatch(saveFilterTemplateThunk());
    closeParentsSnackBar();
  }, [dispatch, closeParentsSnackBar]);

  const handleSaveAsNewClick = useCallback(() => {
    onSaveAsClick?.();
    closeParentsSnackBar();
  }, [onSaveAsClick, closeParentsSnackBar]);

  return (
    <Stack
      mt={0.5}
      direction="row"
      justifyContent="space-between"
      height={snackBarUi ? "unset" : 16}
      minWidth={snackBarUi ? 340 : "unset"}
      divider={
        !snackBarUi && <Divider light orientation="vertical" sx={{ mr: 0.5 }} />
      }
    >
      {snackBarUi ? (
        <Fragment>
          <Button size="small" color="tertiary" onClick={revertHandler}>
            Revert
          </Button>

          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              size="small"
              onClick={handleSaveAsNewClick}
              disabled={!canCreateFilterSet}
            >
              Save as New
            </Button>
            <LoadingButton
              variant="contained"
              size="small"
              onClick={filterTemplateSaveHandler}
              disabled={!canCreateFilterSet || loading}
              loading={loading}
            >
              Save
            </LoadingButton>
          </Stack>
        </Fragment>
      ) : (
        <Fragment>
          <Button
            size="xsmall"
            sx={{ textTransform: "none" }}
            onClick={saveButtonOpenDialogHandler}
            disabled={!canCreateFilterSet}
            startIcon={<SaveIcon sx={{ fontSize: 16 }} />}
          >
            Save
          </Button>

          <Button
            size="xsmall"
            color="tertiary"
            onClick={revertHandler}
            sx={{ textTransform: "none" }}
            startIcon={<RotateLeftIcon sx={{ fontSize: 16 }} />}
          >
            Revert
          </Button>
        </Fragment>
      )}
    </Stack>
  );
};
