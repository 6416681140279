import { FC, useCallback } from "react";
import { CostByCategoryCardDisabledAction } from "./CostByCategoryCardDisabledAction";
import { WidgetOptionCard } from "../../WidgetOptionCard";
import Cover from "../../../../utils/assets/images/widget-covers/cost_by_category.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { dashboardsPossibleWidgetStatusByWidgetTypeSelector } from "../../../../../../../store/dashboards/selectors/widget-options/dashboardsPossibleWidgetStatusByWidgetTypeSelector";
import { categoryIdsByCategoryGroupNameSelector } from "../../../../../../../store/categories/selectors/categoryIdsByCategoryGroupNameSelector";
import { fetchCostByCategoryWidgetDataBySetupThunk } from "../../../../../../../store/dashboards/thunks/widgets/cost-by-category/data-fetch/fetchCostByCategoryWidgetDataBySetupThunk";
import { setWidgetSetup } from "../../../../../../../store/dashboards/dashboardsSlice";
import { costByCategoryWidgetDefaultData } from "../../../../../../../store/dashboards/utils/constants/costByCategoryDefaultData";
import { categoryNamesAlphabeticallySortedSelector } from "../../../../../../../store/categories/selectors/categoryNamesAlphabeticallySortedSelector";
import { fetchCostByCategoryWidgetDataWithoutFiltersThunk } from "../../../../../../../store/dashboards/thunks/widgets/cost-by-category/data-fetch/fetchCostByCategoryWidgetDataWithoutFiltersThunk";

export const CostByCategoryCard: FC = () => {
  const dispatch = useAppDispatch();
  const categoryNames = useAppSelector(
    categoryNamesAlphabeticallySortedSelector,
  );

  const groupName = categoryNames.at(0) ?? "";
  const categoryIds = useAppSelector((state) =>
    categoryIdsByCategoryGroupNameSelector(state, groupName),
  );

  const clickHandler = useCallback(() => {
    dispatch(
      setWidgetSetup({
        ...costByCategoryWidgetDefaultData,
        categoryIds,
        categoryGroup: groupName,
      }),
    );

    dispatch(fetchCostByCategoryWidgetDataBySetupThunk());
    dispatch(fetchCostByCategoryWidgetDataWithoutFiltersThunk());
  }, [dispatch, categoryIds, groupName]);

  const status = useAppSelector((state) =>
    dashboardsPossibleWidgetStatusByWidgetTypeSelector(
      state,
      "costs_by_category_v2",
    ),
  );

  return (
    <WidgetOptionCard
      onClick={clickHandler}
      title="Cost by category"
      description="Select different categories within a category group to compare total costs and filtered resources."
      disabledAction={status ? <CostByCategoryCardDisabledAction /> : null}
    >
      <img src={Cover} alt="ConstAndUsageCard" />
    </WidgetOptionCard>
  );
};
