import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDebounce } from "rooks";
import { Dialog, DialogContent, TextField, Typography } from "@mui/material";
import { FilterTemplateSaveAsNewDialogActions } from "./FilterTemplateSaveAsNewDialogActions";
import { FilterExistingTemplates } from "./FilterExistingTemplates";
import { DialogTitleClosable } from "../../../../common/dialog-utils/DialogTitleClosable";
import { useAppSelector } from "../../../../../store/hooks";
import { appliedFilterTemplateIdSelector } from "../../../../../store/filters/selectors/filter-set/applied/appliedFilterTemplateIdSelector";
import { filterTemplatesSelector } from "../../../../../store/filters/selectors/filter-set/filterTemplatesSelector";
import { currentAccountProviderTypeSelector } from "../../../../../store/account/selectors/current-account/currentAccountProviderTypeSelector";

interface FilterTemplateSaveAsNewDialogProps {
  open: boolean;
  onClose(): void;
}

export const FilterTemplateSaveAsNewDialog: FC<
  FilterTemplateSaveAsNewDialogProps
> = ({ onClose, open }) => {
  const appliedFilterSetId = useAppSelector(appliedFilterTemplateIdSelector);
  const currentAccountProviderType = useAppSelector(
    currentAccountProviderTypeSelector,
  );

  const templates = useAppSelector((state) =>
    filterTemplatesSelector(state, currentAccountProviderType ?? "aws"),
  );

  const appliedFilterTemplateId = useMemo(() => {
    const isDefaultApplied =
      templates?.find((filter_set) => filter_set.id === appliedFilterSetId)
        ?.source === "cloudchipr";

    return isDefaultApplied ? "" : (appliedFilterSetId ?? "");
  }, [templates, appliedFilterSetId]);

  const [newFilterTemplateName, setNewFilterTemplateName] = useState("");
  const [selectedFilterTemplateId, setSelectedFilterTemplateId] = useState(
    appliedFilterTemplateId,
  );
  const [saveAsNew, setSaveAsNew] = useState(false);
  const [duplicationError, setDuplicationError] = useState("");

  const validateName = useCallback(
    (newName: string) => {
      const duplicated = templates.some(
        (filter_set) =>
          filter_set.name.trim().toLowerCase() === newName.trim().toLowerCase(),
      );

      if (duplicated) {
        setDuplicationError(
          `Filter template named "${newName}" already exists.`,
        );
      } else {
        setDuplicationError("");
      }
    },
    [templates],
  );

  const saveAsNewHandler = useCallback(() => {
    setSaveAsNew((checked) => {
      if (checked) {
        validateName(newFilterTemplateName);
      } else {
        setDuplicationError("");
      }

      return !checked;
    });
  }, [newFilterTemplateName, validateName]);

  const validateNameDebounced = useDebounce(validateName, 300);

  const selectHandler = useCallback((event: any) => {
    setSelectedFilterTemplateId(event.target.value);
  }, []);

  const filterTemplateNameChangeHandler = useCallback(
    (event: any) => {
      const newName = event.target.value;
      setNewFilterTemplateName(newName);
      validateNameDebounced(newName);
    },
    [validateNameDebounced],
  );

  const closeHandler = useCallback(() => {
    onClose();
    setSaveAsNew(false);
    setDuplicationError("");
    setNewFilterTemplateName("");
    setSelectedFilterTemplateId("");
  }, [onClose]);

  useEffect(() => {
    if (saveAsNew && !selectedFilterTemplateId) {
      setSelectedFilterTemplateId(appliedFilterTemplateId);
    }
  }, [appliedFilterTemplateId, selectedFilterTemplateId, saveAsNew]);

  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      fullWidth
      PaperProps={{ sx: { borderRadius: 3 } }}
    >
      <DialogTitleClosable title="Save Filters" onClose={closeHandler} />

      <DialogContent dividers sx={{ pr: 3 }}>
        <Typography
          variant="body2"
          color={saveAsNew ? "text.disabled" : "text.primary"}
          mb={1}
        >
          New Filter Template Name
        </Typography>

        <TextField
          fullWidth
          size="small"
          placeholder="Name"
          variant="outlined"
          disabled={saveAsNew}
          sx={{ flexGrow: 1 }}
          error={!!duplicationError}
          helperText={duplicationError}
          value={newFilterTemplateName}
          onChange={filterTemplateNameChangeHandler}
        />

        {!!templates.length && (
          <FilterExistingTemplates
            saveAsNew={saveAsNew}
            templates={templates}
            onSaveAsNew={saveAsNewHandler}
            selectHandler={selectHandler}
            selectedFilterTemplateId={selectedFilterTemplateId}
          />
        )}
      </DialogContent>

      <FilterTemplateSaveAsNewDialogActions
        saveAsNew={saveAsNew}
        onClose={closeHandler}
        disabled={!!duplicationError}
        name={newFilterTemplateName}
        filterTemplateId={selectedFilterTemplateId}
      />
    </Dialog>
  );
};
