import { FC } from "react";
import { Skeleton, Stack } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ProviderIcon } from "../../../../../../common/ProviderIcon";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { SIDEBAR_WIDTH } from "../../../../../../../utils/constants/style-sizes";
import { useAppSelector } from "../../../../../../../store/hooks";
import { currentAccountIdSelector } from "../../../../../../../store/account/selectors/current-account/currentAccountIdSelector";
import { currentAccountProviderTypeSelector } from "../../../../../../../store/account/selectors/current-account/currentAccountProviderTypeSelector";
import { FilterTriggerComponentProps } from "../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { currentAccountNameIdSelector } from "../../../../../../../store/account/selectors/current-account/currentAccountNameIdSelector";
import { AccountAccessTypeChip } from "../../../../../../common/chips/account-access-type-chip/AccountAccessTypeChip";
import { currentAccountAccessTypeSelector } from "../../../../../../../store/account/selectors/current-account/currentAccountAccessTypeSelector";
import { currentAccountHasSatisfiedPermissionsSelector } from "../../../../../../../store/account/selectors/current-account/currentAccountHasSatisfiedPermissionsSelector";

export const SelectedAccountNavigationTriggerComponent: FC<
  FilterTriggerComponentProps
> = ({ onClick, open }) => {
  const currentAccountId = useAppSelector(currentAccountIdSelector);
  const currentAccountProvider = useAppSelector(
    currentAccountProviderTypeSelector,
  );
  const currentAccountName = useAppSelector(currentAccountNameIdSelector);

  const accessType = useAppSelector(currentAccountAccessTypeSelector);
  const hasSatisfiedPermissions = useAppSelector(
    currentAccountHasSatisfiedPermissionsSelector,
  );

  if (!currentAccountId || !currentAccountProvider) {
    return <Skeleton variant="text" height={100} width={SIDEBAR_WIDTH - 20} />;
  }

  return (
    <Stack
      py={0.5}
      px={1}
      direction="row"
      borderRadius={2}
      onClick={onClick}
      bgcolor="grey.100"
      alignItems="center"
      sx={{ cursor: "pointer" }}
      justifyContent="space-between"
      spacing={1}
    >
      <ProviderIcon provider={currentAccountProvider} width={24} />

      <TypographyWithTooltip
        title={currentAccountName ?? ""}
        variant="subtitle2"
      />

      <AccountAccessTypeChip
        accessType={accessType}
        warning={!hasSatisfiedPermissions}
      />

      {open ? (
        <ArrowRightIcon sx={{ mr: 1 }} />
      ) : (
        <ArrowDropDownIcon sx={{ mr: 1 }} />
      )}
    </Stack>
  );
};
