import { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { getCostBreakdownV2NumeralItemSecondaryLabel } from "./utils/helpers/getCostBreakdownV2NumeralItemSecondaryLabel";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { costBreakdownV2WidgetTableViewDataSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetTableViewDataSelector";
import { costBreakdownV2WidgetForecastOptionSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetForecastOptionSelector";
import { WidgetNumeralViewItem } from "../../../../../adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralViewItem";
import { WidgetNumeralView } from "../../../../../adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralView";
import { costBreakdownV2WidgetByWidgetIdLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/loading/costBreakdownV2WidgetByWidgetIdLoadingSelector";
import { costBreakdownV2WidgetGroupingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetGroupingSelector";
import { CostBreakdownV2WidgetContentCosts } from "../../CostBreakdownV2WidgetContentCosts";
import { DateLabelNullable } from "../../../../../../../../../services/cloudchipr.api";
import { costBreakdownV2WidgetVisualizationSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetVisualizationSelector";
import { costBreakdownV2WidgetHasAggregationSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetHasAggregationSelector";

interface CostBreakdownWidgetNumeralViewProps {
  widgetId: string;
  dateLabel?: DateLabelNullable;
}

export const CostBreakdownV2WidgetNumeralView: FC<
  CostBreakdownWidgetNumeralViewProps
> = ({ widgetId, dateLabel }) => {
  const data = useAppSelector((state) =>
    costBreakdownV2WidgetTableViewDataSelector(state, widgetId),
  );
  const forecastOption = useAppSelector((state) =>
    costBreakdownV2WidgetForecastOptionSelector(state, widgetId),
  );
  const chartVisualization = useAppSelector((state) =>
    costBreakdownV2WidgetVisualizationSelector(state, widgetId),
  );

  const loading = useAppSelector((state) =>
    costBreakdownV2WidgetByWidgetIdLoadingSelector(state, widgetId),
  );

  const grouping = useAppSelector((state) =>
    costBreakdownV2WidgetGroupingSelector(state, widgetId),
  );

  const hasAggregations = useAppSelector((state) =>
    costBreakdownV2WidgetHasAggregationSelector(state, widgetId),
  );

  if (!forecastOption) {
    return null;
  }

  return (
    <Fragment>
      {hasAggregations && (
        <Box px={2} pt={2}>
          <CostBreakdownV2WidgetContentCosts
            widgetId={widgetId}
            dateLabel={dateLabel}
          />
        </Box>
      )}

      <WidgetNumeralView
        loading={loading}
        skeletonCount={data?.length}
        width={itemWidth}
      >
        {data?.map((item) => {
          const providers = item?.multiProviders ?? [item.cloud_provider];

          const secondaryLabel = getCostBreakdownV2NumeralItemSecondaryLabel({
            monthlyCost: item?.monthly_forecast?.cost,
            quarterlyCost: item?.quarterly_forecast?.cost,
            grouping,
            forecastOption,
          });

          return (
            <WidgetNumeralViewItem
              key={item.cloud_provider + item.field}
              providers={item.cloud_provider ? providers : []}
              label={item.field}
              cost={item.total_cost}
              trend={chartVisualization?.trend ? item.trend : null}
              itemsCount={data.length}
              secondaryLabel={
                chartVisualization?.forecasted_cost ? secondaryLabel : undefined
              }
              width={itemWidth}
            />
          );
        })}
      </WidgetNumeralView>
    </Fragment>
  );
};

const itemWidth = "20%";
