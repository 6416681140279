import { FC, useCallback, useMemo } from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { ResourceProtectionDialogs } from "../protection/ResourceProtectionDialogs";
import { ResourceItemType } from "../../../../../../../../store/live-usage-mgmt/utils/types/types";
import {
  CleanActionTypes,
  getCleanActionLabel,
} from "../../../../../../../../utils/clean-options";
import { useMenuHook } from "../../../../../../../../utils/hooks/useMenu.hook";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";
import { ResourceType } from "../../../../../../../../services/cloudchipr.api";
import { Protection } from "../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/actions-menu-cell-v2/Protection";

interface PreviewDataGridActionComponentProps {
  protectDisabled: boolean;
  resource: ResourceItemType;
  action: CleanActionTypes;
  scheduleId: string;
  batchId?: string;
  onSubmit(): void;
}

export const PreviewDataGridActionComponent: FC<
  PreviewDataGridActionComponentProps
> = ({ protectDisabled, resource, batchId, action, scheduleId, onSubmit }) => {
  const resourceType = resource.resource_type;
  const isProtected = !!resource?.is_protected;
  const protectedBy = resource?.protected_by;

  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const { open: dialogOpen, openDialog, closeDialog } = useDialog();

  const resourceTypeActions = useMemo(() => {
    return {
      [resourceType]: getCleanActionLabel(action, resourceType),
    } as Record<ResourceType, string>;
  }, [action, resourceType]);

  const submitHandler = useCallback(async () => {
    await onSubmit();
    closeDialog();
    closeMenu();
  }, [closeDialog, closeMenu, onSubmit]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={0.3}
      justifyContent="flex-end"
    >
      {isProtected && <Protection protectedBy={protectedBy} />}

      <IconButton size="small" onClick={openMenu} sx={{ p: 0 }}>
        <MoreVertIcon />
      </IconButton>

      <Menu open={open} onClose={closeMenu} anchorEl={anchor}>
        <MenuItem dense onClick={openDialog} disabled={protectDisabled}>
          <ListItemIcon>
            {!isProtected ? (
              <LockOutlinedIcon fontSize="small" />
            ) : (
              <LockOpenIcon fontSize="small" />
            )}
          </ListItemIcon>

          <ListItemText>{isProtected ? "Unprotect" : "Protect"}</ListItemText>
        </MenuItem>
      </Menu>

      <ResourceProtectionDialogs
        batchId={batchId}
        resources={[resource]}
        scheduleId={scheduleId}
        protectDialogProps={
          !isProtected
            ? {
                onClose: closeDialog,
                open: dialogOpen,
                onSubmit: submitHandler,
              }
            : null
        }
        unprotectDialogProps={
          isProtected
            ? {
                resources: [resource],
                open: dialogOpen,
                onSubmit: submitHandler,
                onClose: closeDialog,
                resourceTypeActions: resourceTypeActions,
              }
            : null
        }
      />
    </Stack>
  );
};
