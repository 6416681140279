import { createAsyncThunk } from "@reduxjs/toolkit";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { RootState } from "../../../../../store";
import { ChartType } from "../../../../../../services/cloudchipr.api";
import { getCostBreakdownV2WidgetSetupDataThunk } from "../getCostBreakdownV2WidgetSetupDataThunk";
import { costBreakdownV2WidgetSetupSelector } from "../../../../selectors/setups/cost-breakdown-v2/costBreakdownV2WidgetSetupSelector";
import { costBreakdownWidgetDefaultData } from "../../../../utils/constants/costBreakdownSetupDefaultData";

export const costBreakdownV2ViewTypeChangeThunk = createAsyncThunk(
  "dashboards/costBreakdownViewTypeChange",
  async (viewType: ChartType, { dispatch, getState }) => {
    const state = getState() as RootState;
    const existingSetup = costBreakdownV2WidgetSetupSelector(state);

    if (!existingSetup) {
      return;
    }
    dispatch(
      setWidgetSetup({
        ...existingSetup,
        //TODO: EnableWidgetsSideBarConfigurations, remove viewType
        viewType,
        visualization: {
          ...costBreakdownWidgetDefaultData.visualization,
          //TODO change chart_type and set other settings to default
          chart_type: viewType,
        },
        frequency: viewType === "pie" ? "monthly" : existingSetup.frequency,
      }),
    );
    dispatch(getCostBreakdownV2WidgetSetupDataThunk());
  },
);
