import { FC } from "react";
import { Stack } from "@mui/material";
import { CostByCategoryChartTypeSelect } from "./app-bar/CostByCategoryChartTypeSelect";
import { CostByCategoryFilteredResourcesSwitcher } from "./app-bar/CostByCategoryFilteredResourcesSwitcher";
import VisualizationIcon from "../../../../../../../../../assets/images/icons/visualization_icon.svg";
import { WidgetConfigSection } from "../../common/widget-configurations/WidgetConfigSection";

export const CostByCategorySetupVisualizationSection: FC = () => {
  return (
    <WidgetConfigSection
      initialExpanded
      title="Visualization"
      icon={<img src={VisualizationIcon} alt="Visualization Icon" />}
    >
      <Stack spacing={1.5}>
        <CostByCategoryChartTypeSelect />
        <CostByCategoryFilteredResourcesSwitcher />
      </Stack>
    </WidgetConfigSection>
  );
};
