import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { applyFilterTemplateToCurrentAccount } from "./applyFilterSetToCurrentAccount";
import { RootState } from "../../../store";
import { currentAccountProviderTypeSelector } from "../../../account/selectors/current-account/currentAccountProviderTypeSelector";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { getOrganisationCurrentFilterTemplatesThunk } from "../../../live-usage-mgmt/thunks/getOrganisationCurrentFilterTemplatesThunk";

export const createFilterTemplateFromAppliedFiltersThunk = createAsyncThunk(
  "filter/createFilterTemplateFromAppliedFiltersThunk",
  async (name: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const {
      filters: { appliedFilters },
      account: { id },
    } = state;

    const provider = currentAccountProviderTypeSelector(state);

    const { postUsersMeOrganisationsCurrentFilterSetsV2 } =
      cloudChiprApi.endpoints;

    if (!id || !provider) {
      return;
    }

    const filters = Object.values(appliedFilters[id]);

    if (!filters) {
      return;
    }

    try {
      const response = await dispatch(
        postUsersMeOrganisationsCurrentFilterSetsV2.initiate({
          body: {
            name,
            filters,
            cloud_provider: provider,
            priority_account_id: id,
          },
        }),
      ).unwrap();

      if (response?.id) {
        await dispatch(applyFilterTemplateToCurrentAccount(response.id));
        await dispatch(getOrganisationCurrentFilterTemplatesThunk());
      }

      return response;
    } catch (e) {
      // @ts-expect-error //todo: remove this when backend will add types
      enqueueSnackbar(e?.data?.message ?? "Something went wrong", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
