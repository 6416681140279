import { FC, useMemo } from "react";
import {
  Box,
  Stack,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { LargestCostChangesWidgetSetupResourceCostItem } from "./LargestCostChangesWidgetSetupResourceCostItem";
import { ProviderIcon } from "../../../../../../../../../common/ProviderIcon";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { TrendChip } from "../../../../../../../../../common/chips/TrendChip";
import {
  Dates,
  ProviderType,
  ResourceExplorerCostsDateDetails,
} from "../../../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";
import { getNumeralViewItemsPaddings } from "../../../common/view-type/utils/helpers/getNumeralViewItemsPaddings";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { generateDatesCompareData } from "../../../../../../../../../common/date-range-picker-v2/utils/helpers/date-formatter/generateDatesCompareData";

interface LargestCostChangesWidgetSetupResourceItemProps {
  provider: ProviderType;
  label: string;
  totalCost: number;
  previousPeriodTotalCost: number | null;
  trend: number | null;
  difference: number | null;
  itemsCount: number;
  previousPeriodDate?: ResourceExplorerCostsDateDetails;
  date?: Dates;
}
export const LargestCostChangesWidgetSetupResourceItem: FC<
  LargestCostChangesWidgetSetupResourceItemProps
> = ({
  provider,
  label,
  totalCost,
  previousPeriodTotalCost,
  trend,
  difference,
  itemsCount,
  previousPeriodDate,
  date,
}) => {
  const isBig = itemsCount < 5;
  const style = styles.get(isBig ? "big" : "small");

  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const dateInfo = useMemo(() => {
    return generateDatesCompareData({
      date,
      quarterStartMonth,
      prevDate: previousPeriodDate,
    });
  }, [quarterStartMonth, previousPeriodDate, date]);

  const startDateText = getDateText(
    dateInfo.startDateLabel,
    dateInfo.startDateRange,
  );
  const endDateText = getDateText(dateInfo.endDateLabel, dateInfo.endDateRange);
  const trendMainText = `change in cost comparing ${startDateText.toLowerCase()} to ${endDateText.toLowerCase()}`;

  return (
    <Box
      p={getNumeralViewItemsPaddings(itemsCount)}
      width={itemsCount > 6 ? "50%" : "100%"}
      flexGrow="1"
      boxSizing="border-box"
    >
      <Stack
        spacing={1}
        bgcolor="grey.50"
        borderRadius={1}
        alignItems="center"
        justifyContent="space-between"
        height="100%"
        direction="row"
        px={style?.padding}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          flex="2 1 0"
          overflow="hidden"
        >
          <ProviderIcon provider={provider} />
          <TypographyWithTooltip title={label} {...style?.resourceName} />
        </Stack>

        <LargestCostChangesWidgetSetupResourceCostItem
          cost={totalCost}
          title="Total Cost"
          totalCostTypographyProps={style?.totalCost}
          priceCostTypographyProps={style?.price}
          tooltipTitle={startDateText}
        />

        <LargestCostChangesWidgetSetupResourceCostItem
          cost={previousPeriodTotalCost}
          title="Preceding Cost"
          totalCostTypographyProps={style?.totalCost}
          priceCostTypographyProps={style?.price}
          tooltipTitle={endDateText}
        />

        <Stack spacing={1} alignItems="flex-end" flex="1 1 0">
          <Tooltip
            arrow
            placement="top"
            title={`Dollar amount ${trendMainText}`}
          >
            <Stack
              spacing={isBig ? 0.2 : 0.1}
              direction="row"
              alignItems="center"
            >
              {!!trend &&
                (trend > 0 ? (
                  <Typography
                    color={red[700]}
                    fontSize={isBig ? 24 : 14}
                    fontWeight="medium"
                  >
                    +
                  </Typography>
                ) : (
                  <Typography
                    color={green[700]}
                    fontSize={isBig ? 24 : 14}
                    fontWeight="medium"
                  >
                    -
                  </Typography>
                ))}

              {difference !== null && (
                <Typography fontWeight="medium" {...style?.price}>
                  {money(difference)}
                </Typography>
              )}
            </Stack>
          </Tooltip>

          <TrendChip
            value={trend}
            tooltipTitle={`Percentage ${trendMainText}`}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

const getDateText = (label?: string | null, range?: string) =>
  range ? `${label} (${range})` : `${label}`;

const styles = new Map<
  "big" | "small",
  {
    resourceName: TypographyProps;
    price: TypographyProps;
    totalCost: TypographyProps;
    padding: number;
  }
>([
  [
    "big",
    {
      resourceName: { variant: "h6", fontWeight: 400 },
      totalCost: { variant: "body1" },
      price: { variant: "h5" },
      padding: 3,
    },
  ],
  [
    "small",
    {
      resourceName: { variant: "body1" },
      totalCost: { variant: "caption" },
      price: { variant: "body2" },
      padding: 1,
    },
  ],
]);
