import { liveUsageMgmtResourceTypeDataSelector } from "./liveUsageMgmtResourceTypeDataSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const liveUsageMgmtFilteredResourcesCountSelector = (
  state: RootState,
  resourceType: ResourceType,
): number | undefined => {
  const data = liveUsageMgmtResourceTypeDataSelector(state, resourceType);

  return data?.resources?.length;
};
