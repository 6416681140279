import { FC, Fragment, useCallback } from "react";
import {
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Typography,
} from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { integrationOptions } from "../../utils/constants/constants";
import { IntegrationLogo } from "../../../../../../../../../../common/integration-dialogs/components/common/IntegrationLogo";
import { IntegrationType } from "../../../../../../../../../integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";
import { initiateCurrentIntegration } from "../../../../../../../../../../../store/integrations/integrationsSlice";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeSelector";

interface ToolbarIntegrationsActionItemsProps {
  resourceType: ResourceType;
}

export const ToolbarIntegrationsActionItems: FC<
  ToolbarIntegrationsActionItemsProps
> = ({ resourceType }) => {
  const dispatch = useAppDispatch();
  const enableSlackFromAccountPage = useFlag("EnableSlackFromAccountPage");
  const enableEmailFromAccountPage = useFlag("EnableEmailFromAccountPage");

  const clickHandler = useCallback(
    (type: IntegrationType) => () => {
      dispatch(initiateCurrentIntegration({ type, resourceType }));
    },
    [dispatch, resourceType],
  );

  const selectedResources = useAppSelector((state) =>
    liveUsageMgmtSelectedResourcesByResourceTypeSelector(state, resourceType),
  );

  return (
    <Fragment>
      <ListSubheader sx={{ lineHeight: 2 }}>
        <Typography fontSize="smaller">INTEGRATIONS</Typography>
      </ListSubheader>

      {integrationOptions.map(({ type, label }) => {
        return (
          <MenuItem
            dense
            key={type}
            onClick={clickHandler(type)}
            disabled={
              !selectedResources?.length ||
              (type === "slack" && !enableSlackFromAccountPage) ||
              (type === "email" && !enableEmailFromAccountPage)
            }
          >
            <ListItemIcon>
              <IntegrationLogo type={type} width={20} />
            </ListItemIcon>

            <ListItemText>{label}</ListItemText>
          </MenuItem>
        );
      })}
    </Fragment>
  );
};
