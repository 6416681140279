import { FC, Fragment } from "react";
import { Card, CardContent } from "@mui/material";
import { useDidMount, useEffectOnceWhen, useWillUnmount } from "rooks";
import { ExecutionLogsHeader } from "./components/header/ExecutionLogsHeader";
import { ExecutionLogsDataGrid } from "./components/data-grid/ExecutionLogsDataGrid";
import { ExecutionLogsToolbar } from "./components/ExecutionLogsToolbar";
import { useExecutionLogPathIds } from "./utils/hooks/useExecutionLogPathIds.hook";
import { resetExecutionLogs } from "../../../store/execution-log/executionLogSlice";
import { useAppDispatch } from "../../../store/hooks";
import { getExecutionLogThunk } from "../../../store/execution-log/thunks/getExecutionLogThunk";
import { getWorkflowsThunk } from "../../../store/automations/thunks/wokrflows/getWorkflowsThunk";
import { getExecutionLogByIdsThunk } from "../../../store/execution-log/thunks/getExecutionLogByIdsThunk";

export const ExecutionLogs: FC = () => {
  const executionLogIds = useExecutionLogPathIds();
  const dispatch = useAppDispatch();

  useEffectOnceWhen(() => {
    dispatch(getExecutionLogByIdsThunk(executionLogIds));
  }, !!executionLogIds?.length);

  useEffectOnceWhen(() => {
    dispatch(getExecutionLogThunk());
  }, !executionLogIds?.length);

  useWillUnmount(() => {
    dispatch(resetExecutionLogs());
  });

  useDidMount(() => {
    dispatch(getWorkflowsThunk());
  });

  return (
    <Fragment>
      <ExecutionLogsHeader />

      <Card variant="outlined" sx={{ m: 2 }}>
        <CardContent sx={{ p: 2 }}>
          <ExecutionLogsToolbar />

          <ExecutionLogsDataGrid executionLogIds={executionLogIds} />
        </CardContent>
      </Card>
    </Fragment>
  );
};
