import { FC } from "react";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { getNumeralViewItemsPaddings } from "./utils/helpers/getNumeralViewItemsPaddings";
import { ProviderIcon } from "../../../../../../../../common/ProviderIcon";
import { ProviderType } from "../../../../../../../../../services/cloudchipr.api";
import { TrendChip } from "../../../../../../../../common/chips/TrendChip";
import { money } from "../../../../../../../../../utils/numeral/money";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";

interface WidgetNumeralViewItemProps {
  label: string;
  costTooltipTitle?: string;
  cost?: number | null;
  trend: number | null;
  itemsCount: number;
  providers?: ProviderType[];
  tooltipTitle?: string;
  CustomLabel?: FC<{ fontSize: number; label: string }>;
  secondaryLabel?: string;
  secondLabelTooltipTitle?: string;
  postfix?: string;
  percent?: number;
  approximate?: boolean;
  width?: string;
}

export const WidgetNumeralViewItem: FC<WidgetNumeralViewItemProps> = ({
  label,
  providers,
  costTooltipTitle,
  cost,
  secondLabelTooltipTitle,
  trend,
  itemsCount,
  tooltipTitle,
  CustomLabel,
  secondaryLabel,
  postfix,
  approximate,
  percent,
  width = "50%",
}) => {
  const bigFontSize = Math.max(14, 36 - itemsCount * 2);
  const smallFontSize = Math.max(14, 26 - itemsCount * 2);

  return (
    <Box
      p={getNumeralViewItemsPaddings(itemsCount)}
      width={width}
      flexGrow="1"
      boxSizing="border-box"
      justifyContent="center"
    >
      <Stack
        spacing={1}
        bgcolor="grey.50"
        borderRadius={1}
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          width="100%"
          spacing={0.5}
          px={1}
        >
          {providers?.map((item) => {
            return <ProviderIcon key={item} provider={item} width={16} />;
          })}
          {CustomLabel ? (
            <CustomLabel fontSize={smallFontSize} label={label} />
          ) : (
            <TypographyWithTooltip
              variant="caption"
              color="text.secondary"
              whiteSpace="nowrap"
              fontSize={smallFontSize}
              title={label}
            />
          )}
        </Stack>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          flexWrap="wrap"
        >
          <Stack
            direction="row"
            spacing={0.25}
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >
            <Tooltip arrow placement="top" title={costTooltipTitle}>
              <Typography
                variant="body1"
                fontWeight={500}
                fontSize={bigFontSize}
              >
                {approximate && "~"}
                {money(cost)}
              </Typography>
            </Tooltip>
            {postfix && (
              <Typography variant="caption" color="text.secondary">
                {postfix}
              </Typography>
            )}

            {percent !== undefined && (
              <Typography
                variant="body1"
                fontWeight={500}
                fontSize={bigFontSize}
              >
                ({percent}%)
              </Typography>
            )}
          </Stack>

          <TrendChip value={trend} tooltipTitle={tooltipTitle} />
        </Stack>

        {secondaryLabel && (
          <Tooltip arrow placement="top" title={secondLabelTooltipTitle}>
            <span>
              <TypographyWithTooltip
                title={secondaryLabel}
                variant="body2"
                color="text.secondary"
                fontSize={smallFontSize}
              />
            </span>
          </Tooltip>
        )}
      </Stack>
    </Box>
  );
};
