import { RootState } from "../../../../store";
import { filterTemplatesSelector } from "../filterTemplatesSelector";
import { ProviderType } from "../../../../../services/cloudchipr.api";

export const defaultFilterTemplateIdSelector = (
  state: RootState,
  provider?: ProviderType,
) => {
  if (!provider) {
    return;
  }

  const data = filterTemplatesSelector(state, provider);

  const defaultFilterSet = data?.find((filter_set) => filter_set.is_default);

  return defaultFilterSet?.id;
};
