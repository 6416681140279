import {
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerGrouping,
} from "../../../../../../../services/cloudchipr.api";

export const resourceExplorerGroupings: ResourceExplorerGrouping[] = [
  "none",
  "account",
  "service",
  "region",
  "resource",
  "product_family",
  "cloud_provider",
  "cost_allocation_tag",
  "cost_allocation_tag_value",
  "charge_type",
  "instance_family",
  "instance_type",
  "environment",
  "marketplace_service",
];

export const unselectableRowGroupings = new Set(["charge_type", "credit_type"]);

export const resourcesExplorerGroupingLabels: Map<
  ResourceExplorerGrouping,
  string
> = new Map([
  ["none", "None"],
  ["category", "Categories"],
  ["account", "Accounts"],
  ["cloud_provider", "Cloud Providers"],
  ["service", "Services"],
  ["region", "Regions"],
  ["product_family", "Product Families"],
  ["charge_type", "Charge Types (Discounts)"],
  ["resource", "Resources"],
  ["instance_family", "Instance Families"],
  ["instance_type", "Instance Types"],
  ["environment", "Environments"],
  ["marketplace_service", "Marketplace Services"],
]);
export const resourcesExplorerGroupingSingularLabels: Map<
  ResourceExplorerGrouping,
  string
> = new Map([
  ["account", "Account"],
  ["category", "Category"],
  ["cloud_provider", "Cloud Provider"],
  ["service", "Service"],
  ["region", "Region"],
  ["product_family", "Product Family"],
  ["charge_type", "Charge Type / Discount"],
  ["resource", "Resource"],
  ["instance_family", "Instance Family"],
  ["instance_type", "Instance Type"],
  ["none", "None"],
  ["environment", "Environment"],
  ["marketplace_service", "Marketplace Service"],
]);

export const possibleGroupingsByCurrentGrouping: Record<
  string,
  ResourceExplorerGrouping[]
> = {
  region: ["region"],
  cloud_provider: ["cloud_provider"],
  account: resourceExplorerGroupings,
  environment: ["environment"],
  marketplace_service: ["marketplace_service"],
  service: ["service"],
  resource: ["product_family", "resource", "account", "region", "service"],
  product_family: ["product_family", "resource", "account", "region"],
  none: ["none"],
  category: ["category"],
};

export const nextGroupingByCurrentGrouping: Record<
  string,
  ResourceExplorerGrouping
> = {
  cloud_provider: "account",
  region: "resource",
  account: "service",
  environment: "service",
  service: "resource",
  resource: "product_family",
  product_family: "resource",
  instance_family: "instance_type",
  instance_type: "resource",
  cost_allocation_tag: "cost_allocation_tag_value",
  cost_allocation_tag_value: "resource",
  none: "cloud_provider",
  category: "account",
  marketplace_service: "resource",
};

export const filterTreeKeyByCurrentGrouping: Record<
  string,
  {
    type: "array" | "object";
    key: ResourceExplorerDynamicFilterItemType;
  }
> = {
  region: { type: "array", key: "region" },
  account: { type: "array", key: "account" },
  service: { type: "array", key: "service" },
  environment: { type: "array", key: "environment" },
  resource: { type: "array", key: "resource_id" },
  product_family: { type: "array", key: "product_family" },
  cloud_provider: { type: "array", key: "cloud_provider" },
  marketplace_service: { type: "array", key: "marketplace" },
  instance_family: { type: "array", key: "instance_family" },
  instance_type: { type: "array", key: "instance_type" },
  cost_allocation_tag_value: { type: "object", key: "tag" },
  cost_allocation_tag: { type: "object", key: "tag" },
  category: { type: "object", key: "dimension_id" },
};
