import { FC, Fragment, useMemo } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { getAccountsTableColumns } from "./accountsTableColumns";
import { AccountCardRouterWrapper } from "../account-card/AccountCardRouterWrapper";
import { DataGrid } from "../../../../../../storybook/data-grid/DataGrid";
import {
  Account,
  ProviderType,
} from "../../../../../../services/cloudchipr.api";
import { AccountsBarView } from "../accounts-bar/AccountsBarView";
import { AccountTabs } from "../../../utils/types/types";
import { generateAccountIdsQueryParams } from "../../../../live-usage-mgmt/utils/helpers/generateAccountIdsQueryParams";

interface AccountsDataGridProps {
  withBar?: boolean;
  accounts: Account[] | null;
  onSortingChange: (data: Account[], drop?: boolean) => void;
  accountsType: AccountTabs;
  provider: ProviderType;
}

export const AccountsListView: FC<AccountsDataGridProps> = ({
  withBar,
  accounts,
  onSortingChange,
  accountsType,
  provider,
}) => {
  const enableFilterTemplatesForAccountsList = useFlag(
    "EnableFilterTemplatesForAccountsList",
  );
  const enableLiveUsageMultiAccountSelect = useFlag(
    "EnableLiveUsageMultiAccountSelect",
  );

  const columns = useMemo(() => {
    return getAccountsTableColumns({
      includeAccountCardFilterTemplateSection:
        enableFilterTemplatesForAccountsList && accountsType !== "inactive",
    }).map((column) => {
      if (!column.cell) {
        return column;
      }

      const col = { ...column };

      col.cell = (props) => {
        let navigateTo = "";
        if (enableLiveUsageMultiAccountSelect) {
          navigateTo = `/${provider}/live-usage/${generateAccountIdsQueryParams([props.row.original.id])}`;
        } else {
          navigateTo = `/${props.row.original.type}/${props.row.original.id}`;
        }
        const status = props.row.original.status;

        return (
          <AccountCardRouterWrapper status={status} navigateTo={navigateTo}>
            <Fragment>
              {typeof column.cell === "function"
                ? column.cell(props)
                : column.cell}
            </Fragment>
          </AccountCardRouterWrapper>
        );
      };

      return col;
    });
  }, [
    enableFilterTemplatesForAccountsList,
    accountsType,
    provider,
    enableLiveUsageMultiAccountSelect,
  ]);

  if (!accounts) {
    return null;
  }

  if (withBar) {
    return <AccountsBarView columns={columns} accounts={accounts} />;
  }

  return (
    <DataGrid
      enableRowDnD
      styles={styles}
      data={accounts}
      columns={columns}
      onRowsDndChange={onSortingChange}
    />
  );
};

const styles = {
  tableHeaderRow: { borderTop: 1, borderColor: "grey.300" },
  tableContainer: {
    borderRight: 1,
    borderLeft: 1,
    borderColor: "grey.300",
  },
};
