import { costByCategoryWidgetByIdSelector } from "../costByCategoryWidgetByIdSelector";
import { RootState } from "../../../../../store";
import { costByCategorySetupSelector } from "../../../setups/cost-by-category/costByCategorySetupSelector";
import { sortAlphabeticallyCallback } from "../../../../../../utils/helpers/sorting";

export const costByCategoryWidgetEditChangesExistSelector = (
  state: RootState,
) => {
  const setup = costByCategorySetupSelector(state);

  if (!setup?.id) {
    return false;
  }

  const widget = costByCategoryWidgetByIdSelector(state, setup?.id);

  if (!widget) {
    return false;
  }

  const somethingChanged =
    setup.name !== widget.name ||
    setup.categoryGroup !== widget.category_group_key ||
    setup.date.from !== widget.date_from ||
    setup.date.to !== widget.date_to ||
    setup.date.label !== widget.date_label ||
    setup.frequency !== widget.date_granularity ||
    setup.displayFilteredResources !== widget.display_filtered_resources ||
    setup.chartType !== widget.chart_type ||
    setup.forecastOption !== widget.forecast_option ||
    setup.categoryIds?.length !== widget.categories?.length;

  if (somethingChanged) {
    return true;
  }

  const setupIds = [...(setup.categoryIds ?? [])];
  const widgetIds = widget.categories?.map(({ id }) => id) ?? [];

  return (
    setupIds.toSorted(sortAlphabeticallyCallback).join(",") !==
    widgetIds?.toSorted(sortAlphabeticallyCallback)?.join(",")
  );
};
