import { previewSelectedResourceIdsSelector } from "./previewSelectedResourceIdsSelector";
import { ResourceType } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";
import { scheduleResourcesDataSelector } from "../scheduleResourcesDataSelector";
import { ResourceItemType } from "../../../../../account/utils/types/types";

export const previewSelectedResourcesSelector = (
  state: RootState,
  resourceTypes: ResourceType[],
  accountIds: string[],
  scheduleId: string,
): ResourceItemType[] => {
  const ids = previewSelectedResourceIdsSelector(state);
  const selectedResourceIds = resourceTypes
    .map((rt) => Object.keys(ids[rt] ?? {}))
    .flat();

  const allResources = resourceTypes
    .map((rt) =>
      scheduleResourcesDataSelector(state, accountIds, rt, scheduleId),
    )
    .flat();

  return allResources?.reduce((acc, resource) => {
    if (!resource?.resource) {
      return acc;
    }

    const resourceId = resource?.resource?.id;

    if (!selectedResourceIds?.includes(resourceId)) {
      return acc;
    }

    acc.push(resource.resource);

    return acc;
  }, [] as ResourceItemType[]);
};
