import { FC, useCallback, useState } from "react";
import { Drawer, Stack } from "@mui/material";
import { CostAllocationTagsDrawerBody } from "./CostAllocationTagsDrawerBody";
import { CostAllocationTagsDrawerHeader } from "./CostAllocationTagsDrawerHeader";
import { ProviderType } from "../../../../../../services/cloudchipr.api";

interface CostAllocationTagsDrawerProps {
  open: boolean;
  provider: ProviderType;
  onClose(): void;
}

export const CostAllocationTagsDrawer: FC<CostAllocationTagsDrawerProps> = ({
  open,
  onClose,
  provider,
}) => {
  // thunk: enableCostAllocationTagKeyThunk
  const [search, setSearch] = useState("");

  const closeHandler = useCallback(() => {
    setSearch("");
    onClose();
  }, [onClose]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={closeHandler}
      PaperProps={{ sx: { width: 480 } }}
    >
      <Stack height="100vh">
        <CostAllocationTagsDrawerHeader
          onClose={closeHandler}
          provider={provider}
          setSearchValue={setSearch}
        />

        <CostAllocationTagsDrawerBody
          provider={provider}
          searchValue={search}
        />
      </Stack>
    </Drawer>
  );
};
