import { liveUsageMgmtLiveFilteredResourcesSelector } from "./liveUsageMgmtLiveFilteredResourcesSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const liveUsageMgmtFilteredResourcesChildResourcesCountSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const data = liveUsageMgmtLiveFilteredResourcesSelector(state, resourceType);
  return data?.reduce(
    (total, resource) =>
      total + (resource?.child_resource?.resources?.length ?? 0),
    0,
  );
};
