import { calculateFromToByDatesData } from "../../../../../common/date-range-picker-v2/utils/helpers/calculateFromToByDatesData";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { Dates } from "../../../../../../services/cloudchipr.api";

export const generateFromToFromDates = (
  dates: Dates,
  quarterStartMonth?: number,
) => {
  const { toDate, fromDate } = calculateFromToByDatesData(
    dates,
    quarterStartMonth,
  );

  const from = formatDate((fromDate || dates.from) ?? "", {
    type: "dateWithComma",
  });
  const to = formatDate((toDate || dates.to) ?? "", {
    type: "dateWithComma",
  });

  return from && to ? `from ${from} to ${to}` : "";
};
