import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { costBreakdownV2SetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupVisualizationPropertyByKeySelector";
import { costBreakdownV2ViewVisualizationChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2ViewVisualizationChangeThunk";
import { ChartBaseType } from "../../../../../../../../../../../services/cloudchipr.api";
import { WidgetSetupChartBaseSelect } from "../../../../common/toolbar/WidgetSetupChartBaseSelect";

export const CostBreakdownV2WidgetVisualizationChartBase: FC = () => {
  const dispatch = useAppDispatch();
  const base = useAppSelector(
    costBreakdownV2SetupVisualizationPropertyByKeySelector("chart_base_type"),
  );

  const handleChartBaseChange = useCallback(
    (chartBase: ChartBaseType) => {
      dispatch(
        costBreakdownV2ViewVisualizationChangeThunk({
          chart_base_type: chartBase,
        }),
      );
    },
    [dispatch],
  );

  return (
    <WidgetSetupChartBaseSelect
      chartBase={base}
      onChartChange={handleChartBaseChange}
    />
  );
};
