import { FC, useCallback, useMemo } from "react";
import { ResourceExplorerV2ViewMoveActionMenuFolderItem } from "./ResourceExplorerV2ViewMoveActionMenuFolderItem";
import { useAppSelector } from "../../../../../../../store/hooks";
import { resourceExplorerViewsHierarchyFoldersSelector } from "../../../../../../../store/resource-explorer/selectors/views-hierarchy/resourceExplorerViewsHierarchyFoldersSelector";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";
import { HierarchyItemMoveActionSection } from "../../../../common/hierarchy-item-move-action/HierarchyItemMoveActionSection";

interface ResourceExplorerV2ViewMoveActionMenuSectionProps {
  hideTitle?: boolean;
  folderId?: string;
  visibility: NavigationItemsVisibilityType;
  currentViewName: string;
  currentVisibility: NavigationItemsVisibilityType;
  moveToFolder(
    visibility: NavigationItemsVisibilityType,
    folderId?: string,
  ): void;
  moveToRoot(visibility: NavigationItemsVisibilityType): void;
}

export const ResourceExplorerV2ViewMoveActionMenuSection: FC<
  ResourceExplorerV2ViewMoveActionMenuSectionProps
> = ({
  folderId,
  visibility,
  currentViewName,
  currentVisibility,
  moveToFolder,
  moveToRoot,
  hideTitle,
}) => {
  const folders = useAppSelector((state) =>
    resourceExplorerViewsHierarchyFoldersSelector(state, visibility),
  );

  const filteredFolders = useMemo(() => {
    return visibility === currentVisibility && folderId
      ? folders?.filter((item) => item.id !== folderId)
      : folders;
  }, [folders, currentVisibility, visibility, folderId]);

  const moveHandler = useCallback(
    (folderId?: string) => {
      if (folderId) {
        moveToFolder(visibility, folderId);
      } else {
        moveToRoot(visibility);
      }
    },
    [moveToRoot, moveToFolder, visibility],
  );

  return (
    <HierarchyItemMoveActionSection
      onMove={moveHandler}
      hideTitle={hideTitle}
      visibility={visibility}
      folders={filteredFolders}
      inFolder={!!folderId}
      currentItemName={currentViewName}
      differentVisibilities={visibility !== currentVisibility}
      HierarchyItemMoveActionItem={
        ResourceExplorerV2ViewMoveActionMenuFolderItem
      }
    />
  );
};
