import { costByCategoryWidgetDataSelector } from "./data/costByCategoryWidgetDataSelector";
import { costByCategoryWidgetFrequencySelector } from "./costByCategoryWidgetFrequencySelector";
import { RootState } from "../../../../store";
import { buildingInProgressWidgetId } from "../../../thunks/widgets/utils/constants";
import { WidgetCosts } from "../../../utils/types/types";
import { getAverageDataByFrequency } from "../../../utils/helpers/costBreakdown/getAverageDataByFrequency";

export const costByCategoryWidgetCostsSelector = (
  state: RootState,
  widgetId?: string,
): WidgetCosts => {
  const widgetData = costByCategoryWidgetDataSelector(
    state,
    widgetId ?? buildingInProgressWidgetId,
  );
  const frequency = costByCategoryWidgetFrequencySelector(state, widgetId);

  const totalCost = +(widgetData?.total_cost_details.total_cost ?? 0).toFixed(
    2,
  );

  const average = getAverageDataByFrequency(frequency, widgetData);

  return {
    total: {
      cost: totalCost,
      costType: widgetData?.total_cost_details?.cost_type,
      trend: widgetData?.total_cost_details?.trend ?? null,
    },

    average,

    liveUsageTotal: {
      cost: +(widgetData?.live_filtered ?? 0)?.toFixed(2),
      tooltip:
        "Total monthly price of live filtered resources found in Live Usage & Mgmt for current categories.",
    },

    forecasted_costs: widgetData?.forecasted_costs,
  };
};
