import { FC, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useFlag, useUnleashContext } from "@unleash/proxy-client-react";
import * as Sentry from "@sentry/react";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import { ViewAccessCheck } from "./routes/ViewAccessCheck";
import { ExecutionLogs } from "../pages/execution-log/ExecutionLogs";
import { OffHoursV1 } from "../pages/schedule/off-hours/OffHoursV1";
import { OffHoursItem } from "../pages/schedule/off-hours/OffHoursItem";
import { Integrations } from "../pages/integrations/Integrations";
import { IntegrationItem } from "../pages/integrations/IntegrationItem";
import { OffHoursRecommendations } from "../pages/recommendations/components/off-hours-recommendations/OffHoursRecommendations";
import { CloudChat } from "../pages/cloudchat/CloudChat";
import { RedirectPage } from "../pages/redirect/RedirectPage";
import { RightsizingRecommendations } from "../pages/recommendations/components/rightsizing-recommendations/RightsizingRecommendations";
import { ResourceExplorer } from "../pages/resource-explorer/ResourceExplorer";
import { IntegrationsSlackAppConnection } from "../pages/integrations/IntegrationsSlackAppConnection";
import { Budgets } from "../pages/budgets/Budgets";
import { BudgetItem } from "../pages/budgets/BudgetItem";
import { NotFound } from "../error-pages/NotFound";
import { AccessDenied } from "../error-pages/AccessDenied";
import { useAppSelector } from "../../store/hooks";
import { currentUserIdSelector } from "../../store/profile/profileSelectors";
import { ScheduleResourceProtection } from "../pages/schedule/resource-protection/ScheduleResourceProtection";
import { ProviderAccounts } from "../pages/accounts-group/ProviderAccounts";
import { AccountsOldUrlsTmpGuard } from "../pages/accounts-group/AccountsOldUrlsTmpGuard";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { currentOrganizationSelector } from "../../store/organizations/organizationsSelectors";
import { MainLayout } from "../layout/MainLayout";
import { Categories } from "../pages/categories/Categories";
import { SmartTagPreferences } from "../pages/settings/components/smart-tag/SmartTagPreferences";
import { Users } from "../pages/settings/components/users/Users";
import { Billing } from "../pages/settings/components/billing/Billing";
import { Organization } from "../pages/settings/components/organization/Organization";
import { ApiKeysList } from "../pages/settings/components/api-keys/ApiKeysList";
import { Profile } from "../pages/settings/components/profile/Profile";
import { AuthAndSSO } from "../pages/settings/components/auth/AuthAndSSO";
import { AccountSetup } from "../pages/account-setup/AccountSetup";
import { AccountV2 } from "../pages/account/AccountV2";
import { UtilizationAndCoveragePage } from "../pages/commtiments/utilization-and-coverage/UtilizationAndCoveragePage";
import { EmailNotifications } from "../pages/settings/components/email-notifications/EmailNotifications";
import { SingleRightsizingRecommendation } from "../pages/recommendations/components/single-rightsizing-recommendation/SingleRightsizingRecommendation";
import { Dimensions } from "../pages/dimensions/Dimensions";
import { OffHours } from "../pages/automations/off-hours/data-grid/OffHours";
import { SingleOffHours } from "../pages/automations/off-hours/single-off-hours/SingleOffHours";
import { SingleWorkflow } from "../pages/automations/workflows/single-workflow/SingleWorkflow";
import { WorkflowsPage } from "../pages/automations/workflows/data-grid/WorkflowsPage";
import { Alerts } from "../pages/alerts/Alerts";
import { Commitments } from "../pages/commtiments/Commitments";
import { LiveUsageMgmt } from "../pages/live-usage-mgmt/LiveUsageMgmt";
import { ResourceExplorerAllViews } from "../pages/resource-explorer-all-views/ResourceExplorerAllViews";
import { DashboardAllItems } from "../pages/dashboard-all-items/DashboardAllItems";

const SentryRoutes = Sentry.withSentryRouting(Routes);

export const AppRouter: FC = () => {
  const userId = useAppSelector(currentUserIdSelector);
  const organization = useAppSelector(currentOrganizationSelector);

  const updateContext = useUnleashContext();
  const enableReservedInstances = useFlag("EnableReservedInstances");
  const enableHierarchySeparatePages = useFlag("EnableHierarchySeparatePages");

  useEffect(() => {
    updateContext({
      userId,
      properties: { organizationId: organization?.id ?? "" },
    });
  }, [userId, updateContext, organization?.id]);

  if (!userId) {
    return null;
  }

  const redirectUrl = "/settings/access-denied";

  return (
    <SentryRoutes>
      <Route
        path="/account/setup"
        element={
          <ProtectedRoute>
            <ViewAccessCheck subject="account">
              <AccountSetup />
            </ViewAccessCheck>
          </ProtectedRoute>
        }
      />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/" element={<RedirectPage />} />
        <Route path="not-found" element={<NotFound />} />
        <Route path="/access-denied" element={<AccessDenied />} />

        <Route
          path="/aws/live-usage/*"
          element={
            <ViewAccessCheck subject="account">
              <LiveUsageMgmt provider="aws" />
            </ViewAccessCheck>
          }
        />
        <Route
          path="/gcp/live-usage/*"
          element={
            <ViewAccessCheck subject="account">
              <LiveUsageMgmt provider="gcp" />
            </ViewAccessCheck>
          }
        />
        <Route
          path="/azure/live-usage/*"
          element={
            <ViewAccessCheck subject="account">
              <LiveUsageMgmt provider="azure" />
            </ViewAccessCheck>
          }
        />
        <Route
          path="/aws/:accountId/*"
          element={
            <ViewAccessCheck subject="account">
              <AccountV2 provider="aws" />
            </ViewAccessCheck>
          }
        />
        <Route
          path="/gcp/:accountId/*"
          element={
            <ViewAccessCheck subject="account">
              <AccountV2 provider="gcp" />
            </ViewAccessCheck>
          }
        />
        <Route
          path="/azure/:accountId/*"
          element={
            <ViewAccessCheck subject="account">
              <AccountV2 provider="azure" />
            </ViewAccessCheck>
          }
        />

        <Route
          path="/dashboards"
          element={<ViewAccessCheck subject="dashboard" />}
        >
          {enableHierarchySeparatePages && (
            <Route index element={<DashboardAllItems />} />
          )}
          <Route path=":dashboardId" element={<Dashboard />} />
        </Route>
        <Route
          path="/resource-explorer"
          element={<ViewAccessCheck subject="resource-explorer" />}
        >
          {enableHierarchySeparatePages && (
            <Route index element={<ResourceExplorerAllViews />} />
          )}
          <Route path=":viewId" element={<ResourceExplorer />} />
        </Route>

        <Route path="/aws/orgs/:orgId" element={<AccountsOldUrlsTmpGuard />} />
        <Route path="/aws" element={<AccountsOldUrlsTmpGuard />} />
        <Route path="/gcp" element={<AccountsOldUrlsTmpGuard />} />
        <Route path="/azure" element={<AccountsOldUrlsTmpGuard />} />

        <Route
          path="/:provider/:orgId/accounts"
          element={
            <ViewAccessCheck subject="account">
              <ProviderAccounts />
            </ViewAccessCheck>
          }
        />

        <Route path="/cloudchat" element={<CloudChat />} />

        <Route
          path="/schedule"
          element={<ViewAccessCheck subject="schedule" />}
        >
          <Route path="off-hours" element={<OffHoursV1 />} />
          <Route path="off-hours/create" element={<OffHoursItem />} />
          <Route path="off-hours/edit/:id" element={<OffHoursItem />} />
        </Route>

        <Route
          path="/automations"
          element={<ViewAccessCheck subject="schedule" />}
        >
          <Route path="workflow" element={<WorkflowsPage />} />
          <Route path="workflow/create" element={<SingleWorkflow />} />
          <Route path="workflow/edit/:id" element={<SingleWorkflow />} />

          <Route path="off-hours" element={<OffHours />} />
          <Route path="off-hours/create" element={<SingleOffHours />} />
          <Route path="off-hours/edit/:id" element={<SingleOffHours />} />
        </Route>

        <Route
          path="schedule-resource-protection/:scheduleId/:batchId"
          element={<ScheduleResourceProtection />}
        />

        <Route
          path="/recommendations"
          element={<ViewAccessCheck subject="recommendation" />}
        >
          <Route path="off-hours" element={<OffHoursRecommendations />} />
          <Route path="off-hours/:type" element={<OffHoursRecommendations />} />
          <Route path="rightsizing" element={<RightsizingRecommendations />} />
          <Route
            path="rightsizing/:recommendationId"
            element={<SingleRightsizingRecommendation />}
          />
        </Route>

        <Route
          path="/dimensions"
          element={
            <ViewAccessCheck subject="category" redirectUrl={redirectUrl}>
              <Dimensions />
            </ViewAccessCheck>
          }
        />

        <Route
          path="/integrations"
          element={
            <ViewAccessCheck subject="integration">
              <Integrations />
            </ViewAccessCheck>
          }
        />
        <Route
          path="/integrations/create"
          element={
            <ViewAccessCheck subject="integration">
              <IntegrationItem />
            </ViewAccessCheck>
          }
        />
        <Route
          path="/integrations/slack-app-connection"
          element={
            <ViewAccessCheck subject="integration">
              <IntegrationsSlackAppConnection />
            </ViewAccessCheck>
          }
        />
        <Route
          path="/integrations/edit/:type/:id"
          element={
            <ViewAccessCheck subject="integration">
              <IntegrationItem />
            </ViewAccessCheck>
          }
        />

        <Route path="/budgets" element={<ViewAccessCheck subject="budget" />}>
          <Route path="" element={<Budgets />} />
          <Route path="create" element={<BudgetItem />} />
          <Route path="edit/:id" element={<BudgetItem />} />
        </Route>

        <Route path="/alerts" element={<ViewAccessCheck subject="alert" />}>
          <Route path="" element={<Alerts />} />
        </Route>

        <Route
          path="/execution-log"
          element={<ViewAccessCheck subject="account" />}
        >
          <Route path="" element={<ExecutionLogs />} />
          <Route path=":id" element={<ExecutionLogs />} />
        </Route>

        {enableReservedInstances ? (
          <Route
            path="/commitments"
            element={<ViewAccessCheck subject="commitment" />}
          >
            <Route path=":orgId" element={<Commitments />} />
            <Route path="" element={<Commitments />} />
          </Route>
        ) : (
          <Route
            path="/commitments"
            element={<ViewAccessCheck subject="commitment" />}
          >
            <Route
              path="utilization-and-coverage/:orgId"
              element={<UtilizationAndCoveragePage />}
            />
            <Route
              path="utilization-and-coverage"
              element={<UtilizationAndCoveragePage />}
            />
          </Route>
        )}

        <Route path="/settings">
          <Route path="access-denied" element={<AccessDenied />} />

          <Route
            path="categories"
            element={
              <ViewAccessCheck subject="category" redirectUrl={redirectUrl}>
                <Categories />
              </ViewAccessCheck>
            }
          />
          <Route
            path="smart-tag"
            element={
              <ViewAccessCheck subject="account" redirectUrl={redirectUrl}>
                <SmartTagPreferences />
              </ViewAccessCheck>
            }
          />
          <Route
            path="users"
            element={
              <ViewAccessCheck subject="user" redirectUrl={redirectUrl}>
                <Users />
              </ViewAccessCheck>
            }
          />
          <Route
            path="billing"
            element={
              <ViewAccessCheck subject="billing" redirectUrl={redirectUrl}>
                <Billing />
              </ViewAccessCheck>
            }
          />
          <Route
            path="organization"
            element={
              <ViewAccessCheck subject="organisation" redirectUrl={redirectUrl}>
                <Organization />
              </ViewAccessCheck>
            }
          />
          <Route
            path="api-keys"
            element={
              <ViewAccessCheck subject="api-key" redirectUrl={redirectUrl}>
                <ApiKeysList />
              </ViewAccessCheck>
            }
          />
          <Route path="profile" element={<Profile />} />
          <Route path="notifications" element={<EmailNotifications />} />
          <Route path="auth" element={<AuthAndSSO />} />
        </Route>
      </Route>

      <Route path="*" element={<Navigate to="/" replace />} />
    </SentryRoutes>
  );
};
