import { FC, useCallback, useMemo } from "react";
import { Stack } from "@mui/material";
import { usePreviousImmediate } from "rooks";
import {
  ResourceCardStatisticsCard,
  ResourceCardStatisticsType,
} from "./ResourceCardStatisticsCard";
import { WithResourceType } from "../../../../../utils/types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { getResourceTypeName } from "../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { resourceTypesWithBillingDataCosts } from "../../../../../../../../utils/constants/resources/resources";
import { getAllResourcesInHierarchyByResourceType } from "../../../../../../../../utils/helpers/resources/getAllResourcesInHierarchyByResourceType";
import { liveUsageMgmtFilterTemplatesEnabledSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtFilterTemplatesEnabledSelector";
import { liveUsageMgmtResourceTypeDataEmsSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataEmsSelector";
import { liveUsageMgmtResourceTypeDataSpendingSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataSpendingSelector";
import { liveUsageMgmtResourceTypeDataLoadingSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataLoadingSelector";
import { liveUsageMgmtResourceTypeDataTotalCountSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataTotalCountSelector";
import { liveUsageMgmtFilteredResourcesCountSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtFilteredResourcesCountSelector";
import { liveUsageMgmtFilteredResourcesChildResourcesCountSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtFilteredResourcesChildResourcesCountSelector";
import {
  resetLiveUsageMgmtSelectedResources,
  setLiveUsageMgmtViewWithoutFiltersResourceTypes,
} from "../../../../../../../../store/live-usage-mgmt/liveUsageMgmtSlice";
import { liveUsageMgmtResourceViewIsWithoutFiltersSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceViewIsWithoutFiltersSelector";
import { getLiveUsageMgmtResourceTypeDataThunk } from "../../../../../../../../store/live-usage-mgmt/thunks/resources/getLiveUsageMgmtResourceTypeDataThunk";

interface ResourceCardStatisticsProps extends WithResourceType {}

export const LiveUsageMgmtResourceCardStatistics: FC<
  ResourceCardStatisticsProps
> = ({ resourceType }) => {
  const dispatch = useAppDispatch();

  const viewIsWithoutFilters = useAppSelector((state) =>
    liveUsageMgmtResourceViewIsWithoutFiltersSelector(state, resourceType),
  );
  const ems = useAppSelector((state) =>
    liveUsageMgmtResourceTypeDataEmsSelector(state, resourceType),
  );
  const spending = useAppSelector((state) =>
    liveUsageMgmtResourceTypeDataSpendingSelector(state, resourceType),
  );
  const loading = useAppSelector((state) =>
    liveUsageMgmtResourceTypeDataLoadingSelector(state, resourceType),
  );
  const resourcesTotalCount = useAppSelector((state) =>
    liveUsageMgmtResourceTypeDataTotalCountSelector(state, resourceType),
  );
  const resourcesCount = useAppSelector((state) =>
    liveUsageMgmtFilteredResourcesCountSelector(state, resourceType),
  );

  const resourceChildrenCount = useAppSelector((state) =>
    liveUsageMgmtFilteredResourcesChildResourcesCountSelector(
      state,
      resourceType,
    ),
  );

  const filterTemplatesEnabled = useAppSelector(
    liveUsageMgmtFilterTemplatesEnabledSelector,
  );

  const tooltip = useMemo(() => {
    if (!resourceTypesWithBillingDataCosts.has(resourceType)) {
      return;
    }

    return `${getResourceTypeName(resourceType, {
      singular: true,
      type: "abbreviation",
    })} prices are calculated from cost and usage billing reports and updated  every 24 hours.`;
  }, [resourceType]);

  const count =
    resourceType === "rds_snapshot_source"
      ? resourceChildrenCount
      : resourcesCount;

  const onChange = useCallback(
    (value: ResourceCardStatisticsType) => {
      if ((value === "all") === viewIsWithoutFilters) {
        return;
      }
      const hierarchyResourceTypes =
        getAllResourcesInHierarchyByResourceType(resourceType);

      hierarchyResourceTypes.forEach((rt) => {
        dispatch(resetLiveUsageMgmtSelectedResources(rt));
        dispatch(
          setLiveUsageMgmtViewWithoutFiltersResourceTypes({
            resourceType: rt,
            value: value === "all",
          }),
        );
      });

      dispatch(getLiveUsageMgmtResourceTypeDataThunk(resourceType));
    },
    [dispatch, resourceType, viewIsWithoutFilters],
  );

  const previousCount = usePreviousImmediate(count);
  const previousTotalCount = usePreviousImmediate(resourcesTotalCount);

  return (
    <Stack direction="row" spacing={2} alignItems="center" borderRadius={2}>
      {filterTemplatesEnabled && (
        <ResourceCardStatisticsCard
          price={ems}
          value="filtered"
          loading={loading}
          tooltip={tooltip}
          onChange={onChange}
          title="Filtered Resources"
          selected={!viewIsWithoutFilters}
          instances={`${count ?? previousCount ?? ""} ${getResourceTypeName(
            resourceType,
          )}`}
        />
      )}
      <ResourceCardStatisticsCard
        value="all"
        price={spending}
        loading={loading}
        tooltip={tooltip}
        onChange={onChange}
        title="All Resources"
        selected={viewIsWithoutFilters}
        instances={`${
          resourcesTotalCount ?? previousTotalCount ?? ""
        } ${getResourceTypeName(resourceType)}`}
        hideRadioButton={!filterTemplatesEnabled}
      />
    </Stack>
  );
};
