import { costBreakdownV2WidgetAggregationsSelector } from "./costBreakdownV2WidgetAggregationsSelector";
import { RootState } from "../../../../../store";
import { WidgetCostBreakdownAggregation } from "../../../../../../services/cloudchipr.api";

export const costBreakdownV2WidgetHasAggregationSelector = (
  state: RootState,
  widgetId: string,
) => {
  const aggregations = costBreakdownV2WidgetAggregationsSelector(
    state,
    widgetId,
  );

  return Object.keys(aggregations ?? {}).some(
    (key) => !!aggregations?.[key as keyof WidgetCostBreakdownAggregation],
  );
};
