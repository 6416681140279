import { FC, Fragment, useCallback, useMemo, useState } from "react";
import { Box, IconButton, Stack, TableCell, TableRow } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ApplyFilterTemplateButton } from "./ApplyFilterTemplateButton";
import { FilterTemplateRowActions } from "./FilterTemplateRowActions";
import { FilterTemplatesModifyRow } from "./FilterTemplatesModifyRow";
import { FilterTemplatesFiltersDialog } from "./templates-filters/FilterTemplatesFiltersDialog";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import c8rLogo from "../../../../../../assets/images/logos/c8r-logo.svg";
import { AccountsWithPopover } from "../../../../../common/accounts-with-popover/AccountsWithPopover";
import {
  FilterSet,
  ProviderType,
} from "../../../../../../services/cloudchipr.api";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { useAppSelector } from "../../../../../../store/hooks";
import { allAccountsIdsByProviderSelector } from "../../../../../../store/accounts/selectors/all-providers/allAccountsIdsByProviderSelector";
import { disabledNonConnectedAllAccountsIdsByProviderSelector } from "../../../../../../store/accounts/selectors/all-providers/disabledNonConnectedAllAccountsIdsByProviderSelector";

interface FilterTemplatesRowProps {
  onApply(): void;
  filterSet: FilterSet;
  editingRowId: string;
  duplicatingRowId: string;
  editOrDuplicateMode: boolean;
  setEditingRowId(id: string): void;
  setDuplicatingRowId(id: string): void;
  hideEms?: boolean;
  provider?: ProviderType;
}

export const FilterTemplatesRow: FC<FilterTemplatesRowProps> = ({
  filterSet,
  setEditingRowId,
  editingRowId,
  editOrDuplicateMode,
  onApply,
  setDuplicatingRowId,
  duplicatingRowId,
  hideEms,
  provider,
}) => {
  const { open, openDialog, closeDialog } = useDialog();
  const [accountsModify, setAccountsModify] = useState(false);
  const showEqualisingCell = editOrDuplicateMode && hideEms;
  const allAccountsIds = useAppSelector((state) =>
    allAccountsIdsByProviderSelector(state, provider),
  );
  const disabledAccountsIds = useAppSelector((state) =>
    disabledNonConnectedAllAccountsIdsByProviderSelector(state, provider),
  );

  const filteredAccounts = useMemo(() => {
    return (
      filterSet?.accounts?.filter(
        ({ id }) =>
          !disabledAccountsIds?.includes(id) && allAccountsIds?.includes(id),
      ) ?? []
    );
  }, [filterSet.accounts, allAccountsIds, disabledAccountsIds]);

  const modifyHandler = useCallback(() => {
    setEditingRowId(filterSet.id);
  }, [setEditingRowId, filterSet.id]);

  const modifyAccountsHandler = useCallback(() => {
    modifyHandler();
    setAccountsModify(true);
  }, [modifyHandler]);

  const cancelModifyHandler = useCallback(() => {
    setEditingRowId("");
    setAccountsModify(false);
  }, [setEditingRowId]);

  const duplicateHandler = useCallback(() => {
    setDuplicatingRowId(filterSet.id);
  }, [setDuplicatingRowId, filterSet.id]);

  const cancelDuplicateHandler = useCallback(() => {
    setDuplicatingRowId("");
  }, [setDuplicatingRowId]);

  if (editingRowId === filterSet.id) {
    return (
      <FilterTemplatesModifyRow
        type="modify"
        accountsModify={accountsModify}
        provider={provider}
        filterSet={filterSet}
        hideEms={hideEms}
        onCancel={cancelModifyHandler}
      />
    );
  }

  return (
    <Fragment>
      <TableRow sx={{ position: "relative" }}>
        <TableCell sx={{ maxWidth: 200, height: 57 }}>
          {editOrDuplicateMode && wrapper}
          <Stack direction="row" spacing={1} alignItems="center">
            {filterSet.source === "cloudchipr" && (
              <img src={c8rLogo} alt="C8R-logo" />
            )}

            <TypographyWithTooltip
              variant="body2"
              title={filterSet?.name}
              onClick={openDialog}
              sx={{
                textDecoration: "underline",
                textUnderlineOffset: 4,
                cursor: "pointer",
              }}
            />
          </Stack>
        </TableCell>
        <TableCell sx={{ maxWidth: 250 }}>
          <Stack direction="row" alignItems="center" spacing={0.25}>
            <AccountsWithPopover accounts={filteredAccounts} />
            {!!filteredAccounts.length && (
              <IconButton size="small" onClick={modifyAccountsHandler}>
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
            )}
          </Stack>
        </TableCell>

        {!hideEms && (
          <TableCell>
            <ApplyFilterTemplateButton
              onApply={onApply}
              filterSetId={filterSet.id}
            />
          </TableCell>
        )}

        {showEqualisingCell && <TableCell />}

        <TableCell sx={{ pl: 0 }} align="right">
          <FilterTemplateRowActions
            filterSet={filterSet}
            onModify={modifyHandler}
            onDuplicate={duplicateHandler}
          />
        </TableCell>
      </TableRow>

      {duplicatingRowId === filterSet.id && (
        <FilterTemplatesModifyRow
          type="duplicate"
          provider={provider}
          hideEms={hideEms}
          filterSet={{
            ...filterSet,
            name: `${filterSet.name} Copy`,
          }}
          onCancel={cancelDuplicateHandler}
        />
      )}

      {open && (
        <FilterTemplatesFiltersDialog
          open
          provider={provider}
          onClose={closeDialog}
          filterSet={filterSet}
        />
      )}
    </Fragment>
  );
};

const wrapper = (
  <Box
    top={0}
    zIndex="1"
    height={50}
    width="100%"
    bgcolor="white"
    position="absolute"
    sx={{ opacity: 0.5 }}
  />
);
