import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { CostBreakdownV2WidgetAdvancedSettingsTrendSetting } from "./CostBreakdownV2WidgetAdvancedSettingsTrendSetting";
import AdvancedSettingsIcon from "../../../../../../../../../../../assets/images/icons/advanced_settings_icon.svg";
import { WidgetConfigSection } from "../../../../common/widget-configurations/WidgetConfigSection";
import { WidgetForecastSelect } from "../../../../common/toolbar/WidgetForecastSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";
import { costBreakdownV2WidgetSetupForecastedOptionsSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupForecastedOptionsSelector";
import { costBreakdownV2ForecastChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2ForecastChangeThunk";

export const CostBreakdownV2WidgetAdvancedSettingsSection: FC = () => {
  const dispatch = useAppDispatch();
  const forecastOption = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("forecastOption"),
  );

  const forecastedCostsOptions = useAppSelector(
    costBreakdownV2WidgetSetupForecastedOptionsSelector,
  );

  const forecastChangeHandler = useCallback(
    (event: any) => {
      dispatch(costBreakdownV2ForecastChangeThunk(event.target.value));
    },
    [dispatch],
  );

  return (
    <WidgetConfigSection
      title="Advanced Settings"
      icon={<img src={AdvancedSettingsIcon} alt="Advanced Settings Icon" />}
    >
      <Stack spacing={1.5}>
        <WidgetForecastSelect
          forecast={forecastOption}
          onChange={forecastChangeHandler}
          options={forecastedCostsOptions}
        />
        <CostBreakdownV2WidgetAdvancedSettingsTrendSetting />
      </Stack>
    </WidgetConfigSection>
  );
};
