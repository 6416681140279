import { costBreakdownV2WidgetByIdSelector } from "./costBreakdownV2WidgetByIdSelector";
import { RootState } from "../../../../../store";
import { WidgetCostBreakdownAggregation } from "../../../../../../services/cloudchipr.api";

export const costBreakdownV2WidgetAggregationsSelector = (
  state: RootState,
  widgetId: string,
): WidgetCostBreakdownAggregation | undefined => {
  const data = costBreakdownV2WidgetByIdSelector(state, widgetId);
  if (!data) {
    return;
  }
  return data?.aggregation;
};
