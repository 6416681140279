import { FC, useCallback } from "react";
import { useAppDispatch } from "../../../../../../../../../store/hooks";
import { Dates } from "../../../../../../../../../services/cloudchipr.api";
import { WidgetSetupDateRangeSelectV2 } from "../../common/toolbar/WidgetSetupDateRangeSelectV2";
import { largestCostChangesSetupChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/largest-cost-changes/widget-change/largestCostChangesSetupChangeThunk";

export const LargestCostChangesDateRangeSelect: FC = () => {
  const dispatch = useAppDispatch();

  const dateRangeChangeHandler = useCallback(
    (dates: Dates) => {
      dispatch(largestCostChangesSetupChangeThunk({ dates }));
    },
    [dispatch],
  );

  return <WidgetSetupDateRangeSelectV2 onDateChange={dateRangeChangeHandler} />;
};
