import { costBreakdownV2SetupPropertyByKeySelector } from "./costBreakdownV2SetupPropertyByKeySelector";
import { CostBreakdownWidgetSetupType } from "../../../utils/types/setups/costBreakdownSetupType";
import { RootState } from "../../../../store";

export const costBreakdownV2SetupAggregationsPropertyByKeySelector =
  (key: keyof CostBreakdownWidgetSetupType["aggregation"]) =>
  (state: RootState): any => {
    const setup =
      costBreakdownV2SetupPropertyByKeySelector("aggregation")(state);

    if (!setup) {
      return null;
    }

    return setup?.[key];
  };
