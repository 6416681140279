import { FC } from "react";
import { Divider, List, Stack, colors } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { BottomNavigation } from "./components/bottom-navigation/BottomNavigation";
import { LiveUsageNavigation } from "./components/live-usage-items/LiveUsageNavigation";
import { RecommendationsNavigation } from "./components/recommendations/RecommendationsNavigation";
import { LogoArea } from "./components/logo-area/LogoArea";
import { CommitmentsNavigation } from "./components/commitments/CommitmentsNavigation";
import { ResourceExplorerV2Navigation } from "./components/resource-explorer-v2/ResourceExplorerV2Navigation";
import { BudgetsNavigation } from "./components/budgets/BudgetsNavigation";
import { DashboardV2Navigation } from "./components/dashboard-v2/DashboardV2Navigation";
import { AutomationNavigation } from "./components/automations/AutomationNavigation";
import { AlertsNavigation } from "./components/alerts/AlertsNavigation";

interface NavigationProps {
  onCollapse(): void;
}

export const Navigation: FC<NavigationProps> = ({ onCollapse }) => {
  const enableAlerts = useFlag("EnableAlerts");

  return (
    <Stack
      height="100%"
      bgcolor="grey.50"
      zIndex={(theme) => theme.zIndex.drawer}
    >
      <LogoArea onCollapse={onCollapse} />

      {/* Scrollable navigation area */}
      <Stack sx={wrapperStyles}>
        <List dense component="nav" sx={{ pt: 0.5 }}>
          <DashboardV2Navigation />

          <Divider sx={{ mb: 0.75, mt: 1 }} />

          <ResourceExplorerV2Navigation />

          <CommitmentsNavigation />

          <BudgetsNavigation />

          {enableAlerts && <AlertsNavigation />}

          <Divider sx={{ my: 0.75 }} />
          <LiveUsageNavigation />
          <Divider sx={{ my: 0.75 }} />

          <AutomationNavigation />

          <RecommendationsNavigation />
        </List>
      </Stack>

      {/* Bottom sticky navigation area */}
      <Stack justifySelf="flex-end">
        <BottomNavigation />
      </Stack>
    </Stack>
  );
};

const wrapperStyles = {
  flexGrow: 1,
  flexShrink: 1,
  overflow: "auto",
  background: `
  linear-gradient(${colors["grey"]["50"]} 60%, ${colors["grey"]["50"]}) center top,
  linear-gradient(${colors["grey"]["50"]}, ${colors["grey"]["50"]} 70%) center bottom,
  radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0)) center bottom
  `,
  backgroundRepeat: "no-repeat",
  backgroundSize: "0 0, 100% 40px, 700% 44px, 100% 14px",
  backgroundAttachment: "local, local, scroll, scroll",
};
