import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DimensionCategories,
  DimensionsDrawerMode,
  DimensionsState,
} from "./utils/types/common";
import { FilterTreeNodeWithId } from "../../components/pages/common/filters-tree/utils/types/common";

const initialState: DimensionsState = {
  drawerMode: null,
  dimensionName: "",
  categories: {},
};

export const dimensionsSlice = createSlice({
  name: "dimensions",
  initialState: initialState,
  reducers: {
    // dimension
    resetDimensions: () => initialState,

    setDimensionsState: (_, action: PayloadAction<DimensionsState>) =>
      action.payload,

    setDimensionsDrawerMode: (
      state,
      action: PayloadAction<DimensionsDrawerMode>,
    ) => {
      state.drawerMode = action.payload;
    },

    setDimensionName: (state, action: PayloadAction<string>) => {
      state.dimensionName = action.payload;
    },

    // categories

    addNewCategory: (state, action: PayloadAction<DimensionCategories>) => {
      /* eslint-disable */
      // @ts-ignore: disabled because of cyclic types
      state.categories = { ...state.categories, ...action.payload };
      /* eslint-enable */
    },

    deleteCategory: (state, action: PayloadAction<string>) => {
      delete state.categories[action.payload];
    },

    setCategoryName: (
      state,
      action: PayloadAction<{ id: string; name: string }>,
    ) => {
      const { id, name } = action.payload;

      if (state.categories[id]) {
        state.categories[id].name = name;
      }
    },

    setCategoryFilterTree: (
      state,
      action: PayloadAction<{ id: string; tree: FilterTreeNodeWithId }>,
    ) => {
      const { id, tree } = action.payload;

      state.categories[id].filter_tree = tree;
    },
  },
});

export const {
  setDimensionsDrawerMode,
  setDimensionsState,
  resetDimensions,
  setDimensionName,
  setCategoryName,
  addNewCategory,
  deleteCategory,
  setCategoryFilterTree,
} = dimensionsSlice.actions;

export default dimensionsSlice.reducer;
