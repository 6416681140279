import { FC, useCallback, useMemo, useState } from "react";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { ListItemText } from "@mui/material";
import { useDidMount } from "rooks";
import { GroupingByTagValueOption } from "./components/with-nested-options/GroupingByTagValueOption";
import { GroupByDimensionOption } from "./components/with-nested-options/GroupByDimensionOption";
import { GroupBySelectorTrigger } from "./components/triggers/GroupBySelectorTrigger";
import { ResourceExplorerGrouping } from "../../../../services/cloudchipr.api";
import { DropdownSelect } from "../../../common/select/dropdown-select/DropdownSelect";
import { FilterTriggerComponentProps } from "../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { getResourcesExplorerGroupingLabel } from "../../resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { DropdownSelectOption } from "../../../common/select/dropdown-select/utils/types/types";
import { resourceExplorerGroupings } from "../../resource-explorer/components/resource-explorer-card/utils/constants/groupings";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { connectedProvidersSelector } from "../../../../store/common/selectors/connectedProvidersSelector";
import { getResourceExplorerGroupingTagKeysThunk } from "../../../../store/common/thunks/resource-explorer/getResourceExplorerGroupingTagKeysThunk";

interface GroupingSelectorProps {
  disabled?: boolean;
  groupValues?: string[];
  value: ResourceExplorerGrouping;
  triggerSize?: TextFieldProps["size"];
  hiddenGroupings?: ResourceExplorerGrouping[];
  onChange(grouping: ResourceExplorerGrouping, groupValue?: string[]): void;
}

export const GroupingSelector: FC<GroupingSelectorProps> = ({
  value,
  groupValues,
  disabled,
  onChange,
  hiddenGroupings,
  triggerSize,
}) => {
  const dispatch = useAppDispatch();
  const [forceToClose, setForceToClose] = useState(false);
  const providers = useAppSelector(connectedProvidersSelector);

  const closeHandler = useCallback(() => {
    setForceToClose(true);

    setTimeout(() => {
      setForceToClose(false);
    }, 300);
  }, []);

  const renderOptions = useMemo(() => {
    const options = resourceExplorerGroupings
      .filter((grouping) => !hiddenGroupings?.includes(grouping))
      .map((grouping) => {
        const label = getResourcesExplorerGroupingLabel(
          grouping,
          true,
          providers,
        );

        if (grouping === "cost_allocation_tag") {
          return {
            value: grouping,
            disableSelection: true,
            rawValue: { search: `${grouping} ${label}` },
            label: (
              <GroupingByTagValueOption
                onChange={onChange}
                onClose={closeHandler}
                values={value === "cost_allocation_tag" ? groupValues : []}
                label={label}
              />
            ),
          } as DropdownSelectOption;
        }

        return {
          value: grouping,
          rawValue: { search: `${grouping} ${label}` },
          label: (
            <ListItemText
              primary={label}
              primaryTypographyProps={{ sx: { whiteSpace: "nowrap" } }}
            />
          ),
        } as DropdownSelectOption;
      });

    options.push({
      value: "category",
      disableSelection: true,
      rawValue: { search: "dimension category" },
      label: (
        <GroupByDimensionOption onClose={closeHandler} onChange={onChange} />
      ),
    });

    return options;
  }, [closeHandler, hiddenGroupings, value, groupValues, onChange, providers]);

  const submitHandlerOnClose = useCallback(
    (values: string[]) => {
      const selected = values.at(0) as ResourceExplorerGrouping;

      if (!selected) {
        return;
      }

      onChange(selected);
    },
    [onChange],
  );

  const TriggerComponent = useCallback(
    (props: FilterTriggerComponentProps) => {
      return (
        <GroupBySelectorTrigger
          {...props}
          groupValues={groupValues}
          size={triggerSize}
        />
      );
    },
    [groupValues, triggerSize],
  );

  useDidMount(() => {
    dispatch(getResourceExplorerGroupingTagKeysThunk());
  });

  if (!renderOptions) {
    return null;
  }

  return (
    <DropdownSelect
      singleSelect
      listWidth={320}
      label="Group by"
      disabled={disabled}
      filterFn={filterFn}
      showSelectAll={false}
      options={renderOptions}
      sortSelectedOptions={false}
      forceToClose={forceToClose}
      values={value ? [value] : []}
      TriggerComponent={TriggerComponent}
      submitHandlerOnClose={submitHandlerOnClose}
    />
  );
};

const filterFn = (option: DropdownSelectOption, keyword: string) => {
  return option.rawValue.search.toLowerCase().includes(keyword.toLowerCase());
};
