import { FC, memo, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import {
  DataGrid,
  DataGridProps,
} from "../../../../../../../../../../storybook/data-grid/DataGrid";
import { useColumnsSorting } from "../../../../../../../utils/hooks/useColumnsSorting.hook";
import { useColumnsVisibility } from "../../../../../../../utils/hooks/useColumnsVisibility.hook";
import { getResourceTypeColumns } from "../utils/data-grid/columns-setup/getResourceTypeColumns";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { ResourceType } from "../../../../../../../../../../services/cloudchipr.api";
import {
  nfSortingConverter,
  nfVisibilitiesConverter,
} from "../utils/nf-tmp-helpers";
import { generateColumns } from "../utils/data-grid/columns-setup/resourcesColumnsGenerator";
import {
  FilteredResourcesType,
  ResourceDataType,
} from "../../../../../../../../../../store/account/utils/types/types";
import { newMetricColumnsByFT } from "../cells/metrics-cells/utils/helpers/newMetricColumnsEnablenes";
import { useDataGridContext } from "../../../../../../../../../../storybook/data-grid/DataGridProvider";
import { generateMetricsFrequenciesFromContextData } from "../utils/csv-data/generateMetricsFrequenciesFromContextData";

type DataGridExtendedProps = Pick<
  DataGridProps<any>,
  | "rowExpanding"
  | "rowSelection"
  | "toolbar"
  | "includeHeader"
  | "styles"
  | "pagination"
>;

export interface SubRowDataGridProps extends DataGridExtendedProps {
  resourceType: ResourceType;
  resource: FilteredResourcesType;
  globalFilter?: string;
  exportedColumns?: string[];
}

export const SubRowDataGridNF: FC<SubRowDataGridProps> = memo(
  ({
    rowSelection,
    rowExpanding,
    resource,
    globalFilter,
    toolbar,
    includeHeader,
    styles,
    pagination,
    resourceType,
    exportedColumns,
  }) => {
    const { visibility, setVisibility } = useColumnsVisibility(resourceType);
    const enableMetricsCharts = useFlag("EnableResourceSmallCharts");
    const {
      cellSpecificMetadata: { data: cellData },
    } = useDataGridContext();
    const metricsFrequencies = useMemo(
      () => generateMetricsFrequenciesFromContextData(cellData),
      [cellData],
    );

    const expandable = !!rowExpanding;
    const selectable = !!rowSelection;

    const columns = getResourceTypeColumns(
      resourceType,
      (exportedColumns ?? []).concat(newMetricColumnsByFT(enableMetricsCharts)),
    );
    const columnsSetup = useMemo(() => {
      return generateColumns(columns as ColumnSetupType<ResourceDataType>[], {
        includeExpander: expandable,
        includeCheckbox: selectable,
        includeActions: selectable,
        metricsFrequencies,
      });
    }, [columns, expandable, selectable, metricsFrequencies]);

    const { sorting, setSorting } = useColumnsSorting(
      resourceType,
      columns ?? [],
    );

    const memoizedSorting = useMemo(
      () => nfSortingConverter(sorting),
      [sorting],
    );

    const memoizedVisibility = useMemo(() => {
      return nfVisibilitiesConverter(visibility);
    }, [visibility]);

    if (!resource?.resources?.length) {
      return <ResourceEmptyState />;
    }

    return (
      <DataGrid
        key={resourceType}
        data={resource.resources}
        columns={columnsSetup}
        columnResizeMode="onEnd"
        enableStickyColumns={!!resource.resources?.length}
        columnVisibility={{
          initialVisibility: memoizedVisibility,
          onColumnsVisibilityChange: setVisibility,
        }}
        globalFilter={{
          initialGlobalFilter: globalFilter,
        }}
        columnSorting={{
          initialSort: memoizedSorting,
          onColumnsSortingChange: setSorting,
        }}
        rowSelection={rowSelection}
        pagination={pagination}
        rowExpanding={rowExpanding}
        toolbar={toolbar}
        includeHeader={includeHeader}
        styles={styles}
      />
    );
  },
);

export const ResourceEmptyState = () => {
  return (
    <Stack my={2} justifyContent="center" alignItems="center">
      <Typography>No resources found based on filters</Typography>
    </Stack>
  );
};
