import { FC } from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { MultiTypeChartProvider } from "../../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { CostBreakdownV2WidgetSetupContentCosts } from "../components/CostBreakdownV2WidgetSetupContentCosts";
import { CostBreakdownV2WidgetChart } from "../components/chart-view/CostBreakdownV2WidgetChart";
import { costBreakdownV2WidgetSetupChartDataSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupChartDataSelector";
import { costBreakdownV2WidgetSetupTotalCostDateDetailsSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupTotalCostDateDetailsSelector";
import { costBreakdownV2WidgetSetupLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupLoadingSelector";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";
import { costBreakdownV2SetupVisualizationPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupVisualizationPropertyByKeySelector";
import { costBreakdownV2WidgetSetupCombinedChartDataSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupCombinedChartDataSelector";
import { costBreakdownV2SetupHasAggregationSelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupHasAggregationSelector";

export const CostBreakdownV2WidgetSetupChartView: FC = () => {
  const data = useAppSelector(costBreakdownV2WidgetSetupChartDataSelector);
  const dateDataPoint = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("frequency"),
  );

  const combinedChartData = useAppSelector(
    costBreakdownV2WidgetSetupCombinedChartDataSelector,
  );

  const dateDetails = useAppSelector(
    costBreakdownV2WidgetSetupTotalCostDateDetailsSelector,
  );
  const loading = useAppSelector(costBreakdownV2WidgetSetupLoadingSelector);

  const chartType =
    useAppSelector(
      costBreakdownV2SetupVisualizationPropertyByKeySelector("chart_type"),
    ) ?? "line";

  const chartBase = useAppSelector(
    costBreakdownV2SetupVisualizationPropertyByKeySelector("chart_base_type"),
  );

  const hasAggregations = useAppSelector(
    costBreakdownV2SetupHasAggregationSelector,
  );

  return (
    <Stack flex={1} p={2} justifyContent={hasAggregations ? "start" : "center"}>
      <MultiTypeChartProvider
        value={{
          data: data ?? [],
          initialChartType: chartType,
        }}
      >
        <CostBreakdownV2WidgetSetupContentCosts />
        <CostBreakdownV2WidgetChart
          dateDataPoint={dateDataPoint}
          chartType={chartType}
          dateDetails={dateDetails}
          loading={!data.length && loading}
          chartBase={chartBase}
          combinedChartData={combinedChartData}
        />
      </MultiTypeChartProvider>
    </Stack>
  );
};
