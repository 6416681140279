import { FC, useCallback } from "react";
import { SwitchWithTypography } from "../../../../../../../../../../SwitchWithTypography";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { costBreakdownV2SetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupVisualizationPropertyByKeySelector";
import { costBreakdownV2ViewVisualizationChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2ViewVisualizationChangeThunk";

export const CostBreakdownV2WidgetVisualizationPreviousPeriodColumn: FC =
  () => {
    const dispatch = useAppDispatch();
    const previousPeriodColumnChecked = useAppSelector(
      costBreakdownV2SetupVisualizationPropertyByKeySelector(
        "previous_period_cost",
      ),
    );

    const handlePreviousPeriodCheckedChange = useCallback(
      (checked: boolean) => {
        dispatch(
          costBreakdownV2ViewVisualizationChangeThunk({
            previous_period_cost: checked,
          }),
        );
      },
      [dispatch],
    );

    return (
      <SwitchWithTypography
        checked={previousPeriodColumnChecked}
        onChange={handlePreviousPeriodCheckedChange}
        title="Previous Period Cost"
      />
    );
  };
