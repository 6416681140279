import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCostByCategoryWidgetDataThunk } from "./fetchCostByCategoryWidgetDataThunk";
import { RootState } from "../../../../../store";
import { costByCategorySetupSelector } from "../../../../selectors/setups/cost-by-category/costByCategorySetupSelector";
import { categoryIdsByCategoryGroupNameSelector } from "../../../../../categories/selectors/categoryIdsByCategoryGroupNameSelector";
import { buildingInProgressWidgetIdWithNoFilter } from "../../utils/constants";

export const fetchCostByCategoryWidgetDataWithoutFiltersThunk =
  createAsyncThunk(
    "dashboards/fetchCostByCategoryWidgetDataWithoutFiltersThunk",
    async (_: void, { dispatch, getState }) => {
      const state = getState() as RootState;
      const setup = costByCategorySetupSelector(state);
      const categoryIds = categoryIdsByCategoryGroupNameSelector(
        state,
        setup?.categoryGroup ?? "",
      );

      if (!setup) {
        return;
      }

      await dispatch(
        fetchCostByCategoryWidgetDataThunk({
          dateTo: setup.date.to,
          dateFrom: setup.date.from,
          dateLabel: setup.date.label,
          frequency: setup.frequency,
          id: buildingInProgressWidgetIdWithNoFilter,
          displayFilteredResources: setup.displayFilteredResources,
          categoryIds: categoryIds,
        }),
      ).unwrap();
    },
  );
