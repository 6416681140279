import { costBreakdownV2WidgetDataByWidgetIdSelector } from "./costBreakdownV2WidgetDataByWidgetIdSelector";
import { costBreakdownV2WidgetFrequencySelector } from "./costBreakdownV2WidgetFrequencySelector";
import { RootState } from "../../../../../store";
import { WidgetCosts } from "../../../../utils/types/types";
import { generateCostBreakdownWidgetCosts } from "../../../../utils/helpers/costBreakdown/generateCostBreakdownWidgetCosts";

export const costBreakdownV2WidgetCostsSelector = (
  state: RootState,
  widgetId: string,
): WidgetCosts | undefined => {
  const widgetData = costBreakdownV2WidgetDataByWidgetIdSelector(
    state,
    widgetId,
  );
  const frequency = costBreakdownV2WidgetFrequencySelector(state, widgetId);

  return generateCostBreakdownWidgetCosts(widgetData, frequency);
};
