import { FC, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Drawer, Typography } from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import { ResourceProtectionDataGrid } from "./ResourceProtectionDataGrid";
import { useGetUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdQuery } from "../../../../services/cloudchipr.api";
import { AccessDenied } from "../../../error-pages/AccessDenied";
import { useDialog } from "../../../../utils/hooks/useDialog.hook";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getActionValue } from "../../../../utils/clean-options";
import { getResourceProtectionResourcesThunk } from "../../../../store/schedules/thunks/resources-for-protector/getResourceProtectionResourcesThunk";
import { protectorResourcesDataSelector } from "../../../../store/schedules/selectors/resources-for-protector/protectorResourcesDataSelector";
import { useSchedulePreviewDatesInfoHook } from "../../automations/common/utils/hooks/useSchedulePreviewDatesInfo.hook";
import { protectorResourcesLoadingSelector } from "../../../../store/schedules/selectors/resources-for-protector/protectorResourcesLoadingSelector";
import { useAppAbility } from "../../../../services/permissions";
import { ScheduleResourcesPreviewDrawerAppBar } from "../../automations/common/components/resources-preview-old/components/app-bar/ScheduleResourcesPreviewDrawerAppBar";
import { PreviewDataGridSkeleton } from "../../automations/common/components/resources-preview-old/components/PreviewDataGridSkeleton";
import { ResourcesPreviewHeader } from "../../automations/common/components/resources-preview-old/components/ResourcesPreviewHeader";

export const ScheduleResourceProtection: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { open, closeDialog } = useDialog(true);

  const { scheduleId, batchId } = useParams();
  const { can } = useAppAbility();
  const canViewSchedule = can("view", "schedule");

  const { data: schedule, isLoading } =
    useGetUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdQuery(
      { workflowId: scheduleId ?? "", batchId: batchId ?? "" },
      { skip: !scheduleId || !batchId || canViewSchedule },
    );

  const data = useAppSelector((state) =>
    protectorResourcesDataSelector(state, scheduleId, batchId),
  );
  const resourcesLoading = useAppSelector((state) =>
    protectorResourcesLoadingSelector(state, scheduleId, batchId),
  );
  const loading = (resourcesLoading && !data) || isLoading;

  const accountIds = schedule?.accounts.map((account) => account.id) ?? [];

  const { nextRuns, frequency } = useSchedulePreviewDatesInfoHook({
    nextRun: schedule?.metadata?.next_run_at ?? "",
    timeZone: schedule?.time_zone ?? "",
    cron: schedule?.cron ?? "",
    weeklyInterval: null,
  });

  useEffectOnceWhen(
    () => {
      dispatch(getResourceProtectionResourcesThunk({ scheduleId, batchId }));
    },
    !!(scheduleId && batchId && !canViewSchedule),
  );

  useEffectOnceWhen(() => {
    navigate(`/schedule/classic?preview=${scheduleId}`, { replace: true });
  }, canViewSchedule && !!scheduleId);

  return (
    <Fragment>
      <AccessDenied />

      <Drawer
        open={open}
        anchor="right"
        onClose={closeDialog}
        PaperProps={{ sx: { width: "80vw" } }}
      >
        <ResourcesPreviewHeader
          onClose={closeDialog}
          title={schedule?.name ?? ""}
          status={schedule?.status ?? ""}
        />

        <ScheduleResourcesPreviewDrawerAppBar
          scheduleId={schedule?.id ?? ""}
          batchId={batchId ?? ""}
          accountIds={accountIds}
          gracePeriodValue={schedule?.grace_period?.period}
          nextRuns={nextRuns}
          frequency={frequency}
        />

        <Box px={3} py={2} overflow="auto">
          {loading ? (
            <PreviewDataGridSkeleton />
          ) : (
            <Fragment>
              {data?.length ? (
                data?.map(({ resource_type, resources, action, snapshot }) => {
                  return (
                    <ResourceProtectionDataGrid
                      gracePeriodEnabled={!!schedule?.grace_period}
                      key={resource_type}
                      batchId={batchId ?? ""}
                      scheduleId={schedule?.id ?? ""}
                      resourceType={resource_type}
                      accountIds={accountIds}
                      action={getActionValue(action, snapshot)}
                      singleGrid={data.length === 1}
                      data={resources.resources}
                      scheduleAction={schedule?.action ?? null}
                    />
                  );
                })
              ) : (
                <Typography
                  mt={4}
                  variant="subtitle1"
                  color="text.secondary"
                  align="center"
                >
                  No Resources Found
                </Typography>
              )}
            </Fragment>
          )}
        </Box>
      </Drawer>
    </Fragment>
  );
};
