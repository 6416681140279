import Typography from "@mui/material/Typography";
import { AlertsCostAnomalyDataGridViewCell } from "./AlertsCostAnomalyDataGridViewCell";
import { AlertsCostAnomalyDataGridTimeIntervalCell } from "./AlertsCostAnomalyDataGridTimeIntervalCell";
import { alertsCostAnomalyTimeIntervalFilter } from "../utils/helpers/filterFunctions/alertsCostAnomalyTimeIntervalFilter";
import { alertsCostAnomalyTimeIntervalSortingFn } from "../utils/helpers/sortingFunctions/alertsCostAnomalyTimeIntervalSortingFn";
import { alertsThresholdFilter } from "../../common/utils/filterFunctions/alertsThresholdFilter";
import { alertsThresholdSortingFn } from "../../common/utils/sortingFunctions/alertsThresholdSortingFn";
import { alertsCreatedByFilter } from "../../common/utils/filterFunctions/alertsCreatedByFilter";
import { alertsCreatedBySortingFn } from "../../common/utils/sortingFunctions/alertsCreatedBySortingFn";
import { alertsNotificationsFilter } from "../../common/utils/filterFunctions/alertsNotificationsFilter";
import { alertsNotificationsSortingFn } from "../../common/utils/sortingFunctions/alertsNotificationsSortingFn";
import { ColumnSetupType } from "../../../../../../../storybook/data-grid/utils/types/types";
import { ResourceExplorerGrouping } from "../../../../../../../services/cloudchipr.api";
import { AlertsDataGridNameCell } from "../../common/AlertsDataGridNameCell";
import { AlertsDataGridThresholdCell } from "../../common/AlertsDataGridThresholdCell";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { resourcesExplorerGroupingSingularLabels } from "../../../../../resource-explorer/components/resource-explorer-card/utils/constants/groupings";
import { AlertsDataGridNotificationsCell } from "../../common/alerts-notifications-cell/AlertsDataGridNotificationsCell";
import { CostAnomalyAlertsDataGridDataType } from "../../../../../../../store/alerts/utils/types/types";
import { AlertsDataGridActionsCell } from "../../common/actions/AlertsDataGridActionsCell";
import { UserActivityCell } from "../../../../../../common/data-grid-cells/UserActivityCell";
import { alertsModifiedByFilter } from "../../common/utils/filterFunctions/alertsModifiedByFilter";

export const alertsCostAnomalyDataGridColumns: ColumnSetupType<CostAnomalyAlertsDataGridDataType>[] =
  [
    {
      accessorKey: "name",
      header: () => {
        return (
          <Typography variant="inherit" px={1}>
            Alert Name
          </Typography>
        );
      },
      minSize: 100,
      size: 160,
      cell: (cell) => {
        const alert = cell.row.original;
        const viewName = alert.viewName;
        return (
          <AlertsDataGridNameCell
            deletedView={!viewName}
            alertName={alert.name}
            alertId={alert.id}
            alertType="costAnomaly"
          />
        );
      },
      meta: {
        sticky: "left",
        hideFromSettings: true,
        cellStyles: { bgcolor: "white", px: 2 },
      },
    },
    {
      accessorKey: "viewName",
      header: () => {
        return (
          <Typography variant="inherit" pl={1}>
            View
          </Typography>
        );
      },
      minSize: 100,
      size: 180,
      cell: (cell) => {
        const viewName = cell.getValue() as string;
        const viewId = cell.row.original.view_id;
        return (
          <AlertsCostAnomalyDataGridViewCell
            viewId={viewId}
            viewName={viewName}
          />
        );
      },
      meta: {
        cellStyles: { pl: 2 },
        headerTitle: "View",
      },
    },
    {
      accessorKey: "grouping",
      header: "Grouping",
      minSize: 80,
      size: 150,
      cell: (cell) => {
        const grouping = cell.getValue() as ResourceExplorerGrouping;
        const title = resourcesExplorerGroupingSingularLabels.get(grouping);

        return <TypographyWithTooltip variant="inherit" title={title} />;
      },
    },
    {
      id: "thresholds",
      accessorKey: "id",
      header: "Threshold",
      minSize: 80,
      size: 150,
      filterFn: alertsThresholdFilter,
      sortingFn: alertsThresholdSortingFn,
      cell: ({ row }) => {
        const thresholds = row.original.thresholds;
        return (
          <AlertsDataGridThresholdCell
            thresholds={thresholds}
            alertType="costAnomaly"
          />
        );
      },
    },
    {
      id: "time_interval",
      accessorKey: "id",
      header: "Time Interval",
      minSize: 80,
      size: 150,
      filterFn: alertsCostAnomalyTimeIntervalFilter,
      sortingFn: alertsCostAnomalyTimeIntervalSortingFn,
      cell: ({ row }) => {
        const interval = row.original.time_interval;

        return (
          <AlertsCostAnomalyDataGridTimeIntervalCell
            timeInterval={interval.time_interval}
            timeIntervalUnit={interval.time_interval_unit}
          />
        );
      },
    },
    {
      accessorKey: "id",
      id: "modified_by",
      header: "Last Modified By",
      minSize: 150,
      size: 180,
      filterFn: alertsModifiedByFilter,
      sortingFn: alertsCreatedBySortingFn,
      cell: (cell) => {
        const alert = cell.row.original;
        const modifiedBy = alert.modified_by;

        return (
          <UserActivityCell
            date={alert.modified_at ?? ""}
            name={modifiedBy?.name ?? modifiedBy?.email ?? ""}
            deleted={modifiedBy?.name?.includes("deleted")}
          />
        );
      },
    },
    {
      accessorKey: "id",
      id: "created_by",
      header: "Created By",
      minSize: 100,
      size: 180,
      filterFn: alertsCreatedByFilter,
      sortingFn: alertsCreatedBySortingFn,
      cell: (cell) => {
        const alert = cell.row.original;
        const createdBy = alert.created_by;

        return (
          <UserActivityCell
            date={alert.created_at}
            name={createdBy?.name ?? createdBy?.email ?? ""}
            deleted={createdBy?.name?.includes("deleted")}
          />
        );
      },
    },
    {
      id: "notifications",
      accessorKey: "id",
      header: "Notification Method",
      minSize: 100,
      size: 160,
      filterFn: alertsNotificationsFilter,
      sortingFn: alertsNotificationsSortingFn,
      cell: ({ row }) => {
        const integrations = row.original.integrations;
        const emails = row.original.emails;

        return (
          <AlertsDataGridNotificationsCell
            emails={emails}
            integrations={integrations}
          />
        );
      },
    },
    {
      id: "actions",
      size: 110,
      enableResizing: false,
      enableGlobalFilter: false,
      cell: (cell) => {
        const alert = cell.row.original;

        return (
          <AlertsDataGridActionsCell
            alert={alert}
            alertsActiveTab="costAnomaly"
          />
        );
      },
      meta: {
        sticky: "right",
        hideFromSettings: true,
        cellStyles: { bgcolor: "white", p: 0 },
      },
    },
  ];
