import { RootState } from "../../../../store";
import { dimensionCategoriesEntriesSelector } from "../../slice-data/dimensionCategoriesEntriesSelector";
import { filterTreeFiltersAreInvalid } from "../../../../../components/pages/common/filters-tree/utils/helpers/filterTreeFiltersAreInvalid";

export const categoriesCreationDataIsInvalidSelector = (state: RootState) => {
  const categories = dimensionCategoriesEntriesSelector(state);

  let invalid = false;

  categories.forEach(([, category]) => {
    if (invalid) {
      return;
    }

    invalid = !category.name;

    if (invalid) {
      return;
    }

    invalid = filterTreeFiltersAreInvalid(category.filter_tree);
  });

  return invalid;
};
