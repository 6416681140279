import { FC, useCallback, useMemo } from "react";
import { useDebounce } from "rooks";
import { costByCategoryWidgetSetupTableColumns } from "./columns/costByCategoryWidgetSetupTableColumns";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { DataGrid } from "../../../../../../../../../../../storybook/data-grid/DataGrid";
import { costByCategorySetupChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/cost-by-category/costByCategorySetupChangeThunk";
import { costByCategorySetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-by-category/costByCategorySetupPropertyByKeySelector";
import { costByCategoryDataGridDataSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-by-category/data/costByCategoryDataGridDataSelector";
import { DataGridProvider } from "../../../../../../../../../../../storybook/data-grid/DataGridProvider";
import { widgetDataGridStyle } from "../../../../../../../../utils/constants/stylings";
import { ToolbarConfig } from "../../../../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { WidgetSetupTableToolbar } from "../../../../common/WidgetSetupTableToolbar";
import { fetchCostByCategoryWidgetDataBySetupThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/cost-by-category/data-fetch/fetchCostByCategoryWidgetDataBySetupThunk";

export const CostByCategoryWidgetSetupDataGrid: FC = () => {
  const dispatch = useAppDispatch();

  const widgetData = useAppSelector(costByCategoryDataGridDataSelector);
  const categoryIds = useAppSelector(
    costByCategorySetupPropertyByKeySelector("categoryIds"),
  ) as string[];

  const initialSelectedItems = useMemo(() => {
    return categoryIds.reduce((acc, id) => ({ ...acc, [id]: true }), {}) ?? {};
  }, [categoryIds]);

  const dispatchHandler = useCallback(
    (fetch: boolean) => {
      if (fetch) {
        dispatch(fetchCostByCategoryWidgetDataBySetupThunk());
      }
    },
    [dispatch],
  );

  const setDebouncedValue = useDebounce(dispatchHandler, 1000);

  const onRowSelectionChange = useCallback(
    (rows: Record<string, boolean>): void => {
      const categoryIds = Object.keys(rows);
      dispatch(costByCategorySetupChangeThunk({ categoryIds }));
      setDebouncedValue(!!categoryIds.length);
    },
    [dispatch, setDebouncedValue],
  );

  if (!widgetData) {
    return null;
  }

  return (
    <DataGridProvider>
      <DataGrid
        styles={widgetDataGridStyle}
        data={widgetData}
        toolbar={toolbar}
        columns={costByCategoryWidgetSetupTableColumns}
        columnSorting={sortingConfig}
        rowSelection={{
          initialSelectedItems,
          rowSelectionChange: onRowSelectionChange,
        }}
      />
    </DataGridProvider>
  );
};

const sortingConfig = { initialSort: [{ id: "total_cost", desc: true }] };

const toolbar: ToolbarConfig = {
  renderToolbar: (props) => (
    <WidgetSetupTableToolbar
      {...props}
      tooltip="Widget costs are calculated based on the items selected in the table below."
    />
  ),
};
