import { FC } from "react";
import { Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import {
  DashboardWidgetCostAndUsageSummary,
  Dates,
} from "../../../../../../services/cloudchipr.api";
import { useGetWidgetsCostAndUsageSummaryByWidgetIdQuery } from "../../../../../../services/cloudchipr-authless.api";
import { WidgetHeader } from "../../WidgetHeader";
import { WidgetLoadingState } from "../../../../../pages/dashboard/components/widgets/common/WidgetLoadingState";
import { getTrendTooltipTitle } from "../../../../../pages/dashboard/utils/helpers/trend-tooltip-title/getTrendTooltipTitle";
import { WidgetNumeralViewItem } from "../../../../../pages/dashboard/components/adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralViewItem";
import { CostAndUsageSumLiveFilteredTitle } from "../../../../../pages/dashboard/components/adding-widget/widget-create/widget-setups/cost-and-usage/content/CostAndUsageSumLiveFilteredTitle";
import { WidgetNumeralView } from "../../../../../pages/dashboard/components/adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralView";
import { generateCostAndUsageSumDataGridData } from "../../../../../../store/dashboards/selectors/widgets/cost-and-usage/utils/helpers/generateCostAndUsageSumDataGridData";
import { covertDatesToQueryParam } from "../../../../../common/date-range-picker-v2/utils/helpers/covertDatesToQueryParam";

interface AuthLessDashboardCostAndUsageSumWidgetProps
  extends DashboardWidgetCostAndUsageSummary {
  overrideQueryDates: Dates;
}

export const AuthLessDashboardCostAndUsageSumWidget: FC<
  AuthLessDashboardCostAndUsageSumWidgetProps
> = ({ id, name, date_label, grouping, dates, overrideQueryDates }) => {
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("category_id") ?? undefined;
  const datesFormQuery = covertDatesToQueryParam(overrideQueryDates);

  const { data, isFetching } = useGetWidgetsCostAndUsageSummaryByWidgetIdQuery(
    { widgetId: id, categoryId, ...datesFormQuery },
    { refetchOnMountOrArgChange: true },
  );

  const gridData = data ? generateCostAndUsageSumDataGridData(data) : null;

  return (
    <Stack flexGrow={1} overflow="auto">
      <WidgetHeader name={name} grouping={grouping} dates={dates} />

      {isFetching ? (
        <WidgetLoadingState />
      ) : (
        <WidgetNumeralView loading={isFetching} skeletonCount={6}>
          {gridData?.map((item) => {
            const tooltipTitle = getTrendTooltipTitle(
              item.trendDetails?.currentDates?.from,
              item.trendDetails?.currentDates?.to,
              item.trendDetails?.previousDates?.from,
              item.trendDetails?.previousDates?.to,
              item?.costAndUsageSumWidgetFilterType,
              date_label,
            );

            return (
              <WidgetNumeralViewItem
                key={item.costAndUsageSumWidgetFilterType}
                label={item.label}
                CustomLabel={
                  item.costAndUsageSumWidgetFilterType ===
                  "live_usage_total_cost"
                    ? CostAndUsageSumLiveFilteredTitle
                    : undefined
                }
                cost={item.cost}
                postfix={
                  item.costAndUsageSumWidgetFilterType ===
                  "live_usage_total_cost"
                    ? "/mo"
                    : undefined
                }
                trend={
                  item.trendDetails?.trend ? item.trendDetails.trend : null
                }
                itemsCount={gridData?.length}
                tooltipTitle={tooltipTitle}
                approximate={
                  item.costAndUsageSumWidgetFilterType ===
                    "forecasted_month_end_cost" ||
                  item.costAndUsageSumWidgetFilterType ===
                    "forecasted_quarter_end_cost"
                }
              />
            );
          })}
        </WidgetNumeralView>
      )}
    </Stack>
  );
};
