import { FC, useCallback } from "react";
import { Drawer, Stack } from "@mui/material";
import { CostByCategoryWidgetSetup } from "./widget-setups/cost-by-category/CostByCategoryWidgetSetup";
import { CostAndUsageSumWidgetSetup } from "./widget-setups/cost-and-usage/CostAndUsageSumWidgetSetup";
import { LargestCostChangesWidgetSetup } from "./widget-setups/larges-cost-changes/LargestCostChangesWidgetSetup";
import { CostBreakdownV2WidgetSetup } from "./widget-setups/cost-breakdown-v2/CostBreakdownV2WidgetSetup";
import { CommitmentUtilizationWidgetSetup } from "./widget-setups/commitment-utilization /CommitmentUtilizationWidgetSetup";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import {
  setWidgetSelectorState,
  setWidgetSetup,
} from "../../../../../../store/dashboards/dashboardsSlice";
import { widgetSetupWidgetTypeSelector } from "../../../../../../store/dashboards/selectors/setups/common/widgetSetupWidgetTypeSelector";

export const WidgetCreateDrawer: FC = () => {
  const widgetType = useAppSelector(widgetSetupWidgetTypeSelector);

  const dispatch = useAppDispatch();

  const closeHandler = useCallback(() => {
    dispatch(setWidgetSetup(null));
  }, [dispatch]);

  const widgetCreateHandler = useCallback(() => {
    dispatch(setWidgetSelectorState(false));
  }, [dispatch]);

  return (
    <Drawer anchor="right" open={!!widgetType} onClose={closeHandler}>
      <Stack width="95dvw" flexGrow={1} overflow="hidden">
        {widgetType === "costs_by_category_v2" && (
          <CostByCategoryWidgetSetup
            onClose={closeHandler}
            onSubmit={widgetCreateHandler}
          />
        )}

        {widgetType === "cost_breakdown" && (
          <CostBreakdownV2WidgetSetup
            onClose={closeHandler}
            onSubmit={widgetCreateHandler}
          />
        )}

        {widgetType === "cost_and_usage_summary" && (
          <CostAndUsageSumWidgetSetup
            onClose={closeHandler}
            onSubmit={widgetCreateHandler}
          />
        )}

        {widgetType === "largest_cost_changes" && (
          <LargestCostChangesWidgetSetup
            onClose={closeHandler}
            onSubmit={widgetCreateHandler}
          />
        )}

        {widgetType === "commitments_utilization" && (
          <CommitmentUtilizationWidgetSetup
            onClose={closeHandler}
            onSubmit={widgetCreateHandler}
          />
        )}
      </Stack>
    </Drawer>
  );
};
