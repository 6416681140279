import { FC, useCallback, useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  YAxis,
} from "recharts";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { ResourceMetricsChartTooltipContent } from "./components/ResourceMetricsChartTooltipContent";
import { MetricType } from "./utils/types";
import { getMetricFrequencyKey } from "./utils/helpers/helpers";
import { ResourceMetricsAggregation } from "./components/ResourceMetricsAggregation";
import { metricCellFrequencyValues } from "./utils/constants";
import { getMetricsByType } from "./utils/helpers/getMetricsByType";
import { pointGetterByType } from "./utils/helpers/pointsByType";
import { colorsByMetricType } from "./utils/colorsByMetricType";
import { useDataGridContext } from "../../../../../../../../../../../storybook/data-grid/DataGridProvider";
import { useHover } from "../../../../../../../../../../../utils/hooks/useHover.hook";
import {
  ComputeInlineMetrics,
  DatabaseInlineMetrics,
  DataStorageInlineMetrics,
} from "../../../../../../../../../../../services/cloudchipr.api";

interface ResourceMetricsCellProps {
  metricsData:
    | DatabaseInlineMetrics
    | DataStorageInlineMetrics
    | ComputeInlineMetrics;
  type: MetricType;
  columnId?: string;
}

export const ResourceMetricsCell: FC<ResourceMetricsCellProps> = ({
  metricsData,
  type,
  columnId,
}) => {
  const theme = useTheme();
  const { ref, hovered } = useHover();
  const { cellSpecificMetadata } = useDataGridContext();
  const [focusBar, setFocusBar] = useState(null);

  const metricCellFrequencyKey = getMetricFrequencyKey(columnId);
  const value = cellSpecificMetadata?.data?.[metricCellFrequencyKey];

  const chartData = useMemo(() => {
    const frequency = metricCellFrequencyValues[value];

    if (!metricsData) {
      return;
    }

    const metrics = getMetricsByType(metricsData, type);

    if (!metrics) {
      return;
    }

    const startDate =
      frequency === "daily"
        ? metricsData?.daily_start_point
        : metricsData?.hourly_start_point;

    const splitData = metrics[frequency]?.split(",");

    let chartData = splitData?.map((point, i) => {
      const value = isNaN(+point) || point === "" ? null : +point;
      const incrementUnit = frequency === "daily" ? "day" : "hour";

      return {
        value: value,
        date: startDate
          ? moment(startDate).add(i, incrementUnit).format()
          : null,
      };
    });

    if (value === "7d") {
      chartData = chartData?.slice(chartData?.length - 7, chartData?.length);
    }

    return chartData;
  }, [metricsData, value, type]);

  const aggregation = useMemo(() => {
    const pointGetter = pointGetterByType[type];

    return pointGetter(chartData);
  }, [chartData, type]);

  const chartHorizontalValues: number[] | undefined = useMemo(() => {
    if (!chartData) {
      return;
    }

    return Array(chartData.length).fill(aggregation ?? 0);
  }, [chartData, aggregation]);

  const onMouseMove = useCallback((state: any) => {
    if (state?.isTooltipActive) {
      setFocusBar(state.activeTooltipIndex);
    } else {
      setFocusBar(null);
    }
  }, []);

  if (!chartData) {
    return (
      <Typography variant="body2" color="text.primary">
        N/A
      </Typography>
    );
  }

  return (
    <Stack direction="row" ref={ref}>
      <ResourceMetricsAggregation value={aggregation} type={type} />

      <Box pt={0.5} width="100%">
        <ResponsiveContainer width="100%" height={25}>
          <BarChart data={chartData} onMouseMove={onMouseMove}>
            <Bar dataKey="value" isAnimationActive={false} minPointSize={1}>
              {chartData.map((entry, index) => (
                <Cell
                  key={entry.date}
                  fill={
                    focusBar === index && hovered
                      ? colorsByMetricType.get(type)?.["700"]
                      : colorsByMetricType.get(type)?.["100"]
                  }
                />
              ))}
            </Bar>

            {aggregation && (
              <YAxis
                strokeOpacity={0}
                strokeDasharray="3 3"
                tickCount={0}
                width={0}
              />
            )}

            <CartesianGrid
              vertical={false}
              strokeOpacity={0.5}
              horizontalValues={chartHorizontalValues}
              strokeDasharray="2 2"
            />

            {hovered && (
              <Tooltip
                filterNull={false}
                wrapperStyle={{ zIndex: theme.zIndex.tooltip }}
                cursor={{ fill: "transparent" }}
                position={{ y: -55 }}
                content={<ResourceMetricsChartTooltipContent type={type} />}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Stack>
  );
};
