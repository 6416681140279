import { FC, Fragment } from "react";
import { Tooltip } from "@mui/material";
import { LiveUsageMgmtToolbarActionsMenu } from "./LiveUsageMgmtToolbarActionsMenu";
import { LiveUsageMgmtToolbarActionsButton } from "./LiveUsageMgmtToolbarActionsButton";
import { useMenuHook } from "../../../../../../../../../../../utils/hooks/useMenu.hook";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { cleanActionDisabledReasonText } from "../../utils/constants/constants";
import { liveUsageMgmtResourceCleanActionsButtonDisabledReasonSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceCleanActionsButtonDisabledReasonSelector";
import { liveUsageMgmtSelectedRowsWithHierarchyByResourceTypeSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedRowsWithHierarchyByResourceTypeSelector";

interface LiveUsageMgmtToolbarActionsProps {
  resourceType: ResourceType;
}

export const LiveUsageMgmtToolbarActions: FC<
  LiveUsageMgmtToolbarActionsProps
> = ({ resourceType }) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const resourceCleanActionsButtonDisabledReason = useAppSelector((state) =>
    liveUsageMgmtResourceCleanActionsButtonDisabledReasonSelector(
      state,
      resourceType,
    ),
  );
  const selectedResourcesWithHierarchy = useAppSelector((state) =>
    liveUsageMgmtSelectedRowsWithHierarchyByResourceTypeSelector(
      state,
      resourceType,
    ),
  );
  const selectedResourceType = Object.keys(
    selectedResourcesWithHierarchy ?? {},
  )?.at(0) as ResourceType | undefined;

  const title = resourceCleanActionsButtonDisabledReason
    ? cleanActionDisabledReasonText.get(
        resourceCleanActionsButtonDisabledReason,
      )
    : "";

  return (
    <Fragment>
      <Tooltip arrow placement="top" title={title ?? ""}>
        <span>
          <LiveUsageMgmtToolbarActionsButton
            onClick={openMenu}
            disabled={!!title}
          />
        </span>
      </Tooltip>
      <LiveUsageMgmtToolbarActionsMenu
        open={open}
        anchor={anchor}
        onClose={closeMenu}
        resourceType={selectedResourceType ?? resourceType}
      />
    </Fragment>
  );
};
