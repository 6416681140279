import { costBreakdownV2WidgetTotalDataWithIdsSelector } from "./costBreakdownV2WidgetTotalDataWithIdsSelector";
import { RootState } from "../../../../../store";
import { ResourceExplorerGridDataWithId } from "../../../../../resource-explorer/utils/types/resourceExplorerDataGrid";
import { combineCostBreakdownWidgetGridDataWithOthers } from "../../../../utils/helpers/costBreakdown/combineCostBreakdownWidgetGridDataWithOthers";

export const costBreakdownV2WidgetTableViewDataSelector = (
  state: RootState,
  widgetId: string,
): ResourceExplorerGridDataWithId[] => {
  const data = costBreakdownV2WidgetTotalDataWithIdsSelector(state, widgetId);

  if (!data) {
    return [];
  }

  return combineCostBreakdownWidgetGridDataWithOthers(data);
};
