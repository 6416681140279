import { FC, useCallback } from "react";
import { WidgetSetupDateRangeSelectV2 } from "../../../../common/toolbar/WidgetSetupDateRangeSelectV2";
import { useAppDispatch } from "../../../../../../../../../../../store/hooks";
import { Dates } from "../../../../../../../../../../../services/cloudchipr.api";
import { commitmentUtilizationWidgetSetupDataChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/commitment-utilization/setup-change/commitmentUtilizationWidgetSetupDataChangeThunk";

export const CommitmentUtilizationWidgetSetupDateRangeSelect: FC = () => {
  const dispatch = useAppDispatch();

  const dateRangeChangeHandler = useCallback(
    (dates: Dates) => {
      dispatch(commitmentUtilizationWidgetSetupDataChangeThunk({ dates }));
    },
    [dispatch],
  );

  return <WidgetSetupDateRangeSelectV2 onDateChange={dateRangeChangeHandler} />;
};
