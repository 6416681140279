import { FC, useCallback } from "react";
import { SwitchWithTypography } from "../../../../../../../../../../SwitchWithTypography";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { costBreakdownV2SetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupVisualizationPropertyByKeySelector";
import { costBreakdownV2ViewVisualizationChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2ViewVisualizationChangeThunk";

export const CostBreakdownV2WidgetVisualizationAccountColumn: FC = () => {
  const dispatch = useAppDispatch();
  const accountColumnChecked = useAppSelector(
    costBreakdownV2SetupVisualizationPropertyByKeySelector("account_column"),
  );

  const handleAccountColumnCheckedChange = useCallback(
    (checked: boolean) => {
      dispatch(
        costBreakdownV2ViewVisualizationChangeThunk({
          account_column: checked,
        }),
      );
    },
    [dispatch],
  );

  return (
    <SwitchWithTypography
      checked={accountColumnChecked}
      onChange={handleAccountColumnCheckedChange}
      title="Account"
    />
  );
};
