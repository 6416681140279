import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { commitmentUtilizationSetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupPropertyByKeySelector";
import { commitmentUtilizationWidgetSetupDataSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/setup/commitmentUtilizationWidgetSetupDataSelector";
import { CommitmentUtilizationWidgetContentCostsCard } from "../../../../../../../widgets/commitment-utlization/components/CommitmentUtilizationWidgetContentCostsCard";
import { WidgetCommitmentsUtilizationAggregation } from "../../../../../../../../../../../services/cloudchipr.api";
import { calculateFromToByDatesData } from "../../../../../../../../../../common/date-range-picker-v2/utils/helpers/calculateFromToByDatesData";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { widgetSetupWidgetDatesSelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/common/widgetSetupWidgetDatesSelector";
import { getTrendTooltipTitle } from "../../../../../../../../utils/helpers/trend-tooltip-title/getTrendTooltipTitle";

export const CommitmentUtilizationWidgetSetupContentCosts: FC = () => {
  const aggregations: Required<WidgetCommitmentsUtilizationAggregation> =
    useAppSelector(
      commitmentUtilizationSetupPropertyByKeySelector("aggregation"),
    );
  const data = useAppSelector(commitmentUtilizationWidgetSetupDataSelector);
  const dates = useAppSelector(widgetSetupWidgetDatesSelector);
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );
  const { toDate, fromDate } = useMemo(
    () => calculateFromToByDatesData(dates ?? {}, quarterStartMonth),
    [dates, quarterStartMonth],
  );

  const trendTooltipTitle = useMemo(
    () =>
      getTrendTooltipTitle(
        data?.current_period_date_details?.date_from,
        data?.current_period_date_details?.date_to,
        data?.previous_period_date_details?.date_from,
        data?.previous_period_date_details?.date_to,
        undefined,
        dates?.label,
      ),
    [
      data?.current_period_date_details,
      data?.previous_period_date_details,
      dates?.label,
    ],
  );

  return (
    <Stack direction="row" padding={2} spacing={2}>
      {aggregations.utilization && (
        <CommitmentUtilizationWidgetContentCostsCard
          title="Utilization"
          tooltipTitle={`Commitments Utilization from ${fromDate} to ${toDate}`}
          cost={data?.utilization?.amount}
          percent={data?.utilization?.percentage}
          trend={Number(data?.utilization_trend)}
          trendTooltipTitle={trendTooltipTitle}
        />
      )}

      {aggregations.commitments && (
        <CommitmentUtilizationWidgetContentCostsCard
          title="Commitments"
          tooltipTitle={`Commitments from ${fromDate} to ${toDate}`}
          cost={data?.commitments}
          trend={Number(data?.commitments_trend)}
          trendTooltipTitle={trendTooltipTitle}
        />
      )}

      {aggregations.net_savings && (
        <CommitmentUtilizationWidgetContentCostsCard
          title="Net Savings"
          tooltipTitle={netSavingsTooltipTitle}
          cost={data?.net_savings}
          trend={Number(data?.net_savings_trend)}
          trendTooltipTitle={trendTooltipTitle}
        />
      )}
    </Stack>
  );
};

const netSavingsTooltipTitle =
  "Net Savings from commitments for the selected date range. Calculated by comparing the usage cost covered by Commitments to the public On-Demand cost.";
