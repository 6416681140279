import { ResourceExplorerFolderViewNameCell } from "./cells/ResourceExplorerFolderViewNameCell";
import { UserActivityCell } from "../../../../../common/data-grid-cells/UserActivityCell";
import { ColumnSetupType } from "../../../../../../storybook/data-grid/utils/types/types";
import { isHierarchyFolder } from "../../../../../../store/navigation/utils/helpers/isHierarchyFolder";
import { ResourceExplorerV2ViewActionButton } from "../../../../../navigation/components/resource-explorer-v2/components/view-action-menu/ResourceExplorerV2ViewActionButton";
import { ResourceExplorerV2ViewFolderActionButton } from "../../../../../navigation/components/resource-explorer-v2/components/folder-actions-menu/ResourceExplorerV2ViewFolderActionButton";
import { hierarchyAllItemsNameFilter } from "../../../../common/hierarchy-all-items/helpers/filterFunctions/hierarchyAllItemsNameFilter";
import { hierarchyAllItemsVisibilityFilter } from "../../../../common/hierarchy-all-items/helpers/filterFunctions/hierarchyAllItemsVisibilityFilter";
import { ReMixedVisibilitiesItemType } from "../utils/types/types";
import { hierarchyAllItemsModifyByFilter } from "../../../../common/hierarchy-all-items/helpers/filterFunctions/hierarchyAllItemsModfiedByFilter";
import { hierarchyAllItemsCreatedByFilter } from "../../../../common/hierarchy-all-items/helpers/filterFunctions/hierarchyAllItemsCreatedByFilter";
import { hierarchyAllItemsCreatedBySortingFn } from "../../../../common/hierarchy-all-items/helpers/sortingFuntions/hierarchyAllItemsCreatedBySortingFn";
import { HierarchyAllItemsVisibilityCell } from "../../../../common/hierarchy-all-items/components/table-cells/HierarchyAllItemsVisibilityCell";

export const resourceExplorerAllViewsTableColumns: ColumnSetupType<ReMixedVisibilitiesItemType>[] =
  [
    {
      header: "Name",
      accessorKey: "name",
      minSize: 100,
      size: 250,
      maxSize: 450,
      cell: (cell) => {
        const item = cell.row.original;
        const toggleExpandedHandler = cell.row.getToggleExpandedHandler();
        const expanded = cell.row.getIsExpanded();

        return (
          <ResourceExplorerFolderViewNameCell
            id={item.id}
            name={item.name}
            expanded={expanded}
            visibility={item.visibility}
            folder={isHierarchyFolder(item)}
            toggleExpandedHandler={toggleExpandedHandler}
          />
        );
      },
      filterFn: hierarchyAllItemsNameFilter,
      meta: {
        hideFromSettings: true,
      },
    },
    {
      header: "Visibility",
      accessorKey: "visibility",
      minSize: 70,
      size: 150,
      maxSize: 250,
      cell: (cell) => {
        const visibility = cell.row.original.visibility;

        return <HierarchyAllItemsVisibilityCell visibility={visibility} />;
      },
      filterFn: hierarchyAllItemsVisibilityFilter,
    },
    {
      header: "Last Modified By",
      accessorKey: "modified_at",
      minSize: 60,
      size: 150,
      maxSize: 250,
      cell: (cell) => {
        const date = cell.row.original.modified_at;
        const modified_by = cell.row.original.modified_by;

        if (!date) {
          return "-";
        }

        return (
          <UserActivityCell
            date={date}
            name={modified_by?.name ?? modified_by?.email ?? ""}
            deleted={modified_by?.name?.includes("deleted")}
          />
        );
      },
      filterFn: hierarchyAllItemsModifyByFilter,
    },
    {
      header: "Created By",
      accessorKey: "id",
      minSize: 60,
      size: 150,
      maxSize: 250,
      cell: (cell) => {
        const view = cell.row.original;
        const createdBy = view.created_by;

        return (
          <UserActivityCell
            date={view.created_at}
            name={createdBy?.name ?? createdBy?.email ?? ""}
            byCloudchipr={
              (!isHierarchyFolder(view) && view.is_default) ||
              createdBy?.name?.toLowerCase() === "cloudchipr"
            }
            deleted={createdBy?.name?.includes("deleted")}
          />
        );
      },
      filterFn: hierarchyAllItemsCreatedByFilter,
      sortingFn: hierarchyAllItemsCreatedBySortingFn,
    },
    {
      id: "actions",
      accessorKey: "id",
      header: "",
      minSize: 60,
      size: 70,
      maxSize: 80,
      enableResizing: false,
      enableGlobalFilter: false,
      cell: (cell) => {
        const data = cell.row.original;

        if (isHierarchyFolder(data)) {
          return (
            <ResourceExplorerV2ViewFolderActionButton
              size="medium"
              id={data.id}
              name={data.name}
              redirectAfterCreate={false}
              visibility={data.visibility}
            />
          );
        }

        return (
          <ResourceExplorerV2ViewActionButton
            size="medium"
            id={data.id}
            name={data.name}
            visibility={data.visibility}
          />
        );
      },
      meta: {
        hideFromSettings: true,
        cellStyles: {
          textAlign: "right",
        },
      },
    },
  ];
