import { costBreakdownV2WidgetSetupDataSelector } from "./costBreakdownV2WidgetSetupDataSelector";
import { RootState } from "../../../../../store";
import { formatResourceExplorerChartData } from "../../../../../resource-explorer/utils/helpers/formatResourceExplorerChartData";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";
import { convertCostToPercent } from "../../../../../resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/convertCostToPercent";
import {
  FormatedData,
  generateCombinatedChartData,
} from "../../../../../resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/generateCombinatedChartData";

export const costBreakdownV2WidgetSetupCombinedChartDataSelector = (
  state: RootState,
): FormatedData | null => {
  const data = costBreakdownV2WidgetSetupDataSelector(state)?.data;
  const frequency =
    costBreakdownV2SetupPropertyByKeySelector("frequency")(state);

  if (!data) {
    return null;
  }

  const formatedData = formatResourceExplorerChartData(data, frequency);
  const percentData = convertCostToPercent(formatedData);

  return generateCombinatedChartData(formatedData, percentData);
};
