import { appliedFilterTemplateIdSelector } from "./appliedFilterTemplateIdSelector";
import { RootState } from "../../../../store";
import { FilterSet } from "../../../../../services/cloudchipr.api";
import { organisationCurrentFilterSetsSelector } from "../../current-filter-sets/organisationCurrentFilterSetsSelector";

export const appliedFilterTemplateSelector = (
  state: RootState,
): FilterSet | undefined => {
  const filterSetsList = organisationCurrentFilterSetsSelector(state);
  const appliedFilterSetId = appliedFilterTemplateIdSelector(state);

  return filterSetsList?.find(
    (filter_set) => filter_set?.id === appliedFilterSetId,
  );
};
