import { FC } from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import { LocalAtm } from "@mui/icons-material";
import { ViewCostTypeSelect } from "./ViewCostTypeSelect";

export const ViewCostType: FC = () => {
  return (
    <Stack px={2} py={1} spacing={1}>
      <Stack direction="row" spacing={0.5}>
        <LocalAtm fontSize="small" />
        <Typography variant="body2" fontWeight="bold">
          AWS Cost Type
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="caption" lineHeight={1.25}>
            Cost type indicates how costs are calculated and presented for each
            cloud service based on the amount of resources used.
          </Typography>
          <Typography variant="caption" lineHeight={1.25} fontStyle="italic">
            Cost type selection applies only to AWS charges. GCP and Azure
            always display their actual costs.
          </Typography>
        </Stack>
        <ViewCostTypeSelect />
      </Stack>
    </Stack>
  );
};
