import { useCallback, useMemo } from "react";
import { useAppDispatch } from "../../../../../store/hooks";
import { ResourceFiltersWithAction } from "../../../../../services/cloudchipr.api";
import { useAllResourcesVisibility } from "../../../schedule/common/utils/hooks/useAllResourcesVisibility.hook";
import {
  initiateInDrawerWorkflowThunk,
  initiateInDrawerWorkflowThunkArgs,
} from "../../../../../store/automations/thunks/wokrflows/initiate/initiateInDrawerWorkflowThunk";

export function useCreateWorkflowV2(
  workflowInitialData: initiateInDrawerWorkflowThunkArgs | null,
) {
  const dispatch = useAppDispatch();

  const provider = workflowInitialData?.account?.provider;

  const resourcesVisibility = useAllResourcesVisibility({
    accountIds: workflowInitialData?.account?.id ?? "",
    provider,
  });

  const normalizedFilters = useMemo(() => {
    return workflowInitialData?.filters?.map((filter) => {
      const actionAvailable = Object.values(resourcesVisibility).every(
        (visibility) => visibility?.[filter.filter.type]?.actions.delete,
      );

      return {
        action: actionAvailable ? "terminate" : undefined,
        ...filter,
      } as ResourceFiltersWithAction;
    });
  }, [workflowInitialData, resourcesVisibility]);

  return useCallback(() => {
    dispatch(
      initiateInDrawerWorkflowThunk({
        filters: normalizedFilters,
        regions: workflowInitialData?.regions,
        account: workflowInitialData?.account,
        resourceType: workflowInitialData?.resourceType,
      }),
    );
  }, [dispatch, workflowInitialData, normalizedFilters]);
}
