import { FC, Fragment, memo, useMemo } from "react";
import { FormikHandlers } from "formik";
import { Grid, TextField } from "@mui/material";
import * as yup from "yup";
import { FilterItemOperatorType } from "../FilterItemOperatorType";
import { filterUnits } from "../../utils/constants/filter-units";
import {
  FilterItem,
  Operator,
} from "../../../../../../services/cloudchipr.api";
import { unaryOperators } from "../../utils/constants/unary-operators";
import { filterValuePostfixes } from "../../utils/constants/filter-value-postfixes";

export const SimpleValueFilterValueValidation = yup.string().when("operator", {
  is: (operator: string) => !unaryOperators.has(operator),
  then: yup.string().required(),
});

interface SimpleValueFilterProps {
  operators?: Operator[];
  filter: FilterItem;
  error?: any;
  type: "number" | "text";
  onChange: FormikHandlers["handleChange"];
  label?: string;
  minNumberValue?: number;
}

export const SimpleValueFilter: FC<SimpleValueFilterProps> = memo(
  ({ operators, filter, type, onChange, error, label, minNumberValue }) => {
    const labelUnit =
      filterUnits.get(filter.key) ?? filterValuePostfixes.get(filter.key) ?? "";

    const showValueFiled = useMemo(() => {
      if (!operators) {
        return false;
      }

      for (const operator of operators) {
        if (unaryOperators.has(operator.key)) {
          return false;
        }
      }

      return true;
    }, [operators]);

    return (
      <Fragment>
        <FilterItemOperatorType
          value={filter.operator || ""}
          options={operators}
          onChange={onChange}
        />

        {showValueFiled ? (
          <Fragment>
            <Grid item md={2}>
              <TextField
                fullWidth
                name="value"
                size="xsmall"
                label={label || `Value ${labelUnit}`}
                error={!!error}
                disabled={!filter.key || !filter.operator}
                value={filter.value || ""}
                onChange={onChange}
                type={type}
                InputProps={{ inputProps: { min: minNumberValue } }}
              />
            </Grid>

            <Grid item md={5} />
          </Fragment>
        ) : (
          <Grid item md={7} />
        )}
      </Fragment>
    );
  },
);
