import { FC, useCallback } from "react";
import { useAppDispatch } from "../../../../../../../../../../store/hooks";
import { Dates } from "../../../../../../../../../../services/cloudchipr.api";
import { costBreakdownV2DateRangeChangeThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2DateRangeChangeThunk";
import { WidgetSetupDateRangeSelectV2 } from "../../../common/toolbar/WidgetSetupDateRangeSelectV2";

export const CostBreakdownV2WidgetSetupDateRangeSelect: FC = () => {
  const dispatch = useAppDispatch();

  const dateRangeChangeHandler = useCallback(
    (dates: Dates) => {
      dispatch(costBreakdownV2DateRangeChangeThunk(dates));
    },
    [dispatch],
  );

  return <WidgetSetupDateRangeSelectV2 onDateChange={dateRangeChangeHandler} />;
};
