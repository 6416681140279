import { FC, memo, ReactElement, useCallback } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import PieChartOutlineOutlined from "@mui/icons-material/PieChartOutlineOutlined";
import { ChartType } from "../../utils/types/types";
import { useMultiTypeChartContext } from "../../MultiTypeChartProvider";
import AreaChartSvgIcon from "../../../../../assets/images/icons/AreaChartSvgIcon";

const icons: Record<ChartType, ReactElement> = {
  line: <StackedLineChartIcon color="action" fontSize="medium" />,
  bar: <BarChartIcon color="action" fontSize="medium" />,
  multiStack: <StackedBarChartIcon color="action" fontSize="medium" />,
  stack: <StackedBarChartIcon color="action" fontSize="medium" />,
  pie: <PieChartOutlineOutlined color="action" fontSize="medium" />,
  area: <AreaChartSvgIcon color="action" fontSize="medium" />,
  horizontal_stack_bar: (
    <StackedBarChartIcon
      fontSize="small"
      color="action"
      sx={{ transform: "rotate(90deg)" }}
    />
  ),
};

export interface ChartTypeSelectProps {
  selectionType?: "button" | "select";
  availableTypes?: ChartType[];
  size?: "xsmall" | "small";
  onChartTypeChange?(type: ChartType): void;
}

export const ChartTypeSelect: FC<ChartTypeSelectProps> = memo(
  ({
    onChartTypeChange,
    availableTypes,
    selectionType = "button",
    size = "small",
  }) => {
    const {
      hover,
      chartType: { type, onChange },
    } = useMultiTypeChartContext();

    const changeHandler = useCallback(
      (chartType: ChartType) => {
        if (!chartType) {
          return;
        }

        onChange(chartType);
        onChartTypeChange?.(chartType);
        hover.setKey("");
      },
      [hover, onChange, onChartTypeChange],
    );

    const chartTypeChangeHandler = useCallback(
      (_: unknown, chartType: ChartType) => changeHandler(chartType),
      [changeHandler],
    );

    const chartTypeSelectHandler = useCallback(
      (event: any) => changeHandler(event.target.value),
      [changeHandler],
    );

    if (!availableTypes) {
      return null;
    }

    if (selectionType === "select") {
      return (
        <FormControl size={size}>
          <Select
            value={type}
            size="small"
            sx={selectStyles}
            MenuProps={selectMenuProps}
            onChange={chartTypeSelectHandler}
            renderValue={(type) => (
              <Stack alignItems="center" height="inherit">
                {icons[type]}
              </Stack>
            )}
          >
            {availableTypes.map((type) => (
              <MenuItem value={type} key={type}>
                {icons[type]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    return (
      <ToggleButtonGroup
        exclusive
        size="small"
        value={type}
        onChange={chartTypeChangeHandler}
      >
        {availableTypes.map((type) => (
          <ToggleButton value={type} key={type}>
            {icons[type]}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  },
);

const selectMenuProps: SelectProps["MenuProps"] = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: 5,
    horizontal: "left",
  },
};

const selectStyles = {
  bgcolor: "white",
  "& .MuiSelect-select": { px: 0.5 },
};
