import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCostByCategoryWidgetDatByWidgetIdThunk } from "../data-fetch/fetchCostByCategoryWidgetDatByWidgetIdThunk";
import { DashboardWidgetCostsByCategoryV2Request } from "../../../../../../services/cloudchipr.api";
import { currentDashboardIdSelector } from "../../../../selectors/dashboard/currentDashboardIdSelector";
import { RootState } from "../../../../../store";
import { buildWidgetThunk } from "../../common/buildWidgetThunk";
import { costByCategorySetupSelector } from "../../../../selectors/setups/cost-by-category/costByCategorySetupSelector";

export const buildCostByCategoryWidgetThunk = createAsyncThunk(
  "dashboards/buildCostByCategoryWidget",
  async (action: "create" | "update", { dispatch, getState }) => {
    const state = getState() as RootState;

    const dashboardId = currentDashboardIdSelector(state);
    const setup = costByCategorySetupSelector(state);

    if (!dashboardId || !setup) {
      return;
    }

    const widget: DashboardWidgetCostsByCategoryV2Request = {
      name: setup.name,
      date_to: setup.date.to,
      date_from: setup.date.from,
      date_label: setup.date.label,
      forecast_option: setup.forecastOption,
      type: "costs_by_category_v2",
      category_ids: setup.categoryIds,
      date_granularity: setup.frequency,
      category_group_key: setup.categoryGroup,
      chart_type: setup.chartType,
      display_filtered_resources: setup.displayFilteredResources,
      layout: null,
    };

    const response = await dispatch(
      buildWidgetThunk({
        widgetId: setup?.id,
        widget,
      }),
    ).unwrap();

    if (response && setup?.id) {
      await dispatch(fetchCostByCategoryWidgetDatByWidgetIdThunk(response.id));
    }

    return response?.id;
  },
);
