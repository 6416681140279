import { FC } from "react";
import { WidgetNumeralView } from "../../../adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralView";
import { WidgetNumeralViewItem } from "../../../adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralViewItem";
import { money } from "../../../../../../../utils/numeral/money";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentUtilizationWidgetByWidgetIdDataSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetByWidgetIdDataSelector";

interface CommitmentUtilizationWidgetNumeralViewProps {
  widgetId: string;
}

export const CommitmentUtilizationWidgetNumeralView: FC<
  CommitmentUtilizationWidgetNumeralViewProps
> = ({ widgetId }) => {
  const data = useAppSelector((state) =>
    commitmentUtilizationWidgetByWidgetIdDataSelector(state, widgetId),
  );
  const { groups } = data ?? {};

  return (
    <WidgetNumeralView
      loading={false}
      skeletonCount={groups?.length ?? 6}
      width={itemWidth}
    >
      {groups?.map((item) => {
        return (
          <WidgetNumeralViewItem
            key={item.name}
            label={item.name ?? ""}
            costTooltipTitle="Utilization"
            cost={item?.utilization?.amount}
            trend={null}
            percent={item?.utilization?.percentage}
            itemsCount={groups.length}
            secondLabelTooltipTitle="Net Savings"
            secondaryLabel={money(item?.net_savings)}
            width={itemWidth}
          />
        );
      })}
    </WidgetNumeralView>
  );
};

const itemWidth = "25%";
