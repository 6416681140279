import { commitmentsDetailsDrawerDatesSelector } from "./commitmentsDetailsDrawerDatesSelector";
import { commitmentsDetailsDrawerDateGranularitySelector } from "./commitmentsDetailsDrawerDateGranularitySelector";
import { commitmentsDetailsDrawerCoverageGroupingSelector } from "./commitmentsDetailsDrawerCoverageGroupingSelector";
import { RootState } from "../../../../store";
import { GetUsersMeOrganisationsCurrentReservationsDataApiArg } from "../../../../../services/cloudchipr.api";
import { commitmentsCurrentOrgIdSelector } from "../commitmentsCurrentOrgIdSelector";
import { covertDatesToQueryParam } from "../../../../../components/common/date-range-picker-v2/utils/helpers/covertDatesToQueryParam";

type PayloadType = Pick<
  GetUsersMeOrganisationsCurrentReservationsDataApiArg,
  | "providerOrganisationId"
  | "dateFrom"
  | "dateTo"
  | "dateLabel"
  | "dateValue"
  | "dateType"
  | "dateUnit"
  | "coverageGrouping"
  | "dataGranularity"
>;

export const commitmentsDetailsDrawerPayloadDataSelector = (
  state: RootState,
): PayloadType | undefined => {
  const providerOrganisationId = commitmentsCurrentOrgIdSelector(state);
  const dates = commitmentsDetailsDrawerDatesSelector(state);
  const dataGranularity =
    commitmentsDetailsDrawerDateGranularitySelector(state);
  const coverageGrouping =
    commitmentsDetailsDrawerCoverageGroupingSelector(state);

  return {
    dataGranularity,
    coverageGrouping,
    providerOrganisationId,

    ...covertDatesToQueryParam(dates),
  };
};
