import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { commitmentUtilizationWidgetSetupDataLoadingSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/setup/commitmentUtilizationWidgetSetupDataLoadingSelector";
import { commitmentUtilizationWidgetSetupDataSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/setup/commitmentUtilizationWidgetSetupDataSelector";
import { WidgetNumeralView } from "../../../../common/view-type/WidgetNumeralView";
import { WidgetNumeralViewItem } from "../../../../common/view-type/WidgetNumeralViewItem";
import { money } from "../../../../../../../../../../../utils/numeral/money";

export const CommitmentUtilizationWidgetSetupNumeralView: FC = () => {
  const loading = useAppSelector(
    commitmentUtilizationWidgetSetupDataLoadingSelector,
  );
  const data = useAppSelector(commitmentUtilizationWidgetSetupDataSelector);
  const { groups } = data ?? {};

  return (
    <WidgetNumeralView
      loading={loading}
      skeletonCount={groups?.length ?? 6}
      width={itemWidth}
    >
      {groups?.map((item) => {
        return (
          <WidgetNumeralViewItem
            key={item.name}
            label={item.name ?? ""}
            costTooltipTitle="Utilization"
            cost={item?.utilization?.amount}
            trend={null}
            percent={item?.utilization?.percentage}
            itemsCount={groups.length}
            secondLabelTooltipTitle="Net Savings"
            secondaryLabel={money(item?.net_savings)}
            width={itemWidth}
          />
        );
      })}
    </WidgetNumeralView>
  );
};

const itemWidth = "25%";
