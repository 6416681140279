import { RootState } from "../../../../store";
import { costByCategoryWidgetNotFoundSelector } from "../cost-by-category/metadata/costByCategoryWidgetNotFoundSelector";
import { WidgetType } from "../../../../../services/cloudchipr.api";
import { costAndUsageWidgetNotFoundSelector } from "../cost-and-usage/costAndUsageWidgetNotFoundSelector";
import { largestCostChangesWidgetNotFoundSelector } from "../largest-cost-changes/largestCostChangesWidgetNotFoundSelector";
import { costBreakdownV2WidgetNotFoundSelector } from "../cost-breakdown-v2/widget-view/costBreakdownV2WidgetNotFoundSelector";
import { commitmentUtilizationChangesWidgetNotFoundSelector } from "../commitment-utilization/widget-view/commitmentUtilizationChangesWidgetNotFoundSelector";

export const widgetDataNotFoundSelector = (
  state: RootState,
  widgetId: string,
  widgetType: WidgetType,
) => {
  if (widgetType === "costs_by_category_v2") {
    return costByCategoryWidgetNotFoundSelector(state, widgetId);
  }

  if (widgetType === "cost_breakdown") {
    return costBreakdownV2WidgetNotFoundSelector(state, widgetId);
  }

  if (widgetType === "cost_and_usage_summary") {
    return costAndUsageWidgetNotFoundSelector(state, widgetId);
  }

  if (widgetType === "largest_cost_changes") {
    return largestCostChangesWidgetNotFoundSelector(state, widgetId);
  }

  if (widgetType === "commitments_utilization") {
    return commitmentUtilizationChangesWidgetNotFoundSelector(state, widgetId);
  }

  return false;
};
