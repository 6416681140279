import { FC } from "react";
import { Card, Theme } from "@mui/material";
import { dimensionsDataGridColumns } from "./dimensionsDataGridColumns";
import { CategoriesTable } from "../categories/CategoriesTable";
import { AddDimensionButton } from "../../AddDimensionButton";
import { DataGrid } from "../../../../../../storybook/data-grid/DataGrid";
import { useAppSelector } from "../../../../../../store/hooks";
import { dimensionsDataSelector } from "../../../../../../store/dimensions/selectors/dimensions-data/dimensionsDataSelector";
import { DataGridProvider } from "../../../../../../storybook/data-grid/DataGridProvider";

export const DimensionsDataGrid: FC = () => {
  const data = useAppSelector(dimensionsDataSelector);

  if (!data) {
    return null;
  }

  return (
    <Card variant="outlined">
      <DataGridProvider>
        <DataGrid
          data={data}
          styles={styles}
          columnSorting={sortingConfig}
          Footer={Footer}
          columns={dimensionsDataGridColumns}
          rowExpanding={{
            parentRowStyles: { "& > td": { p: 0 } },
            renderExpandedRowSubRow: (row) => (
              <CategoriesTable
                categories={row.original.categories}
                dimensionId={row.original.id}
                dimensionName={row.original.name}
              />
            ),
          }}
        />
      </DataGridProvider>
    </Card>
  );
};

const sortingConfig = { initialSort: [{ id: "total_cost", desc: true }] };

const styles = {
  table: {
    borderCollapse: "separate",
    tableLayout: "fixed",
    "& th": { color: "text.secondary" },
  },
  tableHeaderRow: {
    top: 0,
    position: "sticky",
    zIndex: (theme: Theme) => theme.zIndex.appBar - 1,
  },
};

const Footer = () => (
  <Card sx={{ py: 1, pl: 1.5 }}>
    <AddDimensionButton variant="text" />
  </Card>
);
