import { FC, useCallback, useMemo } from "react";
import moment from "moment";
import {
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { MoreDateRange } from "./MoreDateRange";
import { MoreDateRangesPopover } from "./MoreDateRangesPopover";
import { generateDateRangeString } from "../utils/helpers/generateDateRangeString";
import { DatePeriodInputProps } from "../utils/types";
import { PreDefinedRange } from "../utils/constants/types";
import { formatDate } from "../../../../utils/helpers/date-time/datetime-format";
import { useMenuHook } from "../../../../utils/hooks/useMenu.hook";
import { TypographyWithTooltip } from "../../TypographyWithTooltip";
import { DateLabel } from "../../../../services/cloudchipr.api";
// TODO: import paths should be fixed

interface DateRangePickerWithMoreProps extends DatePeriodInputProps {
  options: (PreDefinedRange | undefined)[];
  small?: boolean;
}

export const DateRangePickerWithMore: FC<DateRangePickerWithMoreProps> = ({
  range,
  onClick,
  minDate,
  options,
  displayThreshold,
  onChange,
  dateLabel,
  disabled,
  small,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const label = useMemo(() => {
    if (!range?.startDate || !range?.endDate || dateLabel) {
      return "";
    }

    const from = formatDate(moment(range.startDate), { type: "date" });
    const to = formatDate(moment(range.endDate), { type: "date" });

    return `${from} - ${to}`;
  }, [range, dateLabel]);

  const structuredOptions = useMemo(() => {
    if (displayThreshold) {
      return {
        start: options.slice(0, displayThreshold),
        tail: options.slice(displayThreshold),
      };
    }
    return {
      start: options,
    };
  }, [options, displayThreshold]);

  const dateRangeChangeHandler = useCallback(
    (_: unknown, label: DateLabel | "custom" | "more") => {
      if (!label || label === "custom" || label === "more") {
        return;
      }

      if (onChange) {
        onChange({
          to: null,
          from: null,
          label: label,
        });
      }
    },
    [onChange],
  );

  const selectedDateLabel = structuredOptions?.tail?.find(
    (item) => item?.dateLabel === dateLabel,
  )?.shortLabel;

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      color="primary"
      onChange={dateRangeChangeHandler}
      value={dateLabel || "custom"}
      sx={{ ...toggleButtonGroupSx, height: small ? 32 : 40 }}
    >
      <ToggleButton
        value="custom"
        sx={customDateToggleButtonSx}
        onClick={onClick}
        disabled={disabled}
      >
        <CalendarTodayIcon fontSize="small" sx={{ mr: 1 }} />

        <TypographyWithTooltip
          title={label || "Custom"}
          variant="body2"
          placement="top"
        />
      </ToggleButton>

      {structuredOptions.start?.map((range) => {
        if (!range) {
          return null;
        }

        const localDisabled =
          disabled || !!(minDate && moment(range.startDate).isBefore(minDate));

        return (
          <ToggleButton
            key={range.label}
            value={range.dateLabel}
            sx={{ px: 1.5, color: "text.secondary" }}
            disabled={localDisabled}
          >
            <Tooltip
              arrow
              placement="top"
              title={generateDateRangeString(range)}
            >
              <Typography
                textTransform="capitalize"
                variant="body2"
                whiteSpace="nowrap"
              >
                {range.shortLabel}
              </Typography>
            </Tooltip>
          </ToggleButton>
        );
      })}

      {structuredOptions?.tail?.length && (
        <ToggleButton
          value="more"
          sx={{
            color: "text.secondary",
            px: 1.5,
            pr: 0.5,
          }}
          onClick={openMenu}
          disabled={disabled}
          selected={!!selectedDateLabel}
        >
          <MoreDateRange
            options={structuredOptions?.tail}
            dateLabel={dateLabel}
            open={open}
          />
        </ToggleButton>
      )}
      <MoreDateRangesPopover
        options={structuredOptions?.tail}
        onClick={onChange}
        dateLabel={dateLabel}
        open={open}
        anchor={anchor}
        closeMenu={closeMenu}
        minDate={minDate}
      />
    </ToggleButtonGroup>
  );
};

const toggleButtonGroupSx = {
  "& :not(.Mui-selected) span": {
    fontWeight: "normal",
    color: "text.primary",
  },
};

const customDateToggleButtonSx = {
  textTransform: "none",
  overflow: "hidden",
  width: "100%",
  px: "1.5",
};
