import { largestCostChangesWidgetByIdSelector } from "./largestCostChangesWidgetByIdSelector";
import { largestCostChangesWidgetNotFoundSelector } from "./largestCostChangesWidgetNotFoundSelector";
import { RootState } from "../../../../store";
import { WidgetTitleProps } from "../../../../../components/pages/dashboard/components/widgets/common/widget-header/source-and-name/WidgetSourceInfo";
import { resourceExplorerViewNameByViewIdSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewNameByViewIdSelector";
import { getResourcesExplorerGroupingLabel } from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { objectToUrl } from "../../../../../components/utils/helpers/dataToUrlToDataConvertors";
import { connectedProvidersSelector } from "../../../../common/selectors/connectedProvidersSelector";

export const largestCostChangesWidgetTitlePropsSelector = (
  state: RootState,
  widgetId: string,
): WidgetTitleProps | null => {
  const widget = largestCostChangesWidgetByIdSelector(state, widgetId);

  if (!widget) {
    return null;
  }

  const viewName = resourceExplorerViewNameByViewIdSelector(
    state,
    widget.view_id,
  );

  const viewProviders = connectedProvidersSelector(state);

  const grouping = getResourcesExplorerGroupingLabel(
    widget.grouping,
    true,
    viewProviders,
  );

  const viewNotFound = largestCostChangesWidgetNotFoundSelector(
    state,
    widgetId,
  );

  const path = objectToUrl([
    {
      groupedBy: widget.grouping,
      groupValues: widget.group_values,
      dates: widget.dates,
      filterTree: undefined,
    },
  ]);

  return {
    grouping,
    widgetName: widget.name,
    dates: widget.dates,

    sourceData: viewNotFound
      ? undefined
      : {
          icon: "pie",
          title: viewName ?? "",
          menu: [
            {
              title: "Open in Resource Explorer",
              url: `/resource-explorer/${widget.view_id}?path=${path}`,
            },
          ],
        },
  };
};
