import { createAsyncThunk } from "@reduxjs/toolkit";
import { setResourceExplorerInitialDataGridViewTypeFromStorageThunk } from "./setResourceExplorerInitialDataGridViewTypeFromStorageThunk";
import { getResourceExplorerViewByIdAndSetDataThunk } from "./getResourceExplorerViewByIdAndSetDataThunk";
import { setResourceExplorerPathThunk } from "../widgets/path/setResourceExplorerPathThunk";
import { setResourceExplorerDimensionFilterFromPathThunk } from "../filters/setResourceExplorerDimensionFilterFromPathThunk";
import { resetResourceExplorer } from "../../resourceExplorerSlice";
import { getResourceExplorerDataThunk } from "../widgets/getResourceExplorerDataThunk";
import { RootState } from "../../../store";
import { resourceExplorerPathLastItemFilterTreeSelector } from "../../selectors/current-resource-explorer/path/resourceExplorerPathLastItemFilterTreeSelector";

interface InitiateResourceExplorerThunkArgs {
  path: string | null;
  viewId: string;
  dimensionId?: string;
  categoryId?: string;
}

export const initiateResourceExplorerThunk = createAsyncThunk(
  "resourceExplorer/initiateResourceExplorer",
  async (
    {
      path,
      viewId,
      dimensionId,
      categoryId,
    }: InitiateResourceExplorerThunkArgs,
    { dispatch, getState },
  ) => {
    dispatch(resetResourceExplorer());

    await dispatch(getResourceExplorerViewByIdAndSetDataThunk(viewId));

    dispatch(setResourceExplorerPathThunk(!!path));

    dispatch(
      setResourceExplorerInitialDataGridViewTypeFromStorageThunk(viewId),
    );
    const state = getState() as RootState;

    const dimensionsFilters = await dispatch(
      setResourceExplorerDimensionFilterFromPathThunk({
        dimensionId,
        categoryId,
      }),
    ).unwrap();

    const filterTreeFromPath =
      dimensionsFilters ??
      resourceExplorerPathLastItemFilterTreeSelector(state);

    dispatch(getResourceExplorerDataThunk(filterTreeFromPath));
  },
);
