import { FC, useCallback } from "react";
import { GroupingSelector } from "../../../../../../common/resource-explorer-grouping-selector/GroupingSelector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { resourceExplorerGroupingSelector } from "../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import { resourceExplorerGroupValuesSelector } from "../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupValuesSelector";
import { resourceExplorerGroupByChangeThunk } from "../../../../../../../../store/resource-explorer/thunks/resource-explorer/resourceExplorerGroupByChangeThunk";
import { ResourceExplorerGrouping } from "../../../../../../../../services/cloudchipr.api";

export const ResourceExplorerGroupBySelector: FC = () => {
  const dispatch = useAppDispatch();

  const groupBy = useAppSelector(resourceExplorerGroupingSelector);
  const groupValues = useAppSelector(resourceExplorerGroupValuesSelector);
  const groupingChangeHandler = useCallback(
    (grouping: ResourceExplorerGrouping, groupValues?: string[]) => {
      if (groupBy === grouping && !groupValues) {
        return;
      }

      dispatch(resourceExplorerGroupByChangeThunk({ grouping, groupValues }));
    },
    [dispatch, groupBy],
  );

  return (
    <GroupingSelector
      value={groupBy}
      triggerSize="xsmall"
      groupValues={groupValues}
      onChange={groupingChangeHandler}
    />
  );
};
