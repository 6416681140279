import { FC } from "react";
import { Stack } from "@mui/material";
import { CostBreakdownV2WidgetVisualizationChartBase } from "./CostBreakdownV2WidgetVisualizationChartBase";
import { CostBreakdownV2WidgetVisualizationForecastedCost } from "./CostBreakdownV2WidgetVisualizationForecastedCost";
import { CostBreakdownV2WidgetVisualizationAccountColumn } from "./CostBreakdownV2WidgetVisualizationAccountColumn";
import { CostBreakdownV2WidgetVisualizationRegionColumn } from "./CostBreakdownV2WidgetVisualizationRegionColumn";
import { CostBreakdownV2WidgetVisualizationPreviousPeriodColumn } from "./CostBreakdownV2WidgetVisualizationPreviousPeriodColumn";
import { CostBreakdownV2WidgetVisualizationTrend } from "./CostBreakdownV2WidgetVisualizationTrend";
import { CostBreakdownV2WidgetViewTypeSelect } from "../../../components/side-bar/CostBreakdownV2WidgetViewTypeSelect";
import { WidgetConfigSection } from "../../../../common/widget-configurations/WidgetConfigSection";
import VisualizationIcon from "../../../../../../../../../../../assets/images/icons/visualization_icon.svg";
import {
  ChartType,
  WidgetCostBreakdownVisualization,
} from "../../../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { costBreakdownV2SetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupVisualizationPropertyByKeySelector";

export const CostBreakdownV2WidgetVisualizationSection: FC = () => {
  const chartType = useAppSelector(
    costBreakdownV2SetupVisualizationPropertyByKeySelector("chart_type"),
  );

  return (
    <WidgetConfigSection
      initialExpanded
      title="Visualization"
      icon={<img src={VisualizationIcon} alt="Visualization Icon" />}
    >
      <Stack spacing={1.5}>
        <CostBreakdownV2WidgetViewTypeSelect />

        {costBreakdownVisualizationDetailsByChartType
          .get(chartType)
          ?.map((type) => {
            const Component =
              costBreakdownVisualizationDetailsComponentByType.get(type);

            if (!Component) {
              return;
            }

            return <Component key={type} />;
          })}
      </Stack>
    </WidgetConfigSection>
  );
};

const costBreakdownVisualizationDetailsByChartType = new Map<
  ChartType,
  (keyof WidgetCostBreakdownVisualization)[]
>([
  ["stack", ["chart_base_type"]],
  ["pie", ["trend"]],
  ["numeral", ["trend", "forecasted_cost"]],
  [
    "table",
    //TODO: after adding account and region column only add "account_column", "region_column", to this array
    ["previous_period_cost", "trend", "forecasted_cost"],
  ],
]);

const costBreakdownVisualizationDetailsComponentByType = new Map<
  keyof WidgetCostBreakdownVisualization,
  FC
>([
  ["chart_base_type", CostBreakdownV2WidgetVisualizationChartBase],
  ["trend", CostBreakdownV2WidgetVisualizationTrend],
  ["forecasted_cost", CostBreakdownV2WidgetVisualizationForecastedCost],
  ["account_column", CostBreakdownV2WidgetVisualizationAccountColumn],
  ["region_column", CostBreakdownV2WidgetVisualizationRegionColumn],
  [
    "previous_period_cost",
    CostBreakdownV2WidgetVisualizationPreviousPeriodColumn,
  ],
]);
