import { FC, useMemo } from "react";
import { Stack, Tooltip } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import {
  DashboardWidgetLargestCostChanges,
  Dates,
} from "../../../../../../services/cloudchipr.api";
import { LargestCostChangesWidgetBody } from "../../../../../pages/dashboard/components/adding-widget/widget-create/widget-setups/larges-cost-changes/content/components/LargestCostChangesWidgetBody";
import { useGetWidgetsLargestCostChangesByWidgetIdQuery } from "../../../../../../services/cloudchipr-authless.api";
import { WidgetHeader } from "../../WidgetHeader";
import { LargestCostChangesWidgetFooter } from "../../../../../pages/dashboard/components/widgets/largest-cost-change/components/footer/LargestCostChangesWidgetFooter";
import { LargestCostChangesPrevDateDetails } from "../../../../../pages/dashboard/components/adding-widget/widget-create/widget-setups/larges-cost-changes/content/components/LargestCostChangesPrevDateDetails";
import CompareIcon from "../../../../../../assets/images/icons/compare.svg";
import { generateDatesCompareData } from "../../../../../common/date-range-picker-v2/utils/helpers/date-formatter/generateDatesCompareData";
import { covertDatesToQueryParam } from "../../../../../common/date-range-picker-v2/utils/helpers/covertDatesToQueryParam";

interface AuthLessLargestCostChangesWidgetProps
  extends DashboardWidgetLargestCostChanges {
  overrideQueryDates: Dates;
}

export const AuthLessLargestCostChangesWidget: FC<
  AuthLessLargestCostChangesWidgetProps
> = ({ overrideQueryDates, id, dates, name, grouping }) => {
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("category_id") ?? undefined;
  const datesFormQuery = covertDatesToQueryParam(overrideQueryDates);

  const { data, isFetching } = useGetWidgetsLargestCostChangesByWidgetIdQuery(
    { widgetId: id, categoryId, ...datesFormQuery },
    { refetchOnMountOrArgChange: true },
  );

  const prevDate = data?.previous_period_cost_date_details;

  const dateInfo = useMemo(() => {
    return generateDatesCompareData({
      prevDate: prevDate,
      date: dates,
    });
  }, [prevDate, dates]);

  return (
    <Stack flexGrow={1} overflow="auto">
      <WidgetHeader name={name} grouping={grouping} dates={dates}>
        <Tooltip
          title={dateInfo.title}
          arrow
          placement="top"
          componentsProps={{ tooltip: { sx: { maxWidth: 630 } } }}
        >
          <Stack alignItems="center">
            <img src={CompareIcon} alt="compare" />
          </Stack>
        </Tooltip>
        <LargestCostChangesPrevDateDetails
          prevDates={{
            ...dates,
            from: prevDate?.from_date,
            to: prevDate?.to_date,
          }}
        />
      </WidgetHeader>

      <LargestCostChangesWidgetBody
        date={dates}
        loading={isFetching}
        data={data?.total ?? []}
        previousPeriodDate={prevDate}
      />

      <LargestCostChangesWidgetFooter prevDate={prevDate} date={dates} />
    </Stack>
  );
};
