import { createAsyncThunk } from "@reduxjs/toolkit";
import { WidgetCostBreakdownAggregation } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";
import { costBreakdownV2WidgetSetupSelector } from "../../../../selectors/setups/cost-breakdown-v2/costBreakdownV2WidgetSetupSelector";
import { setWidgetSetup } from "../../../../dashboardsSlice";

export const costBreakdownV2ViewAggregationsChangeThunk = createAsyncThunk(
  "dashboards/costBreakdownV2ViewAggregationsChange",
  async (
    aggregation: WidgetCostBreakdownAggregation,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const existingSetup = costBreakdownV2WidgetSetupSelector(state);

    if (!existingSetup) {
      return;
    }

    dispatch(
      setWidgetSetup({
        ...existingSetup,
        aggregation: {
          ...existingSetup.aggregation,
          ...aggregation,
        },
      }),
    );
  },
);
