import {
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Fragment, useMemo } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { Tune } from "@mui/icons-material";
import { getLiveFilteredResourcesColumns } from "./utils/getLiveFilteredResourcesColumns";
import { ResourceType } from "../../../../../../../../../../../../services/cloudchipr.api";
import { RenderToolbar } from "../../../../../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { ColumnsSwitcher } from "../../../../../../../../../../../../storybook/data-grid/toolbar/ColumnsSwitcher";
import { CsvDataDownloader } from "../../../../../../../../../../account/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/toolbar/CsvDataDownloader";
import { GlobalFilter } from "../../../../../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { money } from "../../../../../../../../../../../../utils/numeral/money";
import { getResourceTypeName } from "../../../../../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { getProviderTypeByResourceType } from "../../../../../../../../../../../../utils/helpers/providers/getProviderTypeByResourceType";
import { ProviderIcon } from "../../../../../../../../../../../common/ProviderIcon";
import { useMenuHook } from "../../../../../../../../../../../../utils/hooks/useMenu.hook";
import { useAppSelector } from "../../../../../../../../../../../../store/hooks";
import { liveFilteredResourcesSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/live-filtered-resources/liveFilteredResourcesSelector";
import { liveFilteredResourcesLoadingSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/live-filtered-resources/liveFilteredResourcesLoadingSelector";
import { liveFilteredResourcesEmsPriceSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/live-filtered-resources/liveFilteredResourcesEmsPriceSelector";
import { generateExportedResourceDataByColumns } from "../../../../../../../../../../account/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/generateExportedResourceDataByColumns";
import { useDataGridContext } from "../../../../../../../../../../../../storybook/data-grid/DataGridProvider";
import { generateMetricsFrequenciesFromContextData } from "../../../../../../../../../../account/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/generateMetricsFrequenciesFromContextData";

interface InternalProps {
  dataCount?: number;
  resourceType: ResourceType;
}

interface LiveFilteredResourceItemToolbarProps
  extends RenderToolbar<InternalProps> {}

export const LiveFilteredResourceItemToolbar: LiveFilteredResourceItemToolbarProps =
  ({ resourceType, dataCount, table, globalFilter, setGlobalFilter }) => {
    const { anchor, openMenu, closeMenu, open } = useMenuHook();
    const enableMetricsCharts = useFlag("EnableResourceSmallCharts");

    const context = useDataGridContext();
    const data = context?.cellSpecificMetadata?.data;
    const metricsFrequencies = generateMetricsFrequenciesFromContextData(data);

    const resources = useAppSelector((state) =>
      liveFilteredResourcesSelector(state, resourceType),
    );
    const loading = useAppSelector((state) =>
      liveFilteredResourcesLoadingSelector(state, resourceType),
    );

    const totalMonthlyPrice = useAppSelector((state) =>
      liveFilteredResourcesEmsPriceSelector(state, resourceType),
    );

    const csvData = useMemo(() => {
      if (!resources?.length) {
        return;
      }
      const columns =
        getLiveFilteredResourcesColumns(resourceType, {
          enableMetricsCharts,
        }) ?? [];

      return generateExportedResourceDataByColumns(resources, columns, [], {
        includeNestedRows: true,
        metricsFrequencies,
      });
    }, [resources, resourceType, enableMetricsCharts, metricsFrequencies]);

    return (
      <Fragment>
        <Stack justifyContent="space-between" direction="row" mb={2}>
          <Stack direction="row" spacing={1}>
            <ProviderIcon
              provider={getProviderTypeByResourceType(resourceType)}
            />

            <Stack spacing={0.25} direction="row" alignItems="center">
              <Typography variant="subtitle1" fontWeight="medium">
                {getResourceTypeName(resourceType, {
                  type: "long",
                })}{" "}
                ({dataCount}) - {money(totalMonthlyPrice)}
              </Typography>
              {totalMonthlyPrice !== null && (
                <Typography variant="caption" color="text.secondary">
                  /mo
                </Typography>
              )}
            </Stack>
          </Stack>

          <Stack direction="row" spacing={1}>
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              size="xsmall"
            />
            <CsvDataDownloader data={csvData} resourceType={resourceType} />
            <Tooltip arrow title="Table Settings" placement="top">
              <IconButton onClick={openMenu} size="small">
                <Tune fontSize="small" />
              </IconButton>
            </Tooltip>
            <ColumnsSwitcher
              open={open}
              onClose={closeMenu}
              anchor={anchor}
              table={table}
            />
          </Stack>
        </Stack>
        {loading && <LinearProgress />}
      </Fragment>
    );
  };
