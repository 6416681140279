import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { GroupingSelector } from "../../../../../../../../common/resource-explorer-grouping-selector/GroupingSelector";
import { costBreakdownV2GroupingChangeThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2GroupingChangeThunk";
import { ResourceExplorerGrouping } from "../../../../../../../../../../services/cloudchipr.api";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";

interface CostBreakdownWidgetSetupGroupingSelectProps {
  disabled?: boolean;
}

export const CostBreakdownV2WidgetSetupGroupingSelect: FC<
  CostBreakdownWidgetSetupGroupingSelectProps
> = ({ disabled }) => {
  const dispatch = useAppDispatch();

  const groupBy = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("grouping"),
  );
  const groupValues = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("group_values"),
  );

  const groupingChangeHandler = useCallback(
    (grouping: ResourceExplorerGrouping, groupValues?: string[]) => {
      dispatch(
        costBreakdownV2GroupingChangeThunk({
          grouping,
          groupValues,
        }),
      );
    },
    [dispatch],
  );

  return (
    <GroupingSelector
      value={groupBy}
      disabled={disabled}
      groupValues={groupValues}
      hiddenGroupings={["resource"]}
      onChange={groupingChangeHandler}
    />
  );
};
