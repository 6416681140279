import { scheduleResourcesDataSelector } from "./scheduleResourcesDataSelector";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { ResourceDataType } from "../../../../account/utils/types/types";

export const scheduleAllResourcesDataSelector = (
  state: RootState,
  accountIds: string[],
  resourceTypes: ResourceType[],
  scheduleId: string,
): ResourceDataType[] => {
  return resourceTypes
    .map((rt) => {
      return scheduleResourcesDataSelector(state, accountIds, rt, scheduleId);
    })
    .filter((resource) => !!resource)
    .flat();
};
