import { FC, useCallback, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { LiveUsageMgmtFilterTemplatesRow } from "./LiveUsageMgmtFilterTemplatesRow";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { useAppDispatch } from "../../../../../../store/hooks";
import {
  FilterSet,
  ProviderType,
} from "../../../../../../services/cloudchipr.api";
import { getOrganisationCurrentFilterTemplatesThunk } from "../../../../../../store/live-usage-mgmt/thunks/getOrganisationCurrentFilterTemplatesThunk";

interface FilterTemplatesDialogProps {
  open: boolean;
  onClose(): void;
  filterTemplates?: FilterSet[];
  provider?: ProviderType;
  onApplyCallback?(): void;
}

export const LiveUsageMgmtFilterTemplatesDialog: FC<
  FilterTemplatesDialogProps
> = ({ onClose, open, onApplyCallback, filterTemplates, provider }) => {
  const dispatch = useAppDispatch();
  const [editingRowId, setEditingRowId] = useState("");
  const [duplicatingRowId, setDuplicatingRowId] = useState("");

  const editOrDuplicateMode = !!(duplicatingRowId || editingRowId);

  const applyHandler = useCallback(() => {
    dispatch(getOrganisationCurrentFilterTemplatesThunk());
    if (onApplyCallback) {
      onApplyCallback();
    }
  }, [onApplyCallback, dispatch]);

  const filterTemplatesRowProps = {
    onApply: applyHandler,
    editOrDuplicateMode: editOrDuplicateMode,
    provider,
    editingRowId,
    setEditingRowId,
    duplicatingRowId,
    setDuplicatingRowId,
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{ sx: { borderRadius: 3 } }}
    >
      <DialogTitleClosable title="Filter Templates" onClose={onClose} />

      <DialogContent dividers>
        <Box borderRadius={2} overflow="hidden">
          <Table size="small">
            <TableHead>
              <TableRow sx={{ bgcolor: "grey.100" }}>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  Filter Template
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  Applied Accounts
                </TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {filterTemplates?.map((filterSet) => (
                <LiveUsageMgmtFilterTemplatesRow
                  key={filterSet.id}
                  filterSet={filterSet}
                  {...filterTemplatesRowProps}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
